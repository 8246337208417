import { LayoutContext } from "components/core/LayoutProvider";
import { handleSetCopyFilters } from "helpers/Functions";
import { useContext } from "react";
import downAngleSVG from "../../../../assets/media/icons/other_icons/down-angle.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { SalesContext } from "../../core/SalesProvider";

const HeaderCell = ({ title, width, arrow, postKey, padding }: any) => {
  const coreLayout = useContext(LayoutContext);
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;

  const layout = useContext(SalesContext);
  let order = layout.salesFilterHandle;

  const handleSort = (sortedKey: any) => {
    layout.setSalesFilterHandle((preValue: any) => {
      handleSetCopyFilters(
        layout.setSalesFilterCopy,
        {
          order_by: sortedKey,
          sort_order:
            preValue &&
            Object.keys(preValue).length > 0 &&
            preValue.order_by !== sortedKey
              ? "desc"
              : preValue.sort_order === "desc"
              ? "asc"
              : preValue.sort_order === "asc"
              ? undefined
              : "desc",
        },
        isGodAdmin
      );
      return {
        ...preValue,
        order_by: sortedKey,
        sort_order:
          preValue &&
          Object.keys(preValue).length > 0 &&
          preValue.order_by !== sortedKey
            ? "desc"
            : preValue.sort_order === "desc"
            ? "asc"
            : preValue.sort_order === "asc"
            ? undefined
            : "desc",
      };
    });
  };
  return (
    <th
      className={`px-1.5 py-3 font-medium whitespace-nowrap ${width} ${padding}`}
    >
      {arrow ? (
        <div className="w-full pr-3 relative">
          {title}
          <button type="button" onClick={() => handleSort(postKey)}>
            <IKTSVG
              path={downAngleSVG}
              className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4 ${
                // @ts-ignore
                order?.order_by === postKey && order?.sort_order === "desc"
                  ? "rotate-180 !fill-violet-500"
                  : order?.order_by === postKey && order?.sort_order === "asc"
                  ? " !fill-violet-500"
                  : "fill-gray-400"
              }
                  `}
            />
          </button>
        </div>
      ) : (
        title
      )}
    </th>
  );
};

export default HeaderCell;
