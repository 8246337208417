import { NO_DEMO_STATUS } from "helpers/const";
import _, { isArray, isObject, isString, size } from "lodash";
import moment from "moment";
import { convertIntoDateFormat } from "pages/inventory/core/_functions";

// Define a common interface for the expected structure
interface DemoItem {
  id: string;
}

// Helper to check for `id` safely
export const hasNoDemoValue = (value: any) => {
  if (isArray(value))
    return value.find((item: any) => item?.id !== NO_DEMO_STATUS);

  if (isObject(value)) return (value as DemoItem)?.id !== NO_DEMO_STATUS;

  if (isString(value)) return value !== NO_DEMO_STATUS;
  return false;
};

export const getDemoStatus = (list: any) => {
  if (!list || !list?.demo_status) return;

  if (!hasNoDemoValue(list?.demo_status)) return list?.demo_status;

  return `${list?.demo_status}`;
};

export const getValidDemoDateTime = (data: any, isFormatted = false) => {
  if (hasNoDemoValue(data?.demo_status)) {
    const demoDateTime = data?.demo_datetime
      ? new Date(data?.demo_datetime)
      : "";

    if (demoDateTime && !isNaN(demoDateTime?.getTime()))
      return isFormatted
        ? moment(demoDateTime).format("DD/MM/YYYY HH:mm")
        : demoDateTime;
  }

  return "";
};

export const makePayloadForReferral = (data: any) => {
  const query = data?.query;
  const page = data?.page;
  const per_page = data?.per_page;
  const order_by = data?.order_by;
  const sort_order = data?.sort_order;
  const team_id = size(data?.teams) > 0 && _.map(data?.teams, "id");
  const referred_id =
    size(data?.referralTeam) > 0 && _.map(data?.referralTeam, "id");
  const referral_status =
    size(data?.referralStatus) > 0 && _.map(data?.referralStatus, "id");
  const referral_start_date = data?.startDate;
  const referral_expiry_date = data?.endDate;
  const export_to_csv = data?.export_to_csv;

  return {
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && order_by && { sort_order }),
    ...(query && { query }),
    ...(team_id && { referrer_id: team_id }),
    ...(referred_id && { referred_id: referred_id }),
    ...(referral_status && { referral_status }),
    ...(export_to_csv && { export_to_csv: 1 }),
    ...(referral_start_date && {
      referral_start_date: convertIntoDateFormat(
        referral_start_date,
        "YYYY-MM-DD"
      ),
    }),
    ...(referral_expiry_date && {
      referral_expiry_date: convertIntoDateFormat(
        referral_expiry_date,
        "YYYY-MM-DD"
      ),
    }),
  };
};

export const createInvoiceNumber = (data: any): string => {
  return `${data?.prefix ?? ""}${data?.invoice_number ?? ""}${data?.invoice_number && data?.suffix ? "_" : ""}${
    data?.suffix ?? ""
  }`;
};
