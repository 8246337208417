import { useContext, useEffect, useMemo, useRef, useState } from "react";
// import Tab from "../tx_trade/component/Tab";
import _, { lowerCase, size } from "lodash";
import { useQuery } from "react-query";
import close from "../../assets/media/icons/other_icons/Clear.svg";

import { getTotalActiveFilters } from "helpers/AssetHelpers";
import { LayoutContext } from "../../components/core/LayoutProvider";
import NoDataComponent from "../../components/tableComponent/NoDataComponent";
import { IKTSVG } from "../../components/ui/IKTSVG";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import {
  PAYOUT_TAB,
  QUERIES,
  TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
  TX_ACCOUNT_INVOICE_INITIAL_FILTER,
  TX_ACCOUNT_ORDER_INITIAL_FILTER,
  TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
  TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
  TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
} from "../../helpers/const";
import { getReportsOptions } from "../Reports/core/requests";
import useDebounce from "../add_inventory/core/useDebounce";
import DataTableBankAccount from "./Table/DataTableBankAccount";
import DataTableInvoices from "./Table/DataTableInvoices";
import DataTableOrders from "./Table/DataTableOrders";
import DataTablePayouts from "./Table/DataTablePayouts";
import DataTableSellerwallet from "./Table/DataTableSellerwallet";
import DataTableWithdrawRequest from "./Table/DataTableWithdrawRequest";
import HeaderFilterContainer from "./Table/component/HeaderFilterContainer";
import Tabs from "./Table/component/Tabs";
import AccountBalanceWithFlag from "./component/AccountBalanceWithFlag";
import BulkExcludeOrdersFilter from "./component/BulkExcludeOrdersFilter";
import CommonFilter from "./component/CommonFilter";
import FiltersChips from "./component/FiltersChips";
import InvoicesFilterCountBoxsWrapper from "./component/InvoicesFilterCountBoxsWrapper";
import OrdersFilterCountBoxsWrapper from "./component/OrdersFilterCountBoxsWrapper";
import PayoutsFilterCountBoxsWrapper from "./component/PayoutsFilterCountBoxsWrapper";
import TableShimmer from "./component/TableShimmer";
import { TxAccountContext } from "./core/TxAccountProvider";
import {
  getTXAccountBankAccountListings,
  getTXAccountInvoiceListings,
  getTXAccountOrdersListings,
  getTXAccountPayoutListings,
  getTXAccountSellerWalletListings,
  getTXAccountWithdrawRequestListings,
  invoiceViewAPI,
  ordersWalletBalance,
} from "./core/_request";
import { tabConditions } from "./core/functions";
import Footer from "./layout/Footer";

const TxAccount = () => {
  const layout = useContext(TxAccountContext);
  const coreLayout = useContext(LayoutContext);
  const tab = layout.isTabActive;
  const orderFilters = layout.orderFilters;
  const invoiceFilters = layout.invoicesFilter;
  const payOutFilters = layout.payOutsFilter;
  const sellerWalletsFilter = layout.sellerWalletsFilter;
  const bankAccountFilter = layout.bankAccountFilter;
  const withdrawRequestFilter = layout.withdrawRequestFilter;
  const orderData = layout.orderList;
  const invoiceData = layout?.invoiceList;
  const payoutData = layout?.payoutList;
  const invoiceOverViewDetails = layout.invoiceOverViewDetails;
  const currencyBalance = layout.currencyBalance;
  const heightRef1: any = useRef();
  const toggleRef: any = useRef();
  const [IsToggle, setIsToggle] = useState<any>(true);
  const [IsIds, setIsIds] = useState<any>(true);
  const [overViewHeight, setOverViewHeight] = useState<any>();
  const element: any = document.querySelector("#inventoryaccordion");
  const stickyElement = document.getElementById("topFilters") as HTMLElement;
  // const [applyFilter, setApplyFilter] = useState(0);
  const [countAPi, setCountApi] = useState(0);
  // const [filterChipsVisible, setFilterChipsVisible] = useState({
  //   count: 0,
  //   show: false,
  //   filterData: {
  //     page: 1,
  //     per_page: 20,
  //   },
  // });

  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;
    if (teamArr === undefined || teamArr?.length === 0) {
      return coreLayout.currencyLabel;
    } else if (teamArr?.length > 1) {
      return coreLayout.currencyLabel;
    } else {
      return teamArr?.[0]?.base_currency;
    }
  }

  const baseCurrency = isGodadminBasedCurrency(layout?.orderFilters?.teams);

  // STATES
  const [inputChange, setInputChange] = useState<any>();

  const [isLoadingOrdersVirtous, setIsLoadingOrderVirtous] =
    useState<any>(false);

  const [isLoadingInvoicesVirtous, setIsLoadingInvoicesVirtous] =
    useState<any>(false);

  const [isLoadingPayoutVirtous, setIsLoadingPayoutVirtous] =
    useState<any>(false);

  const [isLoadingSellerWalletVirtous, setIsSellerWalletVirtous] =
    useState<any>(false);
  const [isLoadingBankAccountVirtous, setIsLoadingBankAccountVirtous] =
    useState<any>(false);
  const [isLoadingwithdrawRequestVirtous, setIsLoadingwithdrawRequestVirtous] =
    useState<any>(false);

  // const [invoiceOverViewDetails, setInvoiceOverViewDetails] = useState<any>();
  // const [filterOptions, setFilterOptions] = useState<any>(
  //   TX_ACCOUNT_OPTIONS_STATE
  // );
  const filterOptions = layout?.filterOptions;

  let txAccountOrderFillter: any = useMemo(() => {
    return [orderFilters];
  }, [orderFilters]);

  let txAccountInvoiceFillter: any = useMemo(() => {
    return [invoiceFilters];
  }, [invoiceFilters]);

  let txAccountPayoutFillter: any = useMemo(() => {
    return [payOutFilters];
  }, [payOutFilters]);

  let txAccountSellerWalletFilter: any = useMemo(() => {
    return [sellerWalletsFilter];
  }, [sellerWalletsFilter]);

  let txAccountbankAccountFilter: any = useMemo(() => {
    return [bankAccountFilter];
  }, [bankAccountFilter]);

  let txAccountWithdrawRequestFilterFilter: any = useMemo(() => {
    return [withdrawRequestFilter];
  }, [withdrawRequestFilter]);

  let txAccountOrderFilterMemo: any = useDebounce(
    JSON.stringify(txAccountOrderFillter),
    200
  );

  let txAccountInvoiceFilterMemo: any = useDebounce(
    JSON.stringify(txAccountInvoiceFillter),
    200
  );
  let txAccountPayoutFilterMemo: any = useDebounce(
    JSON.stringify(txAccountPayoutFillter),
    200
  );
  let txAccountSelFilterMemo: any = useDebounce(
    JSON.stringify(txAccountSellerWalletFilter),
    200
  );
  let txAccountbankAccountFilterMemo: any = useDebounce(
    JSON.stringify(txAccountbankAccountFilter),
    200
  );
  let txAccountWithdrawRequestFilterMemo: any = useDebounce(
    JSON.stringify(txAccountWithdrawRequestFilterFilter),
    200
  );

  let orderFiltersChanged = JSON.parse(txAccountOrderFilterMemo)?.[0];

  delete orderFiltersChanged?.bulk_order;

  const orderFilterCopy = layout.commonFilter?.orderFilters;

  // GET TXACCOUNT FOR ORDERS LISTING
  const {
    isLoading: txaccountListingOrdersLoading,
    data: txaccountlistingOrdersData,
    isFetching: txaccountListingOrdersFetching,
    refetch: orderListingRefetch,
  } = useQuery(
    [`TX-ACCOUNT-ORDERS-LISTING`, orderFilterCopy],
    () =>
      getTXAccountOrdersListings({
        orderFilters: orderFilterCopy,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      enabled: tab === "Orders" && true,
      onSettled: (ee) => {
        setIsLoadingOrderVirtous(false);
      },
    }
  );

  let OrderwalletAmount = JSON.parse(txAccountOrderFilterMemo)?.[0];
  delete OrderwalletAmount?.page;
  delete OrderwalletAmount?.per_page;
  delete OrderwalletAmount?.sort_order;
  delete OrderwalletAmount?.order_by;
  delete OrderwalletAmount?.bulk_order;

  // ORDER - WALLET BALANCE
  const { isLoading: orderWalletLoading, isFetching: orderWalletFetching } =
    useQuery(
      [`TX-ACCOUNT-ORDERS-WALLET-BALANCE`, countAPi],
      () =>
        ordersWalletBalance({
          orderFilters: orderFilterCopy,
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        cacheTime: 0,
        enabled: tab === "Orders" && true,
        onSettled: (data: any) => {
          layout.setOrdersOverViewDetails((current: any) => {
            return {
              ...current,
              ...{
                overview: {
                  total_orders: data?.data?.total_orders,
                  tixstock_revenue: data?.data?.tixstock_revenue,
                  total_revenue: data?.data?.total_revenue,
                  settlement_net_payout: data?.data?.settlement_net_payout,
                  tixstock_currency_balances:
                    data?.data?.tixstock_currency_balances,
                },
              },
            };
          });
        },
      }
    );

  const fetchInvoiceList = async (list: any) => {
    layout.setCustomLoader(true);
    const data = {
      page: 1,
      per_page: 20,
      id: list?.id,
      invoice_type: list?.invoice_type,
    };
    let response = await invoiceViewAPI(data);
    layout.setInvoicesViewData({ ...response, status: true, id: list?.id });
    layout.setCustomLoader(false);
  };

  const invoiceFiltersCopy = layout.commonFilter?.invoicesFilter;
  // GET TX ACCOUNT FOR INVOICE LISTING
  const { isLoading: txaccountListingInvoicesLoading } = useQuery(
    [
      QUERIES.TX_ACCOUNT_INVOICE_LISTINGS,
      // ...JSON.parse(txAccountInvoiceFilterMemo),
      invoiceFiltersCopy,
    ],
    () =>
      getTXAccountInvoiceListings({
        invoiceFilters: invoiceFiltersCopy,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled: tab === "Invoices" && true,
      onSettled(data, error) {
        if (data) {
          if (data?.data?.invoices?.length > 0) {
            layout.setInvoiceList((prevOrders: any) => {
              const updatedOrders =
                data?.meta?.current_page > 1
                  ? _.uniqBy(
                      [...prevOrders, ...data?.data?.invoices],
                      "invoice_number"
                    )
                  : data?.data?.invoices;
              if (layout.invoicesFilter?.openView === true) {
                fetchInvoiceList(updatedOrders[0]).finally(() => {
                  layout.setInvoicesFilter((curr: any) => {
                    return {
                      ...curr,
                      openView: undefined,
                    };
                  });
                  layout.setCommonFilter((pre: any) => {
                    return {
                      orderFilters: TX_ACCOUNT_ORDER_INITIAL_FILTER,
                      orderPayoutSeller: TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
                      invoicesFilter: {
                        ...pre.invoicesFilter,
                        openView: undefined,
                      },
                    };
                  });
                });
              }
              return updatedOrders;
            });

            // layout.setInvoiceList(data?.data?.invoices);
          } else {
            layout.setInvoiceList([]);
          }
        }
        setIsLoadingInvoicesVirtous(false);

        if (data)
          layout?.setInvoiceOverViewDetails((pre: any) => ({
            ...pre,
            overview:
              data?.meta?.current_page === 1
                ? data.data?.revenue
                : pre?.overview,
            meta: data?.meta,
          }));
      },
    }
  );

  const payOutFiltersCopy = layout.commonFilter?.orderPayoutSeller;

  // GET TX ACCOUNT FOR PAYOUT LISTING
  const { isLoading: txaccountListingPayoutLoading } = useQuery(
    [
      QUERIES.TX_ACCOUNT_PAYOUTS_LISTINGS,
      // ...JSON.parse(txAccountPayoutFilterMemo),
      payOutFiltersCopy,
    ],
    () =>
      getTXAccountPayoutListings({
        payOutFilters: payOutFiltersCopy,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled: tab === PAYOUT_TAB && true,
      onSettled(data, error) {
        if (data) {
          if (data?.data?.lists?.length > 0) {
            layout.setPayoutList((prevOrders: any) => {
              const updatedOrders =
                data?.meta?.current_page > 1
                  ? _.uniqBy([...prevOrders, ...data?.data?.lists], "id")
                  : data?.data?.lists;
              return updatedOrders;
            });
          } else {
            layout.setPayoutList([]);
          }
          setIsLoadingPayoutVirtous(false);
        }
        layout.setPayoutsOverViewDetails((pre: any) => ({
          ...pre,
          overview:
            data?.meta?.current_page === 1 ? data.data?.revenue : pre?.overview,
          meta: data?.meta,
          counts:
            data?.meta?.current_page === 1 ? data?.data?.counts : pre?.counts,
        }));
      },
    }
  );
  // GET TX ACCOUNT FOR SELLER WALLET
  const {
    isLoading: txaccountListingSellerWalletLoading,
    refetch: sellerRefetch,
  } = useQuery(
    [
      QUERIES.TX_ACCOUNT_SELLER_WALLET_LISTIGNS,
      ...JSON.parse(txAccountSelFilterMemo),
    ],
    () =>
      getTXAccountSellerWalletListings({
        sellerWalletsFilter,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled: tab === "Seller wallets" && true,
      onSettled(data, error) {
        if (data) {
          if (data?.data?.wallet_list?.length > 0) {
            layout.setSellerWalletsList((prevOrders: any) => {
              const updatedOrders =
                data?.meta?.current_page > 1
                  ? _.uniqBy(
                      [...prevOrders, ...data?.data?.wallet_list],
                      "team_id"
                    )
                  : data?.data?.wallet_list;
              return updatedOrders;
            });
          } else {
            layout.setSellerWalletsList([]);
          }
          if (data?.meta?.current_page === 1) {
            if (data?.data?.revenue) {
              layout.setCurrencyBalance(() => {
                return {
                  overview: data?.data?.revenue,
                };
              });
            } else {
              layout.setCurrencyBalance({
                overview: undefined,
              });
            }
          }
          setIsSellerWalletVirtous(false);
        }

        layout.setSellerWalletsMeta(data?.meta);
      },
    }
  );
  // GET TX ACCOUNT FOR BANK ACCOUNT
  const {
    isLoading: txaccountListingBankAccountLoading,
    refetch: bankAccountRefetch,
  } = useQuery(
    [
      QUERIES.TX_ACCOUNT_BANK_ACCOUNT_LISTIGNS,
      ...JSON.parse(txAccountbankAccountFilterMemo),
    ],
    () =>
      getTXAccountBankAccountListings({
        bankAccountFilter,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled: tab === "Bank accounts" && true,
      onSettled(data, error) {
        if (data) {
          if (data?.data?.length > 0) {
            layout.setBankAccountList((prevOrders: any) => {
              const updatedOrders =
                data?.meta?.current_page > 1
                  ? _.uniqBy([...prevOrders, ...data?.data], "team_id")
                  : data?.data;

              return updatedOrders;
            });
          } else {
            layout.setBankAccountList([]);
          }
          if (data?.meta?.current_page === 1) {
            if (data?.data?.revenue) {
              layout.setCurrencyBalance(() => {
                return {
                  overview: data?.data?.revenue,
                };
              });
            } else {
              layout.setCurrencyBalance({
                overview: undefined,
              });
            }
          }
          setIsLoadingBankAccountVirtous(false);
        }

        layout.setBankAccountMeta(data?.meta);
      },
    }
  );
  // GET TX ACCOUNT FOR WITHDRAW REQUEST
  const {
    isLoading: txaccountListingWithdrawRequestLoading,
    refetch: withdrawListingRefetch,
  } = useQuery(
    [
      QUERIES.TX_ACCOUNT_WITHDRAW_REQUEST_LISTIGNS,
      ...JSON.parse(txAccountWithdrawRequestFilterMemo),
    ],
    () =>
      getTXAccountWithdrawRequestListings({
        withdrawRequestFilter,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled: tab === "Withdraw requests" && true,
      onSettled(data, error) {
        if (data) {
          if (data?.data?.lists?.length > 0) {
            layout.setWithdrawRequestList((prevOrders: any) => {
              const updatedOrders =
                data?.meta?.current_page > 1
                  ? _.uniqBy([...prevOrders, ...data?.data?.lists], "id")
                  : data?.data?.lists;
              return updatedOrders;
            });
          } else {
            layout.setWithdrawRequestList([]);
          }
          if (data?.meta?.current_page === 1) {
            if (data?.data?.revenue) {
              layout.setCurrencyBalance(() => {
                return {
                  overview: data?.data?.revenue,
                };
              });
            } else {
              layout.setCurrencyBalance({
                overview: undefined,
              });
            }
          }
          setIsLoadingwithdrawRequestVirtous(false);
        }

        layout.setWithdrawRequestMeta(data?.meta);
      },
    }
  );

  // GET FILTERS OPTIONS
  useQuery("TX-ACCOUNT-OPTIONS", () => getReportsOptions(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    onError: (err) => {},
    enabled: !txaccountListingOrdersLoading,
    onSettled(data, error) {
      if (data?.data) {
        const teams = coreLayout?.allowedAccess?.teams;
        const orderStatusOptions: any = data?.data?.order_statuses
          ?.flatMap((group: any) => group.data)
          ?.filter(
            (option: any) =>
              option?.description === "Completed" ||
              option?.description === "Cancelled" ||
              option?.description === "Awaiting Delivery"
          );
        const paymentOptions = [
          { id: "paid", name: "Paid", value: "yes" },
          { id: "unpaid", name: "Unpaid", value: "no" },
        ];
        const marketplacepaymentstatus = [
          { id: "paid", name: "Invoiced", value: "1" },
          { id: "unpaid", name: "Not Invoiced", value: "0" },
        ];

        layout.setFilterOptions((pre: any) => {
          return {
            ...pre,
            options_teams: teams,
            options_marketplace: data?.data?.order_exchanges?.filter(
              (option: any) => option?.name !== "Tgces"
            ),
            options_orderstatus: orderStatusOptions,
            options_internalorderstatus: data?.data?.internal_order_statuses,
            options_paymentstatus: paymentOptions,
            options_marketplace_paymentstatus: marketplacepaymentstatus,
            options_invoice_statuses: data?.data?.invoice_statuses,
            options_walletstatus: data?.data?.wallet_statuses,
            options_withdraw_request_statuses:
              data?.data?.withdraw_request_statuses,
            options_marketplace_currencies: data?.data?.marketplace_currencies,
          };
        });
      }
    },
  });

  // HANDLE CHANGE
  const handleOnCommonFilter = (data: any) => {
    if (tab === "Orders") {
      layout.setOrderFilters((pre: any) => {
        let final = {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          page: 1,
        };
        if (!final?.bulk_order) {
          final.order_ids = [];
        }
        return final;
      });
    }
    if (tab === "Invoices") {
      layout.setInvoicesFilter((pre: any) => {
        let invoiceStatus: any = pre?.invoice_status;

        if (
          "checked" in data?.target &&
          data?.target?.checked === true &&
          !data?.target?.value
        ) {
          const foundOption = filterOptions?.options_invoice_statuses?.find(
            (item: any) =>
              lowerCase(item.name) === lowerCase(data?.target?.name)
          );

          if (foundOption) {
            invoiceStatus = invoiceStatus
              ? [...invoiceStatus, foundOption]
              : [foundOption];
          }
        } else if ("checked" in data?.target) {
          invoiceStatus = invoiceStatus?.filter(
            (item: any) =>
              lowerCase(item?.name) !== lowerCase(data?.target?.name)
          );
        }
        return {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          ...("checked" in data?.target && !data?.target?.value
            ? { invoice_status: invoiceStatus }
            : data?.target?.name === "invoice_status"
            ? { invoice_status: data?.target?.value }
            : {}),
          page: 1,
        };
      });
    }
    if (tab === PAYOUT_TAB) {
      layout.setPayOutFilter((pre: any) => {
        return {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          page: 1,
        };
      });
    }
    if (tab === "Seller wallets") {
      layout.setSellerWalletsFilter((pre: any) => {
        return {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          page: 1,
        };
      });
    }
    if (tab === "Bank accounts") {
      layout.setBankAccountFilter((pre: any) => {
        return {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          page: 1,
        };
      });
    }
    if (tab === "Withdraw requests") {
      layout.setWithdrawRequestFilter((pre: any) => {
        return {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          page: 1,
        };
      });
    }
  };

  // ON ENTER SEARCH
  const handleKeyDown = (event: any) => {
    layout.setOrderFilters((currentValue: any) => {
      return {
        ...currentValue,
        query: event?.target?.value,
      };
    });
  };

  // RESET FOOTER BUTTON WHEN ANY FILTER CHANGE
  useEffect(() => {
    layout.setCommonConfirmation((pre: any) => {
      return {
        ...pre,
        draft_invoice_initial_step: false,
      };
    });
    layout?.setOrderPayoutSeller((current: any) => {
      return {
        ...current,
        initial_step: false,
      };
    });
  }, [layout.orderFilters]);

  // const bulkOrderHeight: any =
  //   document.getElementById("filters_block1")?.clientHeight;

  // STICKY HEADER - CODE
  useEffect(() => {
    if (stickyElement) {
      const bulk_order = document.getElementById("bulk_order");
      if (stickyElement) {
        const initialPosition = stickyElement.getBoundingClientRect().top;
        let MarginTopTotle = 0;
        let topPosition = 0;
        const recalculateMarginTopTotle = () => {
          const accordion = document.getElementById(
            "inventoryaccordion"
          ) as HTMLElement;
          const accordionStyle = window.getComputedStyle(accordion);
          const accordionheight: any = parseFloat(
            accordionStyle.getPropertyValue("height")
          );

          const filter = document.getElementById(
            "filters_block"
          ) as HTMLElement;
          const heightheightRef2 = filter.offsetHeight;

          const salesFilter: any =
            document.getElementById("salesFilter")?.clientHeight;

          const filters_block1: any =
            document.getElementById("filters_block1")?.clientHeight;

          const HeaderHeight: any =
            document.querySelector("header")?.clientHeight;

          var computedStyle = window.getComputedStyle(stickyElement);
          var paddingBottom = computedStyle.paddingBottom;
          var paddingBottomValue = parseInt(paddingBottom, 10);

          // console.log(bulkOrderHeight, "bulkOrderHeight");

          // topPosition = heightheightRef2 + salesFilter + HeaderHeight + 20;
          if (window.matchMedia("(max-width: 767px)").matches) {
            topPosition = HeaderHeight;
          } else {
            topPosition =
              heightheightRef2 +
              salesFilter +
              HeaderHeight +
              (orderFilters?.bulk_order ? filters_block1 : 0) +
              paddingBottomValue -
              1;
          }
          // Recalculate MarginTopTotle based on updated heights or values
          const heightheightRef1 = heightRef1?.current?.offsetHeight;
          MarginTopTotle =
            IsToggle === true || orderFilters?.bulk_order === true
              ? heightheightRef1 + accordionheight - HeaderHeight
              : HeaderHeight +
                (orderFilters?.bulk_order ? filters_block1 : 0) -
                heightheightRef1;
        };

        const makeSticky = function () {
          const HeaderHeight: any =
            document.querySelector("header")?.clientHeight;
          const sticky_head: any = document.getElementById("sticky-head");
          const stickyShadow: any = document.getElementById("sticky-shadow");
          const sticky_headHeight: any = sticky_head?.clientHeight;

          recalculateMarginTopTotle();
          if (window.scrollY + HeaderHeight >= initialPosition) {
            stickyElement.classList.add("sticky");
            stickyElement.style.top =
              IsToggle === true || orderFilters?.bulk_order === true
                ? `-${MarginTopTotle}px`
                : `${MarginTopTotle}px`;
            sticky_head && (sticky_head.style.top = topPosition + "px");
            stickyShadow.style.top = topPosition + sticky_headHeight + "px";
          } else {
            stickyElement.classList.remove("sticky");
            stickyElement.style.top = `0px`;
            sticky_head && (sticky_head.style.top = "0px");
            stickyShadow.style.top = "0px";
          }
        };
        const handleWindowResize = () => {
          // Handle logic when the window is resized
          makeSticky(); // Update sticky behavior based on new values
          recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
        };
        window.addEventListener("scroll", makeSticky);
        window.addEventListener("change", makeSticky);
        window.addEventListener("blur", makeSticky);
        // bulk_order?.addEventListener("click", makeSticky);

        setTimeout(() => {
          document.addEventListener("resize", handleWindowResize);
        }, 200);
        return () => {
          window.removeEventListener("scroll", makeSticky);
          window.removeEventListener("change", makeSticky);
          window.removeEventListener("blur", makeSticky);
          document.removeEventListener("resize", handleWindowResize);
        };
      }
    }
  }, [
    toggleRef,
    IsToggle,
    overViewHeight,
    stickyElement,
    layout.orderFilters?.bulk_order,
  ]);

  // UPDATE ORDER TAB - STATE
  useEffect(() => {
    const setOrderList = async () => {
      if (!txaccountListingOrdersFetching) {
        const { data, meta } = txaccountlistingOrdersData || {};
        const orders = data?.orders || [];

        if (orders.length > 0) {
          const newData = await Promise.all(
            orders.map((order: any) => ({ ...order }))
          );

          layout.setOrderList((prevOrders: any) => {
            const updatedOrders =
              meta?.current_page > 1
                ? _.uniqBy([...prevOrders, ...newData], "order_id")
                : newData;
            return updatedOrders;
          });
        } else {
          layout.setOrderList([]);
        }
      }
    };

    setOrderList();
    layout?.setOrdersOverViewDetails((pre: any) => ({
      ...pre,
      ...(txaccountlistingOrdersData?.data?.revenue &&
        txaccountlistingOrdersData?.meta?.current_page === 1 && {
          overview: txaccountlistingOrdersData.data.revenue,
        }),
      ...(txaccountlistingOrdersData?.meta && {
        meta: txaccountlistingOrdersData?.meta,
      }),
    }));
  }, [txaccountlistingOrdersData, txaccountListingOrdersFetching]);

  // STATE TYPE
  const stateReturn = (type: string) => {
    switch (type) {
      case "state":
        return tab === "Orders"
          ? orderFilters
          : tab === "Invoices"
          ? invoiceFilters
          : tab === PAYOUT_TAB
          ? payOutFilters
          : tab === "Seller wallets"
          ? sellerWalletsFilter
          : tab === "Bank accounts"
          ? bankAccountFilter
          : tab === "Withdraw requests"
          ? withdrawRequestFilter
          : "";

      case "loadingState":
        return tab === "Orders"
          ? orderFilterCopy
          : tab === "Invoices"
          ? invoiceFiltersCopy
          : tab === PAYOUT_TAB
          ? payOutFiltersCopy
          : tab === "Seller wallets"
          ? sellerWalletsFilter
          : tab === "Bank accounts"
          ? bankAccountFilter
          : tab === "Withdraw requests"
          ? withdrawRequestFilter
          : "";

      case "loading":
        return tab === "Orders"
          ? txaccountListingOrdersLoading
          : tab === "Invoices"
          ? txaccountListingInvoicesLoading
          : tab === PAYOUT_TAB
          ? txaccountListingPayoutLoading
          : tab === "Seller wallets"
          ? txaccountListingSellerWalletLoading
          : tab === "Bank accounts"
          ? txaccountListingBankAccountLoading
          : tab === "Withdraw requests"
          ? txaccountListingWithdrawRequestLoading
          : false;

      case "total":
        return tab === "Orders"
          ? layout?.ordersOverViewDetails?.meta?.total
          : tab === "Invoices"
          ? invoiceOverViewDetails?.meta?.total
          : tab === PAYOUT_TAB
          ? layout.payoutsOverViewDetails?.meta?.total
          : tab === "Seller wallets"
          ? layout?.sellerWalletsMeta?.total
          : tab === "Bank accounts"
          ? layout.bankAccountMeta?.total
          : tab === "Withdraw requests"
          ? layout?.withdrawRequestMeta?.total
          : "";

      case "balance":
        return tab === "Orders"
          ? layout?.ordersOverViewDetails?.overview?.tixstock_currency_balances
          : tab === "Invoices"
          ? invoiceOverViewDetails?.overview?.tixstock_currency_balances
          : tab === PAYOUT_TAB
          ? layout.payoutsOverViewDetails?.overview?.tixstock_currency_balances
          : tab === "Seller wallets" ||
            tab === "Bank accounts" ||
            tab === "Withdraw requests"
          ? layout?.currencyBalance?.overview?.tixstock_currency_balances
          : "";
      default:
        break;
    }
  };

  // PROGRESS LOADER
  const progressLoader = () => {
    // MAP WHICH IS STATE
    const filterSetterMap: any = {
      Orders: {
        page: orderFilters?.page,
        loader: txaccountListingOrdersLoading,
        title: "orders",
      },
      Invoices: {
        page: invoiceFilters?.page,
        loader: txaccountListingInvoicesLoading,
        title: "invoices",
      },
      [PAYOUT_TAB]: {
        page: payOutFilters?.page,
        loader: txaccountListingPayoutLoading,
        title: "payouts",
      },
      "Seller wallets": {
        page: sellerWalletsFilter?.page,
        loader: txaccountListingSellerWalletLoading,
        title: "seller wallets",
      },
      "Bank accounts": {
        page: bankAccountFilter?.page,
        loader: txaccountListingBankAccountLoading,
        title: "Bank accounts",
      },
      "Withdraw requests": {
        page: withdrawRequestFilter?.page,
        loader: txaccountListingWithdrawRequestLoading,
        title: "Withdraw requests",
      },
    };
    return filterSetterMap[tab];
  };

  const bulkOrderActionExist = layout.filterItems?.find(
    (obj: any) => obj?.name === "Order Ids" && obj?.checked === true
  );

  function ToggleElemClose() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }

  const filterVisibile =
    (orderFilters?.seller_levels && orderFilters?.seller_levels?.length > 0) ||
    orderFilters?.teams?.length > 0 ||
    orderFilters?.order_ids?.length > 0 ||
    _.size(orderFilters?.marketplace) > 0 ||
    (orderFilters?.transcation_date &&
      orderFilters?.transcation_date?.startDate !== undefined &&
      orderFilters?.transcation_date?.endDate !== undefined) ||
    (orderFilters?.event_date &&
      orderFilters?.event_date?.startDate !== undefined &&
      orderFilters?.event_date?.endDate !== undefined) ||
    orderFilters?.order_status?.length > 0 ||
    orderFilters?.internal_order_status?.length > 0 ||
    orderFilters?.payment_status?.length > 0 ||
    orderFilters?.store_id ||
    _.size(invoiceFilters?.marketplace) > 0 ||
    (invoiceFilters?.invoices_date &&
      invoiceFilters?.invoices_date?.startDate !== undefined &&
      invoiceFilters?.invoices_date?.endDate !== undefined) ||
    (invoiceFilters?.payment_date &&
      invoiceFilters?.payment_date?.startDate !== undefined &&
      invoiceFilters?.payment_date?.endDate !== undefined) ||
    invoiceFilters?.invoice_status?.length > 0 ||
    payOutFilters?.teams?.length > 0 ||
    (payOutFilters?.pay_out_date &&
      payOutFilters?.pay_out_date?.startDate !== undefined &&
      payOutFilters?.pay_out_date?.endDate !== undefined) ||
    _.size(orderFilters?.marketplaces_payment_status) > 0 ||
    _.size(orderFilters?.event_id) > 0 ||
    orderFilters?.marketplace_currency ||
    orderFilters?.query ||
    invoiceFilters?.query ||
    // SELLER WALLET
    size(sellerWalletsFilter?.teams) > 0 ||
    size(sellerWalletsFilter?.statuses) > 0 ||
    // BANK ACCOUNT FILTER
    size(bankAccountFilter?.teams) > 0 ||
    // WITHDRAW REQUEST
    size(withdrawRequestFilter?.teams) > 0 ||
    (tab === "Withdraw requests" && size(withdrawRequestFilter?.statuses) > 0
      ? true
      : false) ||
    (withdrawRequestFilter?.requestedDate?.startDate !== undefined &&
      withdrawRequestFilter?.requestedDate?.endDate !== undefined);

  // CLEAR ALL FILTER
  const handleOnclear = () => {
    if (tab === "Orders") {
      layout.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
      setInputChange("");
    }
    if (tab === "Invoices") {
      let isQuery: any = document.getElementById("simple-search");
      isQuery.value = "";
      layout.setInvoicesFilter(TX_ACCOUNT_INVOICE_INITIAL_FILTER);
    }
    if (tab === PAYOUT_TAB) {
      let isQuery: any = document.getElementById("simple-search");
      isQuery.value = "";
      layout.setPayOutFilter(TX_ACCOUNT_PAYOUT_INTIAL_FILTER);
    }
    if (tab === "Seller wallets") {
      layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
    }
    if (tab === "Bank accounts") {
      layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
    }
    if (tab === "Withdraw requests") {
      layout.setWithdrawRequestFilter((current: any) => {
        return {
          ...TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
          statuses: null,
        };
      });
    }
    layout.setCommonFilter((pre: any) => {
      return {
        orderFilters: TX_ACCOUNT_ORDER_INITIAL_FILTER,
        orderPayoutSeller: TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
        invoicesFilter: TX_ACCOUNT_INVOICE_INITIAL_FILTER,
      };
    });
  };
  const handleEventSearch = (): void => {
    const isMobileView = window.matchMedia("(max-width: 768px)").matches;
    if (inputChange && isMobileView) {
      layout.setOrderFilters((currentValue: any) => {
        return {
          ...currentValue,
          query: inputChange,
        };
      });
    }
  };

  const activeFilterLength = useMemo(() => {
    return getTotalActiveFilters({
      orderFilters,
      invoiceFilters,
      payOutFilters,
      sellerWalletsFilter,
      bankAccountFilter,
      withdrawRequestFilter,
      tab,
    });
  }, [
    orderFilters,
    invoiceFilters,
    payOutFilters,
    sellerWalletsFilter,
    bankAccountFilter,
    withdrawRequestFilter,
    tab,
  ]);

  const commonfilterData =
    layout.isTabActive === "Orders"
      ? orderFilters
      : layout.isTabActive === "Invoices"
      ? invoiceFilters
      : payOutFilters;

  const onTabChange = () => {
    // setApplyFilter((pre: any) => {
    //   return 0;
    // });
    window.scrollTo({ top: 0 });
    setCountApi((pre: any) => {
      return 0;
    });
    // setFilterChipsVisible({
    //   count: 0,
    //   show: false,
    //   filterData: {
    //     page: 1,
    //     per_page: 20,
    //   },
    // });

    layout.setCommonFilter((pre: any) => {
      return {
        orderFilters: TX_ACCOUNT_ORDER_INITIAL_FILTER,
        orderPayoutSeller: TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
        invoicesFilter:
          pre.openView === true
            ? pre.invoicesFilter
            : TX_ACCOUNT_INVOICE_INITIAL_FILTER,
      };
    });
  };

  const handleOnApplyFilter = (data: any, length: any, filterData: any) => {
    if (data === "apply" || data === "remove") {
      setCountApi((pre: any) => {
        return pre + 1;
      });
    }

    if (layout.isTabActive === "Orders") {
      layout.setCommonFilter((pre: any) => {
        return {
          orderFilters: filterData,
          orderPayoutSeller: TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
          invoicesFilter: TX_ACCOUNT_INVOICE_INITIAL_FILTER,
        };
      });
    }
    if (layout.isTabActive === "Invoices") {
      layout.setCommonFilter((pre: any) => {
        return {
          orderFilters: TX_ACCOUNT_ORDER_INITIAL_FILTER,
          orderPayoutSeller: TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
          invoicesFilter: filterData,
        };
      });
    }
    if (layout.isTabActive === PAYOUT_TAB) {
      layout.setCommonFilter((pre: any) => {
        return {
          orderFilters: TX_ACCOUNT_ORDER_INITIAL_FILTER,
          orderPayoutSeller: filterData,
          invoicesFilter: TX_ACCOUNT_INVOICE_INITIAL_FILTER,
        };
      });
    }
  };

  // useEffect(() => {
  //   if (
  //     (stateReturn("state")?.order_by && stateReturn("state")?.sort_order) ||
  //     (stateReturn("state")?.order_by &&
  //       stateReturn("state")?.sort_order === undefined)
  //   ) {
  //     handleOnApplyFilter("remove", 0, stateReturn("state"));
  //   }
  // }, [stateReturn("state")?.order_by && stateReturn("state")?.sort_order]);

  return (
    <>
      <div className="main-content-wrap">
        <div
          className={`topFilters sticky-element top-[3.75rem] z-[11] pb-5 bg-gray-200 max-md:!static`}
          id="topFilters"
        >
          <div className="top-accor shadow-op2">
            {/* HEADER */}
            <HeaderFilterContainer
              heightRef1={heightRef1}
              setIsToggle={() => {
                setIsToggle((pre: any) => {
                  let height3: any;

                  setTimeout(() => {
                    height3 = pre ? element?.offsetHeight : 0;
                    setOverViewHeight(height3);
                  }, 400);
                  return !pre;
                });
              }}
            />

            <div
              className="bg-white border-t accordion-collapse font-medium collapse show"
              id="inventoryaccordion"
              data-te-collapse-item
              data-te-collapse-show
              aria-labelledby="headingOne"
            >
              {/* TABS */}
              <Tabs onTabChange={onTabChange} />

              {/* ACCOUNTS BALANCE  */}
              <AccountBalanceWithFlag
                value={stateReturn("balance")}
                loading={
                  stateReturn("state")?.page === 1 &&
                  (tab === "Orders"
                    ? orderWalletLoading
                    : stateReturn("loading"))
                    ? true
                    : false
                }
              />

              <div
                className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
                role="tabpanel"
                data-te-tab-active
              >
                {/*FIRST LAYER */}
                <div className="px-5 pt-2">
                  <div className="divide-gray-200 divide-y-2">
                    <div className="flex flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full filter_scroll -ml-5">
                      {layout.isTabActive === "Orders" && (
                        <OrdersFilterCountBoxsWrapper
                          ordersOverViewDetails={
                            layout?.ordersOverViewDetails?.overview
                          }
                          loading={
                            stateReturn("state")?.page === 1 &&
                            orderWalletLoading
                              ? true
                              : false
                          }
                          baseCurrency={baseCurrency}
                        />
                      )}
                      {layout.isTabActive === "Invoices" && (
                        <InvoicesFilterCountBoxsWrapper
                          invoiceOverViewDetails={invoiceOverViewDetails}
                          handleOnchange={handleOnCommonFilter}
                          loading={
                            stateReturn("state")?.page === 1 &&
                            stateReturn("loading")
                              ? true
                              : false
                          }
                          baseCurrency={baseCurrency}
                        />
                      )}
                      {layout.isTabActive === PAYOUT_TAB && (
                        <PayoutsFilterCountBoxsWrapper
                          payoutsOverDetails={
                            layout.payoutsOverViewDetails?.counts
                          }
                          loading={
                            stateReturn("state")?.page === 1 &&
                            stateReturn("loading")
                              ? true
                              : false
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* SECOND LAYER FILTERS */}
            <div
              className="filtersMob max-md:flex max-md:flex-col"
              id={"filtersMob"}
            >
              <div
                className="flex flex-wrap border-b border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
                id={`filters_block`}
              >
                <div
                  className="closePopup hidden max-md:flex"
                  id="closePopup"
                  onClick={ToggleElemClose}
                >
                  <IKTSVG
                    path={close}
                    className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
                    svgClassName="w-4 h-4"
                  />
                </div>
                <CommonFilter
                  activeTab={tab}
                  handleOnChange={handleOnCommonFilter}
                  handleKeyDown={handleKeyDown}
                  options={filterOptions}
                  inputChange={inputChange}
                  setInputChange={setInputChange}
                  value={stateReturn("state")}
                  loading={
                    stateReturn("loadingState")?.page === 1 &&
                    stateReturn("loading")
                      ? true
                      : false
                  }
                  handleOnApplyFilter={handleOnApplyFilter}
                  filterVisibile={filterVisibile}
                />
              </div>
              <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
                <button
                  className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
                    filterVisibile
                      ? "bg-white text-violet-800 border-gray-300"
                      : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
                  }`}
                  onClick={handleOnclear}
                >
                  Clear
                </button>

                <button
                  className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
                  onClick={() => {
                    ToggleElemClose();
                    handleEventSearch();
                    handleOnApplyFilter("apply", activeFilterLength, {
                      ...commonfilterData,
                      page: 1,
                    });
                  }}
                >
                  Apply
                </button>
              </div>
            </div>

            {/* ORDER IDS FILTER */}
            {orderFilters?.bulk_order &&
              tab === "Orders" &&
              bulkOrderActionExist && (
                <BulkExcludeOrdersFilter
                  loading={
                    stateReturn("state")?.page === 1 && stateReturn("loading")
                      ? true
                      : false
                  }
                  handleOrderId={(data: any) => {
                    handleOnCommonFilter(data);
                    handleOnApplyFilter("apply", 0, {
                      ...orderFilterCopy,
                      order_ids: data?.target?.value,
                      page: 1,
                    });
                  }}
                />
              )}

            {/*THIRD LAYER*/}
            <div
              className="flex flex-wrap customStyle relative bg-white max-md:border-t"
              id={`salesFilter`}
            >
              <FiltersChips
                totalList={stateReturn("total")}
                setInputChange={() => setInputChange("")}
                handleOnclear={handleOnclear}
                loading={
                  stateReturn("loadingState")?.page === 1 &&
                  stateReturn("loading")
                    ? true
                    : false
                }
                handleOnRemoveApplyFilter={handleOnApplyFilter}
              />
            </div>
          </div>
        </div>

        {/* DATA TABLE */}
        {layout.isTabActive === "Orders" && (
          <>
            {txaccountListingOrdersLoading && orderFilterCopy?.page === 1 ? (
              <TableShimmer loader={txaccountListingOrdersLoading} />
            ) : orderData && orderData?.length > 0 ? (
              <>
                <DataTableOrders
                  paginateData={layout?.ordersOverViewDetails?.meta}
                  data={orderData}
                  loading={isLoadingOrdersVirtous}
                  setIsLoading={setIsLoadingOrderVirtous}
                  filterOptions={filterOptions}
                  handleOnApplyFilter={handleOnApplyFilter}
                />
              </>
            ) : (
              <>
                <NoDataComponent />
              </>
            )}
          </>
        )}
        {layout.isTabActive === "Invoices" && (
          <>
            {txaccountListingInvoicesLoading &&
            invoiceFiltersCopy?.page === 1 ? (
              <TableShimmer loader={txaccountListingInvoicesLoading} />
            ) : invoiceData && invoiceData?.length > 0 ? (
              <>
                <DataTableInvoices
                  paginateData={invoiceOverViewDetails?.meta}
                  loading={isLoadingInvoicesVirtous}
                  setIsLoading={setIsLoadingInvoicesVirtous}
                  handleOnApplyFilter={handleOnApplyFilter}
                />
              </>
            ) : (
              <>
                <NoDataComponent />
              </>
            )}
          </>
        )}
        {layout.isTabActive === PAYOUT_TAB && (
          <>
            {txaccountListingPayoutLoading && payOutFiltersCopy?.page === 1 ? (
              <TableShimmer loader={txaccountListingPayoutLoading} />
            ) : payoutData && payoutData?.length > 0 ? (
              <DataTablePayouts
                paginateData={layout.payoutsOverViewDetails?.meta}
                loading={isLoadingPayoutVirtous}
                setIsLoading={setIsLoadingPayoutVirtous}
                handleOnApplyFilter={handleOnApplyFilter}
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
        {layout.isTabActive === "Seller wallets" && (
          <>
            {txaccountListingSellerWalletLoading &&
            sellerWalletsFilter?.page === 1 ? (
              <TableShimmer loader={txaccountListingSellerWalletLoading} />
            ) : layout.sellerWalletsList &&
              layout.sellerWalletsList?.length > 0 ? (
              <DataTableSellerwallet
                loading={isLoadingSellerWalletVirtous}
                setIsLoading={setIsSellerWalletVirtous}
                refetch={sellerRefetch}
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
        {layout.isTabActive === "Bank accounts" && (
          <>
            {txaccountListingBankAccountLoading &&
            bankAccountFilter?.page === 1 ? (
              <TableShimmer loader={txaccountListingBankAccountLoading} />
            ) : layout.bankAccountList && layout.bankAccountList?.length > 0 ? (
              <DataTableBankAccount
                loading={isLoadingBankAccountVirtous}
                setIsLoading={setIsLoadingBankAccountVirtous}
                bankAccountRefetch={bankAccountRefetch}
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
        {layout.isTabActive === "Withdraw requests" && (
          <>
            {txaccountListingWithdrawRequestLoading &&
            withdrawRequestFilter?.page === 1 ? (
              <TableShimmer loader={txaccountListingWithdrawRequestLoading} />
            ) : layout.withdrawRequestList &&
              layout.withdrawRequestList?.length > 0 ? (
              <DataTableWithdrawRequest
                loading={isLoadingwithdrawRequestVirtous}
                setIsLoading={setIsLoadingwithdrawRequestVirtous}
                refetch={withdrawListingRefetch}
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </div>

      {/* FOOTER  */}
      <Footer />

      {/* LOADER */}
      {progressLoader()?.loader && progressLoader()?.page === 1 && (
        <ProgressBardLoader
          LoadingText={`Loading your ${progressLoader()?.title} data`}
          secondCounter={"3"}
        />
      )}

      {layout.customLoader && (
        <ProgressBardLoader
          secondCounter={1}
          LoadingText={`Loading your ${tabConditions(tab)} data`}
        />
      )}
    </>
  );
};

export default TxAccount;
