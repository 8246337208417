/* eslint-disable array-callback-return */

import { getTotalActiveFilters } from "helpers/AssetHelpers";
import moment from "moment";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { TableVirtuoso } from "react-virtuoso";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  decimalNumber,
  getCurrencySymbol,
  handleScroll,
  ticketTypeRenameForSalesReport,
} from "../../../helpers/Functions";
import { SYMBOLS } from "../../../helpers/const";
import { display } from "../../Reports/core/_function";
import OrderDetail from "../../Reports/widgets/OrderDetail";
import { TxAccountContext } from "../core/TxAccountProvider";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  md:min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTableOrders = ({
  paginateData,
  data,
  loading,
  setIsLoading,
  filterOptions,
  handleOnApplyFilter,
}: any) => {
  const layout = useContext(TxAccountContext);
  const coreLayout = useContext(LayoutContext);
  const [showOrder, setShowOrder] = useState<any>({
    show: false,
    data: "",
  });
  const [height, setHeight] = useState<any | undefined>(undefined);

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");
  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#txaccount-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      // setTimeout(() => {
      //   nomoreListingHeight = nomoreListing?.clientHeight;
      // }, 2000);

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight + 1;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };
  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  const tableRef: any = useRef<any>();
  const stickyTableRef: any = useRef<any>();

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  // useEffect(() => {
  //   const handleScrollTable = () => {
  //     const EventAccordion = document.getElementById(
  //       "EventAccordion"
  //     ) as HTMLElement;

  //     const topfilterTop: any = document.getElementById("topFilters");

  //     const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
  //     // if (topValue.includes("-")) {
  //     // Check if the value contains a negative sign
  //     const currentValue = parseInt(topValue); // Extract the numeric value
  //     const newValue = Math.abs(currentValue); // Make it positive
  //     topfilterTop && (topfilterTop.style.top = ${newValue}px); // Apply the new positive value back as a string
  //     // }
  //     const windScroll: number = window.scrollY;

  //     const headingOne: any =
  //       document.getElementById("headingOne")?.clientHeight;
  //     const inventoryaccordion: any =
  //       document.getElementById("inventoryaccordion")?.clientHeight;
  //     // const totleScroll = headingOne + inventoryaccordion + 16;
  //     // const currentTop = parseInt(topfilterTop.style.top || "0");
  //     const topFiltersHeight = topfilterTop.clientHeight;
  //     let totleScroll = 0;
  //     if (window.matchMedia("(max-width: 767px)").matches) {
  //       totleScroll = topFiltersHeight;
  //     } else {
  //       totleScroll = headingOne + inventoryaccordion + 16;
  //     }

  //     const stickyHead: any = document.getElementById("sticky-head");
  //     const stickyShadow: any = document.getElementById("sticky-shadow");

  //     if (windScroll > totleScroll) {
  //       EventAccordion?.classList?.add("-mt-10");
  //       stickyHead?.classList.remove("opacity-0");
  //       stickyHead?.classList.remove("h-0");
  //       stickyHead?.classList.add("h-[2.5625rem]");
  //       stickyShadow.classList.add("!block");
  //     } else if (windScroll < totleScroll) {
  //       EventAccordion?.classList?.remove("-mt-10");
  //       stickyHead?.classList.add("opacity-0");
  //       stickyHead?.classList.add("h-0");
  //       stickyHead?.classList.remove("h-[2.5625rem]");
  //       stickyShadow.classList.remove("!block");
  //     }
  //   };
  //   document.addEventListener("scroll", handleScrollTable);
  //   document.addEventListener("resize", handleScrollTable);
  // }, []);
  useEffect(() => {
    const handleScrollTable = () => {
      const eventAccordion = document.getElementById("EventAccordion");
      const topFilters = document.getElementById("topFilters");

      if (topFilters) {
        const topValue = topFilters.style.top;
        if (topValue.includes("-")) {
          const currentValue = parseInt(topValue, 10);
          const newValue = Math.abs(currentValue);
          topFilters.style.top = `${newValue}px`;
        }
      }

      const windScroll = window.scrollY;
      const headingOneHeight =
        document.getElementById("headingOne")?.clientHeight || 0;
      const inventoryAccordionHeight =
        document.getElementById("inventoryaccordion")?.clientHeight || 0;
      const topFiltersHeight = topFilters?.clientHeight || 0;

      let totalScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totalScroll = topFiltersHeight;
      } else {
        totalScroll = headingOneHeight + inventoryAccordionHeight + 16;
      }

      const stickyHead = document.getElementById("sticky-head");
      const stickyShadow = document.getElementById("sticky-shadow");

      if (windScroll > totalScroll) {
        eventAccordion?.classList.add("-mt-10");
        stickyHead?.classList.remove("opacity-0", "h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow?.classList.add("!block");
      } else {
        eventAccordion?.classList.remove("-mt-10");
        stickyHead?.classList.add("opacity-0", "h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow?.classList.remove("!block");
      }
    };

    document.addEventListener("scroll", handleScrollTable);
    window.addEventListener("resize", handleScrollTable);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("scroll", handleScrollTable);
      window.removeEventListener("resize", handleScrollTable);
    };
  }, []);

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  // HANDLE ORDER VIEW PAGE
  const handleOrderView = (list: any, index: number) => {
    if (
      list?.order_id &&
      showOrder?.data?.order_id &&
      list?.order_id === showOrder?.data?.order_id
    ) {
      display(false);
      setShowOrder({
        show: false,
        data: "",
        index: "",
      });
    } else {
      setShowOrder({
        show: true,
        data: list,
        index: index,
      });
    }
  };

  const getColumnItems = () => {
    return layout.columnItems.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  // auto disabled left right table button
  useEffect(() => {
    const element = document.getElementById("tableId1");
    const stickyHead = document.getElementById("sticky-head");
    const scrollHandler = () => handleScroll(element, stickyHead);
    if (element) {
      element.removeEventListener("scroll", scrollHandler);
      element.addEventListener("scroll", scrollHandler);
      scrollHandler(); // Initial check
      return () => {
        element.removeEventListener("scroll", scrollHandler);
      };
    }
  }, []);

  useEffect(() => {
    display(true);
  }, [coreLayout.htmlFontSize]);

  const orderFilterCopy = layout.commonFilter?.orderFilters;

  return (
    <div
      className="accordion my-4 font-medium transition-all relative md:px-5 max-lg:!mr-0 lg2:min-h-[8.75rem]"
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none `}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="text-xs text-left  md:min-w-[87.5rem] absolute left-0 top-0 w-full">
          <thead className="text-xs text-gray-400 ">
            <tr className="border-b bg-white">
              {getColumnItems().map((columnItem: any, index: number) => {
                switch (columnItem.id) {
                  case 0:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Team name"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="name"
                          padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 1:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Team member"
                          width="min-w-[8.125rem]"
                          arrow={true}
                          postKey="username"
                          // padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 2:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Marketplace"
                          width="min-w-[12.8125rem]"
                          arrow={true}
                          postKey="exchange_title"
                        />
                      </React.Fragment>
                    );
                  case 3:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Order ID"
                          width="min-w-[7.5rem]  max-w-[7.5rem]"
                          arrow={false}
                          postKey="order_id"
                        />
                      </React.Fragment>
                    );
                  case 4:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          // title="Order value"
                          title={"Order date"}
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="order_date_time"
                        />
                      </React.Fragment>
                    );
                  case 5:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Proceed price"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="transaction_value"
                        />
                      </React.Fragment>
                    );
                  case 6:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Marketplace value"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          postKey="transaction_value"
                        />
                      </React.Fragment>
                    );
                  case 7:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Seller payout"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          postKey="transaction_value"
                        />
                      </React.Fragment>
                    );
                  case 8:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Tixstock revenue"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          postKey="tixstock_revenue"
                        />
                      </React.Fragment>
                    );
                  case 40:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Penalty amount"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          postKey="tixstock_revenue"
                        />
                      </React.Fragment>
                    );
                  case 9:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Event"
                          width="min-w-[14.375rem]"
                          arrow={true}
                          postKey="event_description"
                        />
                      </React.Fragment>
                    );
                  case 10:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Venue"
                          width="min-w-[10.625rem]"
                          arrow={true}
                          postKey="event_venue"
                        />
                      </React.Fragment>
                    );
                  case 11:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Event date"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="event_date"
                        />
                      </React.Fragment>
                    );
                  case 12:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Ticket details"
                          width="min-w-[10.625rem]"
                          arrow={true}
                          postKey="ticket_section"
                        />
                      </React.Fragment>
                    );
                  case 13:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Qty"
                          width="min-w-[3.4375rem]"
                          arrow={true}
                          postKey="ticket_quantity"
                        />
                      </React.Fragment>
                    );
                  case 14:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Ticket type"
                          width="min-w-[8.125rem]"
                          arrow={true}
                          postKey="ticket_type"
                        />
                      </React.Fragment>
                    );
                  case 15:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Order status"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="order_status"
                        />
                      </React.Fragment>
                    );
                  case 16:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Internal order status"
                          width="min-w-[8.875rem]"
                          arrow={false}
                          postKey="internal_order_status"
                        />
                      </React.Fragment>
                    );
                  case 17:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Payment status"
                          width="min-w-[7.5rem]"
                          arrow={true}
                          postKey="paid"
                        />
                      </React.Fragment>
                    );
                  case 18:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Marketplace ID"
                          width="min-w-[16.5625rem]"
                          arrow={false}
                          // postKey="exchange_id"
                        />
                      </React.Fragment>
                    );
                  case 43:
                    return (
                      <HeaderCell
                        key={index}
                        title="Buyer fees"
                        width="min-w-[8.125rem]"
                        arrow={false}
                      />
                    );
                  case 28:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Marketplace invoice status"
                          width="min-w-[7.5rem]"
                          arrow={false}
                          // postKey="exchange_id"
                        />
                      </React.Fragment>
                    );
                  case 44:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title={"Referral amount"}
                          width="min-w-[7.5rem]"
                          arrow={false}
                          // postKey="exchange_id"
                        />
                      </React.Fragment>
                    );
                  case 45:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title={"Referral status"}
                          width="min-w-[7.5rem]"
                          arrow={false}
                        />
                      </React.Fragment>
                    );
                  case 46:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title={"Net revenue"}
                          width="min-w-[7.5rem]"
                          arrow={false}
                        />
                      </React.Fragment>
                    );
                }
              })}

              <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem] max-md:static">
                <div
                  className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden"
                  id="left-shad-list"
                >
                  <div className="arrow_buttons flex rounded ">
                    <button
                      className="group rounded-l  transition px-[.5625rem] py-2"
                      type="button"
                      id="leftArrow"
                      onClick={(e: any) => scroll(-120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={angleLeftSVG}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                    <button
                      className="group rounded-r  transition px-[.5625rem] py-2"
                      type="button"
                      onClick={(e: any) => scroll(120)}
                      id="rightArrow"
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={angleRightSVG}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        {/* <EventFields /> */}
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              // style={{
              //   height: `${
              //     virtuosoHeight() > height ? virtuosoHeight() : height
              //   }px`,
              // }}
              //   style={{ height: height > 41 ? `${height}px` : "auto" }}
              style={{
                height: height > 41 ? `${height}px` : "auto",
              }}
            >
              <TableVirtuoso
                data={data}
                endReached={() => {
                  if (
                    paginateData?.current_page < paginateData?.last_page &&
                    !loading
                  ) {
                    setIsLoading(true);
                    layout.setOrderFilters((current: any) => {
                      let nextPage: number = paginateData?.current_page + 1;
                      const updatedFilters = {
                        ...current,
                        page: nextPage,
                        per_page: 20,
                      };

                      const length = getTotalActiveFilters({
                        orderFilters: updatedFilters,
                      });

                      handleOnApplyFilter("scroll", length, {
                        ...orderFilterCopy,
                        page: nextPage,
                        per_page: 20,
                      });

                      return updatedFilters;
                    });
                  }
                }}
                useWindowScroll
                overscan={200}
                totalCount={data?.length}
                // atBottomThreshold={100}
                id={"txaccount-lazyload"}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return (
                      <TableRow
                        {...props}
                        // hoveredId={hoveredId}
                        // setHoveredId={setHoveredId}
                        showOrder={showOrder}
                        showOrderId="order_id"
                      />
                    );
                  },
                  TableFoot: (props) => {
                    if (
                      data?.length === paginateData?.total &&
                      paginateData?.current_page > 1
                    ) {
                      return (
                        <tfoot
                          className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                          id={`NoMoreListing`}
                        >
                          <tr className="border-b bg-white">
                            <td
                              colSpan={11}
                              align="center"
                              className="h-[2.8125rem]"
                            >
                              No more items to show
                            </td>
                          </tr>
                        </tfoot>
                      );
                    } else {
                      return null;
                    }
                  },
                }}
                fixedFooterContent={() => {
                  return (
                    <tr className="border-b bg-white">
                      <td colSpan={0}>Hi</td>
                    </tr>
                  );
                }}
                fixedHeaderContent={() => (
                  <tr className="border-b bg-white">
                    {getColumnItems().map((columnItem: any, index: number) => {
                      switch (columnItem.id) {
                        case 0:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Team name"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="name"
                                padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 1:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Team member"
                                width="min-w-[8.125rem]"
                                arrow={true}
                                postKey="username"
                                // padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 2:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Marketplace"
                                width="min-w-[12.8125rem]"
                                arrow={true}
                                postKey="exchange_title"
                              />
                            </React.Fragment>
                          );
                        case 3:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Order ID"
                                width="min-w-[7.5rem] max-w-[7.5rem]"
                                arrow={false}
                                postKey="order_id"
                              />
                            </React.Fragment>
                          );
                        case 4:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                // title="Order value"
                                title={"Order date"}
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="order_date_time"
                              />
                            </React.Fragment>
                          );
                        case 5:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Proceed price"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="transaction_value"
                              />
                            </React.Fragment>
                          );
                        case 6:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Marketplace value"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                postKey="transaction_value"
                              />
                            </React.Fragment>
                          );
                        case 7:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Seller payout"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                postKey="transaction_value"
                              />
                            </React.Fragment>
                          );
                        case 8:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Tixstock revenue"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                postKey="tixstock_revenue"
                              />
                            </React.Fragment>
                          );
                        case 40:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Penalty amount"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                postKey="tixstock_revenue"
                              />
                            </React.Fragment>
                          );
                        case 9:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Event"
                                width="min-w-[14.375rem]"
                                arrow={true}
                                postKey="event_description"
                              />
                            </React.Fragment>
                          );
                        case 10:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Venue"
                                width="min-w-[10.625rem]"
                                arrow={true}
                                postKey="event_venue"
                              />
                            </React.Fragment>
                          );
                        case 11:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Event date"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="event_date"
                              />
                            </React.Fragment>
                          );
                        case 12:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Ticket details"
                                width="min-w-[10.625rem]"
                                arrow={true}
                                postKey="ticket_section"
                              />
                            </React.Fragment>
                          );
                        case 13:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Qty"
                                width="min-w-[3.4375rem]"
                                arrow={true}
                                postKey="ticket_quantity"
                              />
                            </React.Fragment>
                          );
                        case 14:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Ticket type"
                                width="min-w-[8.125rem]"
                                arrow={true}
                                postKey="ticket_type"
                              />
                            </React.Fragment>
                          );
                        case 15:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Order status"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="order_status"
                              />
                            </React.Fragment>
                          );
                        case 16:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Internal order status"
                                width="min-w-[8.875rem]"
                                arrow={true}
                                postKey="internal_order_status"
                              />
                            </React.Fragment>
                          );
                        case 17:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Payment status"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="paid"
                              />
                            </React.Fragment>
                          );
                        case 18:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Marketplace ID"
                                width="min-w-[16.5625rem]"
                                arrow={false}
                                // postKey="exchange_id"
                              />
                            </React.Fragment>
                          );
                        case 43:
                          return (
                            <HeaderCell
                              key={index}
                              title="Buyer fees"
                              width="min-w-[8.125rem]"
                              arrow={false}
                            />
                          );
                        case 28:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Marketplace invoice status"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                // postKey="exchange_id"
                              />
                            </React.Fragment>
                          );
                        case 44:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title={"Referral amount"}
                                width="min-w-[7.5rem]"
                                arrow={false}
                                // postKey="exchange_id"
                              />
                            </React.Fragment>
                          );
                        case 45:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title={"Referral status"}
                                width="min-w-[7.5rem]"
                                arrow={false}
                                // postKey="exchange_id"
                              />
                            </React.Fragment>
                          );
                        case 46:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title={"Net revenue"}
                                width="min-w-[7.5rem]"
                                arrow={false}
                              />
                            </React.Fragment>
                          );
                      }
                    })}

                    <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem] max-md:static">
                      <div
                        className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden"
                        id="left-shad-list"
                      >
                        <div className="arrow_buttons flex rounded ">
                          <button
                            className="group rounded-l  transition px-[.5625rem] py-2"
                            type="button"
                            onClick={(e: any) => scroll(-120)}
                            id="leftArrow"
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={angleLeftSVG}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                          <button
                            className="group rounded-r  transition px-[.5625rem] py-2"
                            type="button"
                            onClick={(e: any) => scroll(120)}
                            id="rightArrow"
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={angleRightSVG}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                        </div>
                      </div>
                    </th>
                  </tr>
                )}
                itemContent={(sIndex: any, list: any) => {
                  const matchedView =
                    showOrder?.show &&
                    showOrder?.data &&
                    showOrder?.data?.order_id === list.order_id;
                  return (
                    <React.Fragment key={sIndex}>
                      {getColumnItems().map(
                        (columnItem: any, index: number) => {
                          switch (columnItem.id) {
                            case 0:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.team_name}
                                    classname="block"
                                    padding="pl-5"
                                    width="w-[5.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 1:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.user_name}
                                    classname="block w-[7.375rem]"
                                    // padding="pl-5"
                                    width="w-[5.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 2:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.marketplace}
                                    classname="block w-[12.0625rem]"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 3:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.order_id}
                                    classname="block"
                                    width="w-[6.75rem]  max-w-[7.5rem]"
                                  />
                                </React.Fragment>
                              );
                            case 4:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={moment(list?.order_date_time).format(
                                      "DD/MM/YYYY"
                                    )}
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 5:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${
                                      SYMBOLS?.[list?.ticket_currency]
                                    }${decimalNumber(list?.transaction_value)}`}
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 6:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${
                                      SYMBOLS?.[
                                        list?.marketplace_ticket_currency
                                      ]
                                        ? SYMBOLS?.[
                                            list?.marketplace_ticket_currency
                                          ]
                                        : `${list?.marketplace_ticket_currency} `
                                    }${decimalNumber(
                                      list?.marketplace_selling_price
                                    )}`}
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 7:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${
                                      SYMBOLS?.[list?.settlement_currency]
                                    }${decimalNumber(list?.settlement_value)}`}
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 8:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${
                                      SYMBOLS?.[list?.settlement_currency]
                                    }${decimalNumber(list?.tixstock_revenue)}`}
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 9:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.event_description}
                                    width="w-[13.625rem]"
                                    noCapitalize={"yes"}
                                  />
                                </React.Fragment>
                              );
                            case 40:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${
                                      SYMBOLS?.[list?.settlement_currency]
                                    }${decimalNumber(list?.fine_amount)}`}
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 10:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.venue}
                                    width="w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 11:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={moment(list?.event_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 12:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.seating_location}
                                    width="w-[9.875rem]"
                                  />{" "}
                                </React.Fragment>
                              );
                            case 13:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={
                                      list?.ticket_quantity < 10
                                        ? list?.ticket_quantity
                                            .toString()
                                            .padStart(2, 0)
                                        : list?.ticket_quantity
                                    }
                                    width="w-[2.6875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 14:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={ticketTypeRenameForSalesReport(
                                      list?.ticket_type,
                                      list?.sub_ticket_type
                                    )}
                                    noCapitalize={"yes"}
                                    width="w-[6.75rem]"
                                    classname="block w-[7.375rem]"
                                  />
                                </React.Fragment>
                              );
                            case 15:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.order_status}
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 16:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={
                                      list?.internal_order_status
                                        ? list?.internal_order_status
                                        : "-"
                                    }
                                    width="w-[8.125rem]"
                                  />
                                </React.Fragment>
                              );
                            case 17:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={
                                      list?.paid === "false" ? "Unpaid" : "Paid"
                                    }
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 18:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.external_order_id}
                                    noCapitalize="yes"
                                    classname="block w-[15.8125rem]"
                                  />
                                </React.Fragment>
                              );

                            case 43:
                              return (
                                <DataCell
                                  key={index}
                                  value={`${
                                    SYMBOLS?.[list?.settlement_currency] ?? ""
                                  }${decimalNumber(list?.buyer_fees)}`}
                                  noCapitalize="yes"
                                />
                              );
                            case 28:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={
                                      list?.marketplace_status
                                        ? "Invoiced"
                                        : "Not invoiced"
                                    }
                                  />
                                </React.Fragment>
                              );
                            case 44:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={decimalNumber(
                                      list?.referral_payout ?? ""
                                    )}
                                  />
                                </React.Fragment>
                              );
                            case 45:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.referral_payment_status}
                                  />
                                </React.Fragment>
                              );
                            case 46:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${getCurrencySymbol(
                                      list?.settlement_currency ?? ""
                                    )}${decimalNumber(
                                      list?.net_revenue ?? ""
                                    )}`}
                                  />
                                </React.Fragment>
                              );
                          }
                        }
                      )}

                      <td className="group sticky right-0 ml-auto p-0 icon_td  border-b">
                        <React.Fragment key={sIndex}>
                          <div
                            className={`${
                              matchedView ? "bg-indigo-500 bg-opacity-[7%]" : ""
                            } `}
                          >
                            <div
                              className="flex left-shad relative w-full h-full border-l"
                              id="left-shad-list"
                            >
                              {list?.is_legacy_order === "true" && (
                                <button
                                  type="button"
                                  className="absolute top-1/2 -translate-y-1/2 right-full text-white text-xxs bg-violet-500 px-[.4375rem] py-1 leading-3 rounded-l whitespace-nowrap cursor-default"
                                >
                                  Legacy order
                                </button>
                              )}

                              <button
                                className={`group flex items-center justify-center p-1 md:p-1 w-full 
                              `}
                                type="button"
                                id={`record-view-tooltip${sIndex}`}
                                onClick={() => {
                                  handleOrderView(list, sIndex);
                                }}
                              >
                                <Tooltip
                                  anchorId={`record-view-tooltip${sIndex}`}
                                  content={`${matchedView ? "Close" : "View"}`}
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 mt-2"
                                />
                                <IKTSVG
                                  className={`flex items-center justify-center transition group-hover:fill-indigo-500 ${
                                    matchedView
                                      ? "fill-indigo-500"
                                      : "fill-violet-500"
                                  }`}
                                  path={eyeSVG}
                                  svgClassName="w-4 h-[.6875rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      </td>
                      {/* </tr> */}
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </div>
          {/* ORDER VIEW PAGE */}
          {showOrder?.show && (
            <OrderDetail
              show={showOrder}
              handleShow={(data: any) => setShowOrder(data)}
              isGodAdmin={true}
              setShowOrder={setShowOrder}
              reportsOptions={filterOptions}
              layoutContextOrder={layout.orderList}
              setLayoutContextOrder={layout.setOrderList}
              lockButtonShow={false}
            />
          )}
          <div
            id="custom-loader"
            className={`accordion bg-white font-medium pt-2.5 border-t `}
          >
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            {/* <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTableOrders;
