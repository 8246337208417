import { getAuthDataFromStorage } from "./AssetHelpers";

export const PAYOUT_TAB = "Payouts";

const defaultTicketType: any = {
  id: "eticket",
  name: "E-Ticket",
  subtypes: [],
};
const defaultSplitType = {
  id: "ANY",
  name: "No Preferences",
};

const performerFilters = {
  timeframe: "",
  start_date: {},
};
const initialKeyboardField = {
  id: "",
  name: "",
  date: "",
  time: "",
  address: "",
  hideError: false,
  eventRecords: [
    {
      errorInAnyFields: false,
      marketplaces: [],
      ticketType: defaultTicketType,
      sub_ticket_type: {},
      selected: false,
      newRow: true,
      splitType: defaultSplitType,
      MULTIPLES: "",
      maxDisplayQuantity: "",
      category: {},
      section: {},
      row: "",
      firstRow: "",
      firstSeat: "",
      faceValue: {},
      proceedValue: {},
      ppe: false,
      ticketsInHands: false,
      dateToShip: null,
      benefits: [],
      restrictions: [],
      deliveryType: [],
      quantity: "",
      visibility: 0,
      oldVisibility: 0,
    },
  ],
};

// ADD INVENTORY
const TIMEFRAMES = [
  {
    id: 30,
    name: "Next 30 days",
  },
  {
    id: 60,
    name: "Next 60 days",
  },
  {
    id: 90,
    name: "Next 90 days",
  },
];

//AWS
// const REACT_APP_AWS_S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
// const REACT_APP_AWS_REGION = process.env.REACT_APP_AWS_REGION;
// const REACT_APP_AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
// const REACT_APP_AWS_SECRET_ACCESS_KEY =
//   process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const REACT_APP_AWS_TICKET_FOLDER_NAME =
  process.env.REACT_APP_AWS_TICKET_FOLDER_NAME;

// AWS CONFIG OBJECT
const S3_CONFIG: any = {
  // bucketName: REACT_APP_AWS_S3_BUCKET,
  // region: REACT_APP_AWS_REGION,
  // accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  // secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
  dirName: REACT_APP_AWS_TICKET_FOLDER_NAME,
};

// REACT USE QUERY
const QUERIES = {
  INVENTORY_SEARCH: "inventory-search",
  MY_REFERRAL_SETTINGS_COUNT: "my-referral-settings-count",
  SUBSCRIBE_REFERRAL_COUNTS: "subscribe-referral-counts",
  MY_REFERRAL_SETTINGS_LISTINGS: "my-referral-settings-listings",
  MY_REFERRAL_SETTINGS_OPTIONS: "my-referral-settings-options",
  SUBSCRIBE_REFERRAL_OPTIONS: "subscribe-referral-options",
  SUBSCRIBE_REFERRAL: "subscribe-referral",
  SUBSCRIBE_REFERRAL_DETAILS: "subscribe-referral-details",
  INVENTORY_EVENT_LIST: "inventory-event-list",
  INVENTORY_EVENT_COUNT: "inventory-event-count",
  ALGOLIA: "algolia-events",
  SALES_LISTING: "sales-listing",
  SALES_OPTIONS: "sales-options",
  SALES_OUT_TO_OFFER_DETAILS: "out-to-offer",
  REPORTS_LISTING: "reports-listing",
  REPORTS_OPTIONS: "reports-options",
  NOTIFICATIONS_LISTING: "notifications-listing",
  NOTIFICATIONS_OPTIONS: "notifications-options",
  ACTIVITIES_OPTIONS: "activities-options",
  SETTINGS_OVERVIEW: "settings-overview",
  ACCOUNTINFO: "ACCOUNTINFO",
  ADDRESSBOOK: "ADDRESSBOOK",
  SETTINGS_TEAMMEMBERS: "SETTINGS_TEAMMEMBERS",
  SETTINGS_PACKAGES: "SETTINGS_PACKAGES",
  SETTINGS_CHANNELS: "SETTINGS_CHANNELS",
  SETTINGS_GET_CARDS: "SETTINGS_GET_CARDS",
  TX_TRADE_GET_CARDS: "TX_TRADE_GET_CARDS",
  SIGN_UP: "sign-up",
  STRIPE_GATEWAY: "stripe-gateway",
  USER_OPTIONS: "user-options",
  REMOVE_USER_OPTIONS: "remove-user-options",
  ACCESS: "access",
  FULFILLED_LISTING: "fulfilled-listing",
  ACTIVITY_LISTING: "activity-listing",
  PRICE_LISTING: "price-listing",
  NEWS_LISTING: "news-listing",
  USER_DETAIL_INFO: "user-detail-info",
  LOGIN_INFO: "login-info",
  TX_PAY_LISTING: "tx-pay-listing",
  SIGN_UP_EMAIL: "signup-email",
  GET_CATEGORY_LIST: "category-list",
  TOP_SELLING_EVENTS: "top-selling-events",
  API_CONNECTOR_CLIENTS: "api-connector-clients",
  API_CONNECTOR_TRIGGERS: "api-connector-triggers",
  API_CONNECTOR_EVENTS: "api-connector-triggers",
  API_CONNECTOR_LISTING: "api-connector-listing",
  API_CONNECTOR_ORDERS: "api-connector-orders",
  API_CONNECTOR_CLIENTS_OPTIONS: "api-connector-clients-options",

  // ORDER REPLACEMENT
  SUGGEST_REPLACEMENT_LISTING: "suggest-replacement-listing",
  REPLACEMENT_LIST: "replacement-list",
  REPLACEMENT_LIST_TEAM: "replacement-list-team",
  GET_CATEGORY_LISTING: "category-list",
  ORDER_REPLACEMENT_TEAM_LISTING_PRICE:
    "get-order-replacemen-tteam-listing-price",
  ORDER_ON_OFFER: "order-on-offer",

  // ALGOLIA
  ALGOLIA_REPORT: "algolia-report",
  ALGOLIA_SALES: "algolia-sales",
  ALGOLIA_PERFORMERS: "algolia-performers",
  ALGOLIA_INVENTORY: "algolia-inventory",
  ALGOLIA_TxTRADE: "algolia-tx-trade",
  ALGOLIA_TxTRADE_VENUE: "algolia-tx-trade-venue",
  ALGOLIA_TxTRADE_CITY: "algolia-tx-trade-city",
  ALGOLIA_TxPAY: "algolia-tx-pay",
  ALGOLIA_TxACCOUNT: "algolia-tx-account",

  // TX TRADE
  GET_TICKETS: "get-tickets",
  TRACKED_LISTINGS: "tracked-listing",
  TRACKED_LISTINGS_BUY: "tracked-listing-buy",
  PURCHASE_LISTINGS_BUY: "purchase-listing-buy",
  GET_FAVOURITE: "get-favourite",
  DASHBOARD_PURCHASE: "dashboard-purchase",
  DASHBOARD_HOT_EVENTS: "dashboard-hot-events",
  DASHBOARD_LAST_MINUTE: "dashboard-last-minute",
  DASHBOARD_CATEGORY_OPTION: "dashboard-category-option",
  DASHBOARD_RECENT_EVENTS: "dashboard-recent-events",
  DASHBOARD_OFFERED_ORDERS: "dashboard-offered-orders",
  DASHBOARD_OFFERED_ORDERS_DETAILS: "dashboard-offered-orders-detail",
  DASHBOARD_ONSALES: "dashboard-onsales",

  // TX ACCOUNT
  TX_ACCOUNT_INVOICE_LISTINGS: "tx-account-invoice-listings",
  TX_ACCOUNT_PAYOUTS_LISTINGS: "tx-account-payout-listings",
  TX_ACCOUNT_SELLER_WALLET_LISTIGNS: "tx-account-seller-wallet-listings",
  TX_ACCOUNT_BANK_ACCOUNT_LISTIGNS: "tx-account-bank-account-listings",
  TX_ACCOUNT_WITHDRAW_REQUEST_LISTIGNS: "tx-account-withdraw_request-listings",
  TX_ACCOUNT_INVOICE_VIEW: "tx-account-invoice-view",

  // STORE FIELD ARRAY
  STORE_FIELD: "store-field",

  // PERSONAL DETAILS
  PERSONAL_DETAILS_OPTIONS: "personal-details-options",
  PERSONAL_DETAILS_LISTING: "personal-details-listings",

  // INSTRUCTION LETTER
  INSTRUCTION_LETTER_OPTIONS: "instruction-letter-options",
  INSTRUCTION_LETTER_LISTING: "instruction-letter-listings",

  // API CONNECTOR
  GET_VARIABLES: "get-variables",
  GET_VARIABLES_DATA: "get-variables-data",
  INVENTORY_LOG_LISTING: "INVENTORY_LOG_LISTING",

  // SELLER LEVEL

  SELLER_LEVEL_LIST: "seller-level-lists",
  SELLER_LEVEL_PERSONAL_DETAILS_OPTIONS: "seller-level-personal-options",
  SELLER_LEVEL_OPTION: "seller-level-options",
  LEVEL_LIST: "level-list",
  SELLER_LEVEL_MOVEMENT_LIST: "seller-level-movement-list",

  // CUSTOMIZE
  CUSTOMIZE_ALGOLIA: "customize-algolia",
  CONTENT_LIST: "content-list",
  HOME_DESIGN_LIST: "home_design_list",

  PERSONAL_TEAMMEMBERS: "PERSONAL_TEAMMEMBERS",
  USER_CONTECTED_MARKETPLACE: "USER_CONTECTED_MARKETPLACE",

  // VENUE DETAILS VARIATION
  VENUE_DETAILS_VARIATIONS: "venue-details-variations",
  VARIATION_DETAILS: "variation_details",

  VENUS_FETCH: "venus-fetch",
};

// LOGIN
const REACT_APP_URL = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "";

//ALGOLIA
const REACT_APP_ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY
  ? process.env.REACT_APP_ALGOLIA_API_KEY
  : "535a3cb3dd4d18a4ddde5ebbd2134dc4";
const REACT_APP_ALGOLIA_APPLICATION_ID = process.env
  .REACT_APP_ALGOLIA_APPLICATION_ID
  ? process.env.REACT_APP_ALGOLIA_APPLICATION_ID
  : "BJBT7AKWD8";

const PUBLISH_STATUS = [
  {
    id: "yes",
    // name: "Active",
    name: "Published",
  },
  {
    id: "no",
    // name: "Inactive",
    name: "Unpublished",
  },
  {
    id: "hold",
    name: "Hold",
  },
  {
    id: "sold",
    name: "Sold",
  },
  {
    id: "errors",
    name: "Errors",
  },
];

// FILTER ITEMS
const REPORT_FILTER_ITEMS = [
  {
    id: 1,
    name: "Venue",
    checked: true,
    searched: true,
  },
  {
    id: 2,
    name: "Marketplaces",
    checked: true,
    searched: true,
  },
  {
    id: 3,
    name: "Transaction date",
    checked: true,
    searched: true,
  },
  {
    id: 4,
    name: "Event date",
    checked: true,
    searched: true,
  },
  {
    id: 5,
    name: "All teams",
    checked: true,
    searched: true,
  },
  {
    id: 6,
    name: "Team members",
    checked: true,
    searched: true,
  },
  {
    id: 7,
    name: "Order status",
    checked: true,
    searched: true,
  },

  {
    id: 8,
    name: "Payment status",
    checked: true,
    searched: true,
  },
  {
    id: 9,
    name: "Internal order status",
    checked: true,
    searched: true,
  },
  {
    id: 10,
    name: "Include webstores",
    checked: true,
    searched: true,
  },
  {
    id: 11,
    name: "Category",
    checked: true,
    searched: true,
  },
  {
    id: 12,
    name: "Performer",
    checked: true,
    searched: true,
  },
  {
    id: 13,
    name: "Buyer",
    checked: true,
    searched: true,
  },
];

const SALES_FILTER_ITEMS = [
  {
    id: 1,
    name: "All teams",
    checked: true,
    searched: true,
  },
  {
    id: 2,
    name: "Team member",
    checked: true,
    searched: true,
  },
  {
    id: 3,
    name: "Ticket type",
    checked: true,
    searched: true,
  },
  {
    id: 4,
    name: "Tickets in hand",
    checked: true,
    searched: true,
  },
  {
    id: 5,
    name: "Order date",
    checked: true,
    searched: true,
  },
  {
    id: 6,
    name: "Deliver by date",
    checked: true,
    searched: true,
  },

  {
    id: 7,
    name: "Event date",
    checked: true,
    searched: true,
  },

  {
    id: 8,
    name: "Days to event",
    checked: true,
    searched: true,
  },
  // {
  //   id: 6,
  //   name: "Order status",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 9,
    name: "Marketplaces",
    checked: true,
    searched: true,
  },
  {
    id: 10,
    name: "Internal order status",
    checked: true,
    searched: true,
  },
  {
    id: 11,
    name: "Include webstores",
    checked: true,
    searched: true,
  },
  {
    id: 12,
    name: "Category",
    checked: true,
    searched: true,
  },
  {
    id: 13,
    name: "Performer",
    checked: true,
    searched: true,
  },
  {
    id: 14,
    name: "Buyer",
    checked: true,
    searched: true,
  },
];

const SALES_COLUMN_ITEMS = [
  {
    id: 0,
    name: "Team name",
    checked: true,
    searched: true,
  },
  {
    id: 1,
    name: "Team member",
    checked: true,
    searched: true,
  },
  {
    id: 2,
    name: "Order ID",
    checked: true,
    searched: true,
  },
  {
    id: 3,
    name: "Order date",
    checked: true,
    searched: true,
  },
  {
    id: 4,
    // name: "Order value",
    name: "Listing value",
    checked: true,
    searched: true,
  },
  {
    id: 7,
    name: "Event",
    checked: true,
    searched: true,
  },
  {
    id: 8,
    name: "Venue",
    checked: true,
    searched: true,
  },
  // {
  //   id: 6,
  //   name: "Marketplace",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 9,
    name: "Event date",
    checked: true,
    searched: true,
  },

  {
    id: 10,
    name: "Ticket details",
    checked: true,
    searched: true,
  },
  {
    id: 11,
    name: "Ticket quantity",
    checked: true,
    searched: true,
  },
  {
    id: 12,
    name: "Ticket type",
    checked: true,
    searched: true,
  },
  {
    id: 13,
    name: "Order status",
    checked: true,
    searched: true,
  },
  {
    id: 14,
    name: "Internal order status",
    checked: true,
    searched: true,
  },
  {
    id: 15,
    name: "Days to event",
    checked: true,
    searched: true,
  },
  {
    id: 16,
    name: "Marketplace",
    checked: true,
    searched: true,
  },
  {
    id: 17,
    name: "Marketplace ID",
    checked: true,
    searched: true,
  },
  {
    id: 18,
    name: "Buyer",
    checked: true,
    searched: true,
  },
  {
    id: 19,
    name: "Referral amount",
    checked: true,
    searched: true,
  },
];

const REPORT_COLUMN_ITEMS = [
  {
    id: 0,
    name: "Team name",
    checked: true,
    searched: true,
  },
  {
    id: 1,
    name: "Team member",
    checked: true,
    searched: true,
  },
  {
    id: 2,
    name: "Order ID",
    checked: true,
    searched: true,
  },
  {
    id: 3,
    name: "Order date",
    checked: true,
    searched: true,
  },
  {
    id: 4,
    // name: "Order value",
    name: "Listing value",
    checked: true,
    searched: true,
  },

  {
    id: 5,
    name: "Marketplace value",
    checked: true,
    searched: true,
  },
  {
    id: 6,
    name: "Settlement/net payout value",
    checked: true,
    searched: true,
  },
  {
    id: 7,
    name: "Tixstock revenue",
    checked: true,
    searched: true,
  },
  {
    id: 8,
    name: "Event",
    checked: true,
    searched: true,
  },
  {
    id: 9,
    name: "Venue",
    checked: true,
    searched: true,
  },
  {
    id: 10,
    name: "Event date",
    checked: true,
    searched: true,
  },
  {
    id: 11,
    name: "Ticket details",
    checked: true,
    searched: true,
  },
  {
    id: 12,
    name: "Qty",
    checked: true,
    searched: true,
  },
  {
    id: 13,
    name: "Ticket type",
    checked: true,
    searched: true,
  },
  {
    id: 14,
    name: "Order status",
    checked: true,
    searched: true,
  },

  {
    id: 15,
    name: "Internal order status",
    checked: true,
    searched: true,
  },

  {
    id: 16,
    name: "Payment status",
    checked: true,
    searched: true,
  },
  {
    id: 17,
    name: "Marketplace",
    checked: true,
    searched: true,
  },
  {
    id: 18,
    name: "Marketplace ID",
    checked: true,
    searched: true,
  },
  {
    id: 19,
    name: "Buyer fees",
    checked: true,
    searched: true,
  },
  {
    id: 20,
    name: "Buyer",
    checked: true,
    searched: true,
  },
];

const INVENTRY_COLUMN_ITEMS: any = [
  {
    id: 0,
    name: "Listing ID",
    checked: true,
    searched: true,
    checkBox: true,
  },
  {
    id: 1,
    name: "Team name",
    checked: true,
    searched: true,
    checkBox: true,
  },
  {
    id: 2,
    name: "Team member",
    checked: true,
    searched: true,
    checkBox: true,
  },

  {
    id: 3,
    name: "Ticket type",
    checked: true,
    searched: true,
    checkBox: false,
  },
  // {
  //   id: 4,
  //   name: "Sub ticket type",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 5,
    name: "Quantity",
    checked: true,
    searched: true,
    checkBox: false,
  },
  {
    id: 6,
    name: "Split type",
    checked: true,
    searched: true,
    checkBox: true,
  },
  {
    id: 7,
    name: "Sell in multiple",
    checked: true,
    searched: true,
    checkBox: true,
  },
  {
    id: 8,
    name: "Max display",
    checked: true,
    searched: true,
    checkBox: true,
  },

  {
    id: 9,
    name: "Category",
    checked: true,
    searched: true,
    checkBox: false,
  },
  {
    id: 10,
    name: "Section",
    checked: true,
    searched: true,
    checkBox: false,
  },
  {
    id: 11,
    name: "Row",
    checked: true,
    searched: true,
    checkBox: true,
  },
  {
    id: 12,
    name: "First seat",
    checked: true,
    searched: true,
    checkBox: true,
  },
  {
    id: 13,
    name: "Face value",
    checked: true,
    searched: true,
    checkBox: false,
  },
  {
    id: 14,
    name: "Proceed price",
    checked: true,
    searched: true,
    toggle: false,
    checkBox: false,
  },
  {
    id: 15,
    name: "Benefits",
    checked: true,
    searched: true,
    checkBox: true,
  },
  {
    id: 16,
    name: "Restrictions",
    checked: true,
    searched: true,
    checkBox: true,
  },
  // {
  //   id: 13,
  //   name: "Face value",
  //   checked: true,
  //   searched: true,
  // },
  // {
  //   id: 14,
  //   name: "Proceed price",
  //   checked: true,
  //   searched: true,
  // },
  // {
  //   id: 17,
  //   name: "In hand",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 18,
    name: "Date of ship",
    checked: true,
    searched: true,
    checkBox: true,
  },
  // {
  //   id: 17,
  //   name: "Benefits",
  //   checked: true,
  //   searched: true,
  // },
  // {
  //   id: 18,
  //   name: "Restrictions",
  //   checked: true,
  //   searched: true,
  // },
];

const ADD_INVENTRY_COLUMN_ITEMS: any = [
  {
    id: 0,
    name: "Proceed price",
    checked: true,
    searched: true,
    toggle: false,
    checkBox: false,
  },
];

const INVENTORY_FILTER_ITEMS: any = [
  {
    id: 1,
    name: "All teams",
    checked: true,
    searched: true,
  },

  {
    id: 2,
    name: "Team members",
    checked: true,
    searched: true,
  },
  {
    id: 3,
    name: "Event date",
    checked: true,
    searched: true,
  },

  {
    id: 5,
    // name: "Publish Status",
    name: "Ticket type",
    checked: true,
    searched: true,
  },
  // {
  //   id: 6,
  //   // name: "Publish Status",
  //   name: "Tickets in hand",
  //   checked: true,
  //   searched: true,
  // },

  // {
  //   id: 7,
  //   // name: "Publish Status",
  //   name: "Tickets uploaded",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 6,
    // name: "Publish Status",
    name: "Ticket status",
    checked: true,
    searched: true,
  },
  {
    id: 7,
    // name: "Publish Status",
    name: "Listing status",
    checked: true,
    searched: true,
  },
  {
    id: 8,
    // name: "Publish Status",
    name: "Listing quality",
    checked: true,
    searched: true,
  },
  {
    id: 9,
    // name: "Publish Status",
    name: "Category",
    checked: true,
    searched: true,
  },
  {
    id: 10,
    name: "Hospitality tickets",
    checked: true,
    searched: true,
  },
  {
    id: 11,
    name: "Re-created listings",
    checked: true,
    searched: true,
  },
];

const INVENTORY_BULK_ITEMS: any = [
  {
    id: 1,
    name: "Publish",
    searched: true,
  },
  {
    id: 2,
    name: "Unpublish",
    searched: true,
  },
  //   {
  //     id: 3,
  //     name: "Adjust proceed price",
  //     searched: true,
  //   },
  {
    id: 4,
    name: "Delete listing",
    searched: true,
  },
  {
    id: 5,
    name: "Update pricing",
    searched: true,
  },
  {
    id: 6,
    name: "Transfer ownership",
    searched: true,
  },
];

const NOTIFICATION_FILTER_ITEMS = [
  {
    id: 0,
    name: "All teams",
    checked: true,
    searched: true,
    tab: ["Notification", "Activities"],
  },
  {
    id: 1,
    name: "Team members",
    checked: true,
    searched: true,
    tab: ["Notification", "Activities"],
  },
  {
    id: 2,
    name: "Date range",
    checked: true,
    searched: true,
    tab: ["Notification", "Activities"],
  },
  {
    id: 3,
    name: "Activity type",
    checked: true,
    searched: true,
    tab: ["Activities"],
  },
];

const ADD_INVENTORY_KEYBOARD_ITEMS = [
  {
    id: 2,
    name: "Ticket type",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  // {
  //   id: 3,
  //   name: 'Sub Ticket type',
  //   checked: true,
  //   searched: true,
  //   isDraggable: false
  // },
  // {
  //   id: 4,
  //   name: 'Delivery type',
  //   checked: true,
  //   searched: true,
  //   isDraggable: true
  // },
  {
    id: 5,
    name: "Quantity",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 6,
    name: "Split type",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 7,
    name: "Max display quantity",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 8,
    name: "Category",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 9,
    name: "Section",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 10,
    name: "Row",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 11,
    name: "First seat",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 12,
    name: "Face value",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 13,
    name: "Proceed price",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 14,
    name: "Price per partner",
    checked: true,
    searched: true,
    isDraggable: true,
  },

  // {
  //   id: 12,
  //   name: "Face value",
  //   checked: true,
  //   searched: true,
  //   isDraggable: true,
  // },
  // {
  //   id: 13,
  //   name: "Proceed price",
  //   checked: true,
  //   searched: true,
  //   isDraggable: true,
  // },

  {
    id: 15,
    name: "Benefits",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 16,
    name: "Restriction",
    checked: true,
    searched: true,
    isDraggable: true,
  },

  // {
  //   id: 12,
  //   name: "Face value",
  //   checked: true,
  //   searched: true,
  //   isDraggable: true,
  // },
  // {
  //   id: 13,
  //   name: "Proceed price",
  //   checked: true,
  //   searched: true,
  //   isDraggable: true,
  // },
  // {
  //   id: 12,
  //   name: 'PPE',
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 17,
    name: "Date to ship",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  {
    id: 18,
    name: "Tickets in hand",
    checked: true,
    searched: true,
    isDraggable: true,
  },

  {
    id: 19,
    name: "Upload tickets",
    checked: true,
    searched: true,
    isDraggable: true,
  },
  // {
  //   id: 16,
  //   name: "Benefits",
  //   checked: true,
  //   searched: true,
  //   isDraggable: true,
  // },
  // {
  //   id: 17,
  //   name: "Restriction",
  //   checked: true,
  //   searched: true,
  //   isDraggable: true,
  // },
];

const TX_PAY_FILTER_ITEMS = [
  {
    id: 1,
    name: "Transaction date",
    checked: true,
    searched: true,
  },
  {
    id: 2,
    name: "Transaction type",
    checked: true,
    searched: true,
  },
  {
    id: 3,
    name: "All teams",
    checked: true,
    searched: true,
  },
];

const API_CONNECTOR_CLIENT_FILTERS = [
  {
    id: 1,
    name: "Status",
    checked: true,
    searched: true,
  },
];

// CURRENCY
const SYMBOLS: any = {
  GBP: "£",
  EUR: "€",
  USD: "$",
  PER: "%",
};

const TX_ACCOUNT_FILTER = [
  {
    id: 0,
    name: "Seller level",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 1,
    name: "Teams",
    checked: true,
    searched: true,
    tab: [
      "Orders",
      PAYOUT_TAB,
      "Seller wallets",
      "Bank accounts",
      "Withdraw requests",
    ],
  },
  {
    id: 2,
    name: "Marketplaces",
    checked: true,
    searched: true,
    tab: ["Orders", "Invoices"],
  },
  {
    id: 3,
    name: "Transaction date",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 4,
    name: "Event date",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 5,
    name: "Order status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },

  {
    id: 6,
    name: "Internal order status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 7,
    name: "Seller payment status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },

  // {
  //   id: 8,
  //   name: "Include webstores",
  //   checked: true,
  //   searched: true,
  //   tab: ["Orders"],
  // },

  {
    id: 9,
    name: "Created date",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 10,
    name: "Payment date",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 11,
    name: "Invoice status",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 12,
    name: "Payout date",
    checked: true,
    searched: true,
    tab: [PAYOUT_TAB],
  },
  {
    id: 13,
    name: "Marketplace invoice status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 14,
    name: "Wallet status",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 15,
    name: "Withdraw request status",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 16,
    name: "Requested date",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 17,
    name: "Marketplace currency",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 18,
    name: "Order Ids",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 19,
    name: "Change requested",
    checked: true,
    searched: true,
    tab: ["Withdraw requests", "Bank accounts"],
  },
  {
    id: 20,
    name: "Withdrawal bank account configured",
    checked: true,
    searched: true,
    tab: ["Bank accounts"],
  },
];

const TX_ACCOUNT_COLUMN = [
  {
    id: 0,
    name: "Team name",
    checked: true,
    searched: true,
    tab: ["Orders", "Withdraw requests", "Bank accounts"],
  },
  {
    id: 1,
    name: "Team member",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 2,
    name: "Marketplace",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 3,
    name: "Order ID",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 4,
    name: "Order date",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 5,
    name: "Proceed price",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 6,
    name: "Marketplace value",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 7,
    name: "Seller payout",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 8,
    name: "Tixstock revenue",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 40,
    name: "Penalty amount",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 9,
    name: "Event",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 10,
    name: "Venue",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 11,
    name: "Event date",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 12,
    name: "Ticket details",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 13,
    name: "Qty",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 14,
    name: "Ticket type",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 15,
    name: "Order status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 16,
    name: "Internal order status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 17,
    name: "Payment status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 18,
    name: "Marketplace ID",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 19,
    name: "Marketplace ",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 20,
    name: "Created Date",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 21,
    name: "Invoice number",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 22,
    name: "Invoice value",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 23,
    name: "Invoice status",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 24,
    name: "Payment date",
    checked: true,
    searched: true,
    tab: ["Invoices"],
  },
  {
    id: 25,
    name: "Team name",
    checked: true,
    searched: true,
    tab: [PAYOUT_TAB],
  },
  {
    id: 42,
    name: "Payout reference",
    checked: true,
    searched: true,
    tab: [PAYOUT_TAB],
  },
  {
    id: 26,
    name: "Payout date",
    checked: true,
    searched: true,
    tab: [PAYOUT_TAB],
  },
  // {
  //   id: 27,
  //   name: "Statement number",
  //   checked: true,
  //   searched: true,
  //   tab: [PAYOUT_TAB],
  // },
  {
    id: 27,
    name: "Payout value",
    checked: true,
    searched: true,
    tab: [PAYOUT_TAB],
  },
  {
    id: 28,
    name: "Marketplace invoice status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 29,
    name: "Team member",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 30,
    name: "TX Pay ID",
    checked: true,
    searched: true,
    tab: ["Seller wallets", "Bank accounts", "Withdraw requests"],
  },
  {
    id: 31,
    name: "IBAN",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 32,
    name: "MC Account ID",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 33,
    name: "Balance",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 34,
    name: "Wallet status",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 35,
    name: "Amount",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 48,
    name: "Balance",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 36,
    name: "Requested date",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 37,
    name: "Status",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 38,
    name: "Withdraw bank name",
    checked: true,
    searched: true,
    tab: ["Bank accounts"],
  },
  {
    id: 39,
    name: "Funding bank name",
    checked: true,
    searched: true,
    tab: ["Bank accounts"],
  },
  {
    id: 41,
    name: "Change requested",
    checked: true,
    searched: true,
    tab: ["Withdraw requests", "Bank accounts"],
  },
  {
    id: 43,
    name: "Buyer fees",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 44,
    name: "Referral amount",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 45,
    name: "Referral status",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
  {
    id: 46,
    name: "Net revenue",
    checked: true,
    searched: true,
    tab: ["Orders"],
  },
];

const WALLET_COLUMNS = [
  {
    id: 0,
    name: "Team name",
    checked: true,
    searched: true,
    tab: ["Withdraw requests", "Bank accounts"],
  },
  {
    id: 29,
    name: "Team member",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 30,
    name: "TX Pay ID",
    checked: true,
    searched: true,
    tab: ["Seller wallets", "Bank accounts", "Withdraw requests"],
  },
  {
    id: 31,
    name: "IBAN",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 32,
    name: "MC Account ID",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 33,
    name: "Balance",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 34,
    name: "Wallet status",
    checked: true,
    searched: true,
    tab: ["Seller wallets"],
  },
  {
    id: 35,
    name: "Amount",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 40,
    name: "Balance",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 36,
    name: "Requested date",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 37,
    name: "Status",
    checked: true,
    searched: true,
    tab: ["Withdraw requests"],
  },
  {
    id: 38,
    name: "Withdraw bank name",
    checked: true,
    searched: true,
    tab: ["Bank accounts"],
  },
  {
    id: 39,
    name: "Funding bank name",
    checked: true,
    searched: true,
    tab: ["Bank accounts"],
  },
  {
    id: 41,
    name: "Change requested",
    checked: true,
    searched: true,
    tab: ["Withdraw requests", "Bank accounts"],
  },
];

const PEROSNAL_DETAILS_FILTER_ITEMS = [
  {
    id: 0,
    name: "Teams",
    checked: true,
    searched: true,
  },
  // {
  //   id: 1,
  //   name: "Teams name",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 2,
    name: "Team status",
    checked: true,
    searched: true,
  },
  // {
  //   id: 3,
  //   name: "Risk level",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 4,
    name: "Registered date",
    checked: true,
    searched: true,
  },
  {
    id: 5,
    name: "Seller level",
    checked: true,
    searched: true,
  },
  {
    id: 6,
    name: "User type",
    checked: true,
    searched: true,
  },
  {
    id: 7,
    name: "Demo status",
    checked: true,
    searched: true,
  },
];

const REFERRAL_DETAILS_FILTER_ITEMS = [
  {
    id: 0,
    name: "Referrer team name",
    checked: true,
    searched: true,
  },
  {
    id: 1,
    name: "Referred team name",
    checked: true,
    searched: true,
  },
  {
    id: 2,
    name: "Referral status",
    checked: true,
    searched: true,
  },
  {
    id: 3,
    name: "Start date",
    checked: true,
    searched: true,
  },
  {
    id: 4,
    name: "End date",
    checked: true,
    searched: true,
  },
];

const PEROSNAL_DETAILS_COLUMNS_ITEMS = [
  {
    id: 0,
    name: "Team name",
    checked: true,
    searched: true,
  },
  {
    id: 1,
    name: "First name",
    checked: true,
    searched: true,
  },
  {
    id: 2,
    name: "Last name",
    checked: true,
    searched: true,
  },
  // {
  //   id: 3,
  //   name: "Package",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 4,
    name: "Team status",
    checked: true,
    searched: true,
  },
  {
    id: 5,
    name: "Secondary users",
    checked: true,
    searched: true,
  },
  {
    id: 6,
    name: "Registered date",
    checked: true,
    searched: true,
  },
  {
    id: 7,
    name: "Last login",
    checked: true,
    searched: true,
  },
  // {
  //   id: 8,
  //   name: "Risk level",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 9,
    name: "User type",
    checked: true,
    searched: true,
  },
  {
    id: 10,
    name: "Seller level",
    checked: true,
    searched: true,
  },
  {
    id: 11,
    name: "Demo status",
    checked: true,
    searched: true,
  },
];
const REFERRALS_COLUMNS_ITEMS = [
  {
    id: 0,
    name: "Referrer team name",
    checked: true,
    searched: true,
  },
  {
    id: 1,
    name: "Referred team name",
    checked: true,
    searched: true,
  },
  {
    id: 2,
    name: "Start date",
    checked: true,
    searched: true,
  },
  {
    id: 3,
    name: "End date",
    checked: true,
    searched: true,
  },
  // {
  //   id: 4,
  //   name: "Earnings",
  //   checked: true,
  //   searched: true,
  // },
  {
    id: 5,
    name: "Eligible earnings",
    checked: true,
    searched: true,
  },
  {
    id: 6,
    name: "Paid earnings",
    checked: true,
    searched: true,
  },
];

const INITIAL_PERSONAL_DETAILS = {
  teams: null,
  statuses: null,
  risk_levels: null,
  register_date: null,
  seller_levels: null,
  user_type: null,
  query: "",
  page: 1,
  per_page: 20,
  order_by: "",
  sort_order: "asc",
};

const INITIAL_REFERRAL_DETAILS = {
  teams: null,
  referralTeam: null,
  referralStatus: null,
  startDate: null,
  endDate: null,
  query: "",
  page: 1,
  per_page: 20,
  order_by: "",
  sort_order: "asc",
};

// INSTRUCTIONS
const INSTRUCTION_LETTER_FILTER_ITEMS = [
  {
    id: 0,
    name: "Status",
    checked: true,
    searched: true,
  },
];

const INSTRUCTION_LETTER__COLUMNS_ITEMS = [
  {
    id: 0,
    name: "Title",
    checked: true,
    searched: true,
  },
  {
    id: 1,
    name: "Status",
    checked: true,
    searched: true,
  },
];

const INITIAL_INSTRUCTION_DETAILS = {
  title: null,
  content: null,
  status: null,
  query: "",
  page: 1,
  per_page: 20,
  order_by: "",
  sort_order: "asc",
};

// API CONNECTOR
const INITIAL_CONNECTOR_VIEW = {
  url: "https://key-manager.tn-apis.com/oauth2/token",
  httpMethod: { id: "post", name: "POST", className: "text-amber-500" },
  activeTab: "Headers",
  headers: undefined,
  body: "",
  response: {
    data: "",
    message: "",
    error: false,
  },
  authRequired: false,
  name: null,
};

// HTTP METHODS
const HTTP_METHODS = [
  {
    id: "post",
    name: "POST",
    className: "method-post",
  },
  {
    id: "get",
    name: "GET",
    className: "method-get",
  },
  {
    id: "put",
    name: "PUT",
    className: "method-put",
  },
  {
    id: "delete",
    name: "DELETE",
    className: "method-delete",
  },
  {
    id: "patch",
    name: "PATCH",
    className: "method-patch",
  },
  {
    id: "head",
    name: "HEAD",
    className: "method-head",
  },
  {
    id: "options",
    name: "OPTIONS",
    className: "method-options",
  },
];

const INITIAL_HEADERS = { id: "", name: "", value: "" };

// AUTH USER DATA
let getAuthData: any = getAuthDataFromStorage();
// Cookies.get("authData") as string;
const AUTHDATA: any = getAuthData ? JSON.parse(getAuthData) : {};

// PUSHER
const CHANNEL_ON_HOLD_EVENT_ID = "listing-on-hold";
const CHANNEL_RELEASED_EVENT_ID = "listing-released";
const CHANNEL_LISTING_PUBLISHED_MARKETPLACE_EVENT_ID =
  "listing-published-to-market-place";
const CHANNEL_LISTING_REMOVED_MARKETPLACE_EVENT_ID =
  "listing-removed-from-market-place";
const CHANNEL_INVENTORY_LISTING_UPDATE_EVENT_ID = "inventory-listing-update";
const CHANNEL_LISTING_DELETE = "listing-deleted";
const CHANNEL_INVENTORY_LISTING_CREATE_EVENT_ID = "listing-create";
const CHANNEL_NEW_NOTIFICATION_EVENT_ID = "new-notification";
const CHANNEL_DOCUSIGN_COMPLETED = "docusign-completed";

// PHONE NUMBER REGEX CHECK
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// PDF DOCUMENT
const PDFFailedLoad = "Failed to load PDF file.";

// TX TRADE
// INITIAL FILTER STATES
const INITIALSTATE = {
  min_quantity: { id: 6, name: "Any", postData: 1 },
  order_by: "price",
  sort_order: "asc",
  my_listings: "all_listings",
  per_page: 100,
  page: 1,
};

// SALES NEW ORDER INITIAL FILTES
const NEW_ORDER_INITIAL_FILTER_STATE = {
  quantity: { id: "", name: "Any", postData: "" },
  team_id: [],
  ticket_type: [],
  category_id: [],
  ticket_in_hand: false,
  ticket_uploaded: false,
  page: 1,
  per_page: 10,
  order_by: "",
  sort_order: "",
};

const NOTIFICATION_POPUP = {
  title: "A new version of Tixstock is available",
  message:
    " Please log out and log in again to update Tixstock and continue using optimally.",
};

const INVENTORY_VALIDATE_COLUMN = [
  "sell_price",
  "quantity_available",
  "face_value",
  "category",
  "PPP",
];

const MULTI_SELECTION_ALLOWED = [
  "Teams",
  "teams",
  "referralStatus",
  "referredTeamName",
  "referralTeam",
  "team_id",
  "teamMembers",
  "teammembers",
  "publishstatus",
  "activities",
  "order_exchanges",
  "team_member",
  "order_statuses",
  "payment_status",
  "internal_order_statuses",
  "internal_order_status",
  "order_status",
  "category_name",
  "ticket_types",
  "broker_id",
  "invoice_status",
  "marketplace",
  "category_id",
  "ticket_type",
  "statuses",
  "benefits",
  "restrictions",
  "deliveryType",
  "listing_quality",
  "ticket_type_dropdown",
  "ticketstatus",
  "category_ids",
  "risk_levels",
  "performer_ids",
  "buyer_team_id",
  "venue_ids",
];

const MULTI_SELECTION_ALLOWED_TABLE = ["benefits", "restrictions"];

const TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE = {
  draft_invoice_initial_step: false,
  draft_invoice_next_step: false,
  listing_data: undefined,
  generate_draft_invoice_status: true,
  generate_draft_invoice_next_step: false,
  generate_draft_invoice_next_step_disabled: false,
  finish: false,
};

const TX_ACCOUNT_OPTIONS_STATE = {
  options_teams: [],
  options_marketplace: [],
  options_orderstatus: [],
  options_internalorderstatus: [],
  options_paymentstatus: [],
  options_marketplace_paymentstatus: [],
};

const TX_ACCOUNT_ORDER_INITIAL_FILTER = {
  page: 1,
  per_page: 20,
  event_id: [],
  teams: [],
  marketplace: [],
  transcation_date: null,
  event_date: null,
  order_status: [],
  internal_order_status: [],
  payment_status: [],
  store_id: false,
  order_by: "",
  sort_order: "asc",
  bulk_order: false,
  order_ids: [],
};
const TX_ACCOUNT_INVOICE_INITIAL_FILTER = {
  page: 1,
  per_page: 20,
  invoice_number: "",
  invoice_status: [],
  marketplace: [],
  invoice_date: null,
  payment_date: null,
  order_by: "",
  sort_order: "asc",
  query: "",
};

const TX_ACCOUNT_PAYOUT_INTIAL_FILTER = {
  page: 1,
  per_page: 20,
  date_to: null,
  date_from: null,
  teams: [],
  pay_out_date: null,
};

const TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER = {
  page: 1,
  per_page: 20,
  teams: [],
  statuses: null,
  order_by: "",
  sort_order: "",
};
const TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER = {
  page: 1,
  per_page: 20,
  teams: [],
  statuses: [{ id: 1, name: "Active" }],
  requestedDate: null,
  txpay_change_request: false,
  order_by: "",
  sort_order: "",
};
const TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER = {
  page: 1,
  per_page: 20,
  txpay_change_request: false,
  teams: [],
  order_by: "",
  sort_order: "",
  is_withdrawal_acc_configured: null,
};

const PAYOUT_VIEW_INTIALSTATE = {
  view_status: false,
};

const TX_ACCOUNT_INVOICE_VIEW_DATA = {
  data: undefined,
  meta: undefined,
  status: false,
};

const TX_ACCOUNT_ORDER_PAYOUT_SELLER = {
  initial_step: false,
  next_step: false,
  authorize_pay_step: false,
  authorize_pay_next_step: false,
  finish: false,
};

const ORDER_ASSIGN_TO_NEW_LISTING = {
  status: false,
  backToOrderListingStatus: false,
  data: undefined,
  replacementList: undefined,
  reviewReplacement: true,
  confirmedReplacement: false,
};
const ORDER_ASSIGN_TO_NEW_TEAM = {
  status: false,
  data: undefined,
  confirmed: false,
};

const ORDER_ON_OFFER = {
  status: false,
  data: undefined,
  confirmed: false,
};
export const VENUE_DETAILS_INITIAL_FILTER = {
  query: "",
  page: 1,
  per_page: 20,
};

export const VENUE_NEW_VARIATION_STATE = {
  page: 1,
  per_page: 20,
};

const TICKET_TYPE_MAP: any = {
  eticket: "E-ticket",
  eTicket: "E-ticket",
  paper: "Paper",
  Paper: "Paper",
  "mobile-link": "Mobile-Link",
  mobile: "Mobile",
  "season-cards": "Members / Season Card",
  "Members / Season Card": "Members / Season Card",
};

const SALES_FILTERS_ADMIN: any = [
  "All teams",
  "Marketplaces",
  "Internal replacement status",
  "Stores",
  "Internal order status",
  "Include webstores",
  "Performer",
  "Buyer",
];

const REPORT_FILTERS_ADMIN: any = [
  "All teams",
  "Marketplaces",
  "Internal replacement status",
  "Stores",
  "Internal order status",
  "Include webstores",
  "Performer",
  "Buyer",
];

const SALES_COLUMN_ADMIN: any = [
  "Team name",
  "Team member",
  "Marketplace value",
  "Settlement/net payout value",
  "Marketplace",
  "Marketplace ID",
  "Internal replacement status",
  "Internal order status",
  "Buyer",
  "Referral amount",
];

const REPORT_COLUMN_ADMIN: any = [
  "Team name",
  "Team member",
  "Marketplace value",
  "Settlement/net payout value",
  "Tixstock revenue",
  "Marketplace",
  "Marketplace ID",
  "Internal replacement status",
  "Internal order status",
  "Buyer",
];

const CAPITAL_OPTION_NOT_REQUIRED = [
  "ticket_types",
  "statuses",
  "internal_order_statuses",
  "order_status_id",
  "orderStatus",
  "internal_order_status",
];

const CAPITAL_OPTIONS_IN_MULTIDROPDOWN = ["category_ids"];

const deliveryTypeOrders: any = [
  (item: any) => {
    if (typeof item === "object" && item !== null) {
      let compare: any = item?.type;
      switch (compare) {
        case "Local":
          return 1;
        case "National":
          return 2;
        case "International":
          return 3;
        default:
          return 0; // Handle other cases if necessary
      }
    } else {
      switch (item) {
        case "Local":
          return 1;
        case "National":
          return 2;
        case "International":
          return 3;
        default:
          return 0; // Handle other cases if necessary
      }
    }
  },
];
// COUNTRY CODE SYMBOLS
const COUNTRY_CODE_SYMBOLS: any = {
  france: "+33",
  germany: "+49",
  ireland: "+353",
  Italy: "+39",
  Spain: "+34",
  UK: "+44",
  US: "+1",
  Israel: "+972",
  "Hong Kong": "+852",
};

const API_CONNECTOR_ACCEPTED_STATUS_CODE = [200, 201, 202, 203, 204, 205, 206];
//MOBILE
const isMobile = (window as any).innerWidth <= 767;

// TICKET FILES
const MAX_FILES = 500;

export const MULTI_SELECT_INPUT = {
  gridTemplateColumns: "0 minmax(min-content, 1fr)",
};

const pngImagePath = "/media/png/";

export const NO_DEMO_STATUS = "No Demo";

// NAVIGATION URL
type Listing_utils_type = {
  url: string;
  name: string;
};

export const LISTINGS_UTILS: Record<string, Listing_utils_type> = {
  addListings: {
    url: "/add-listings",
    name: "Add Listings",
  },
  myListings: {
    url: "/my-listings",
    name: "My Listings",
  },
  venueDetails: {
    url: "/venue-details-variations",
    name: "Venue Details Variations",
  },
  APIConnector: {
    url: "/API-connector",
    name: "API Connector",
  },
};

const XERO_CONTACT_OPTIONS = [
  {
    name: "General",
    value: "general",
    id: "general",
  },
  {
    name: "Marketplace",
    value: "marketplace",
    id: "marketplace",
  },
];

export const REMOVED_ACTIONS = ["Accept Offer", "Make Offer", "Reject Offer"];

export {
  ADD_INVENTORY_KEYBOARD_ITEMS,
  ADD_INVENTRY_COLUMN_ITEMS,
  API_CONNECTOR_ACCEPTED_STATUS_CODE,
  API_CONNECTOR_CLIENT_FILTERS,
  AUTHDATA,
  CAPITAL_OPTION_NOT_REQUIRED,
  CAPITAL_OPTIONS_IN_MULTIDROPDOWN,
  CHANNEL_DOCUSIGN_COMPLETED,
  CHANNEL_INVENTORY_LISTING_CREATE_EVENT_ID,
  CHANNEL_INVENTORY_LISTING_UPDATE_EVENT_ID,
  CHANNEL_LISTING_DELETE,
  CHANNEL_LISTING_PUBLISHED_MARKETPLACE_EVENT_ID,
  CHANNEL_LISTING_REMOVED_MARKETPLACE_EVENT_ID,
  CHANNEL_NEW_NOTIFICATION_EVENT_ID,
  CHANNEL_ON_HOLD_EVENT_ID,
  CHANNEL_RELEASED_EVENT_ID,
  COUNTRY_CODE_SYMBOLS,
  deliveryTypeOrders,
  HTTP_METHODS,
  INITIAL_CONNECTOR_VIEW,
  INITIAL_HEADERS,
  INITIAL_INSTRUCTION_DETAILS,
  INITIAL_PERSONAL_DETAILS,
  INITIAL_REFERRAL_DETAILS,
  initialKeyboardField,
  INITIALSTATE,
  INSTRUCTION_LETTER__COLUMNS_ITEMS,
  INSTRUCTION_LETTER_FILTER_ITEMS,
  INVENTORY_BULK_ITEMS,
  INVENTORY_FILTER_ITEMS,
  INVENTORY_VALIDATE_COLUMN,
  INVENTRY_COLUMN_ITEMS,
  isMobile,
  MAX_FILES,
  MULTI_SELECTION_ALLOWED,
  MULTI_SELECTION_ALLOWED_TABLE,
  NEW_ORDER_INITIAL_FILTER_STATE,
  NOTIFICATION_FILTER_ITEMS,
  NOTIFICATION_POPUP,
  ORDER_ASSIGN_TO_NEW_LISTING,
  ORDER_ASSIGN_TO_NEW_TEAM,
  ORDER_ON_OFFER,
  PAYOUT_VIEW_INTIALSTATE,
  PDFFailedLoad,
  performerFilters,
  PEROSNAL_DETAILS_COLUMNS_ITEMS,
  PEROSNAL_DETAILS_FILTER_ITEMS,
  phoneRegExp,
  pngImagePath,
  PUBLISH_STATUS,
  QUERIES,
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
  // REACT_APP_AWS_ACCESS_KEY,
  // REACT_APP_AWS_REGION,
  // REACT_APP_AWS_S3_BUCKET,
  // REACT_APP_AWS_SECRET_ACCESS_KEY,
  REACT_APP_AWS_TICKET_FOLDER_NAME,
  REACT_APP_URL,
  REFERRAL_DETAILS_FILTER_ITEMS,
  REFERRALS_COLUMNS_ITEMS,
  REPORT_COLUMN_ADMIN,
  REPORT_COLUMN_ITEMS,
  REPORT_FILTER_ITEMS,
  REPORT_FILTERS_ADMIN,
  S3_CONFIG,
  SALES_COLUMN_ADMIN,
  SALES_COLUMN_ITEMS,
  SALES_FILTER_ITEMS,
  SALES_FILTERS_ADMIN,
  SYMBOLS,
  TICKET_TYPE_MAP,
  TIMEFRAMES,
  TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
  TX_ACCOUNT_COLUMN,
  TX_ACCOUNT_FILTER,
  TX_ACCOUNT_INVOICE_INITIAL_FILTER,
  TX_ACCOUNT_INVOICE_VIEW_DATA,
  TX_ACCOUNT_OPTIONS_STATE,
  TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE,
  TX_ACCOUNT_ORDER_INITIAL_FILTER,
  TX_ACCOUNT_ORDER_PAYOUT_SELLER,
  TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
  TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
  TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
  TX_PAY_FILTER_ITEMS,
  WALLET_COLUMNS,
  XERO_CONTACT_OPTIONS,
};
