import { errorReflect } from "helpers/Functions";
import { PAYOUT_TAB } from "helpers/const";
import _, { size } from "lodash";
import { useContext, useState } from "react";
import downloadSVG from "../../../assets/media/icons/other_icons/file.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { TxAccountContext } from "../core/TxAccountProvider";
import { downloadPDF, getTXAccountInvoiceListings } from "../core/_request";

const CommanFooter = ({ commonConfirmation, setCommonConfirmation }: any) => {
  const [selectAllLoader, setSelectAllLoader] = useState<any>(false);
  const layout = useContext(TxAccountContext);
  // const invoiceFilters = layout.invoicesFilter;
  const [isSelectAll, setIsSelectAll] = useState<any>();
  const tab = layout.isTabActive;
  const isDisabled =
    tab === "Invoices" &&
    size(layout.invoiceList) > 0 &&
    layout.invoiceList?.some(
      (item: any) => item?.selected && item?.invoice_type !== "group_invoice"
    );

  let invoiceTotalRecords = layout?.invoiceOverViewDetails?.meta?.total;

  const handleInvoiceSelectAllAPI = async () => {
    let responses: any[] = [];
    let numberOfTimeApiCall = Math.ceil(invoiceTotalRecords / 50);

    if (numberOfTimeApiCall > 1) {
      setSelectAllLoader(true);
    }
    for (let i = 1; i <= numberOfTimeApiCall; i++) {
      try {
        let data = await getTXAccountInvoiceListings({
          invoiceFilters: {
            // ...layout.invoicesFilter,
            ...layout.commonFilter?.invoicesFilter,
            page: i,
            per_page: 50,
          },
        });
        layout?.setInvoiceOverViewDetails((pre: any) => ({
          ...pre,
          overview:
            data?.meta?.current_page === 1 ? data.data?.revenue : pre?.overview,
          meta: data?.meta,
        }));
        responses?.push(data?.data?.invoices);
      } catch (error) {}
    }

    if (responses?.length > 0) {
      const final = responses?.flat()?.map((obj: any) => {
        return {
          ...obj,
          selected: true,
        };
      });
      layout.setInvoiceList(_.uniqBy(final, "invoice_number"));
    }
    setSelectAllLoader(false);
  };

  // HANDLE - SELECT ALL
  const handleSelectAll = (data: any) => {
    if (tab === "Invoices") {
      handleInvoiceSelectAllAPI().then(() => {
        setIsSelectAll(false);
      });
      // layout.setInvoicesFilter((current: any) => {
      //   return {
      //     ...current,
      //     page: 1,
      //     per_page: invoiceTotalRecords,
      //   };
      // });
    } else if (tab === PAYOUT_TAB) {
      layout.setPayoutList((prev: any) => {
        return prev.map((current: any) => {
          return {
            ...current,
            selected: data?.target?.checked,
          };
        });
      });
    }
  };

  // GET ALL SELECTED IDs
  const getAllSelectedIds = (data: any, id: string) => {
    layout.setCustomLoader(true);

    let allSelected: any = _.map(_.filter(data, { selected: true }), `${id}`);
    // EXECUTE DOWNLOAD - API
    downloadPDF(allSelected).then((response: any) => {
      if (
        response?.type ===
        `application/${size(allSelected) === 1 ? "pdf" : "zip"}`
      ) {
        const blob = new Blob([response], {
          type: `application/${size(allSelected) === 1 ? "pdf" : "zip"}`,
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `invoices.${size(allSelected) === 1 ? "pdf" : "zip"}`;
        link.click();

        // Clean up
        window.URL.revokeObjectURL(link.href);
      } else {
        errorReflect(response);
      }
      layout.setCustomLoader(false);
    });
  };

  // DONWLOAD ALL PDF
  const handleDownloadPDF = () => {
    if (tab === "Invoices") {
      getAllSelectedIds(layout.invoiceList, "xero_invoice_id");
    }
  };

  // useEffect(() => {
  //   if (
  //     layout?.invoiceOverViewDetails?.meta?.to === invoiceTotalRecords &&
  //     isSelectAll &&
  //     size(layout.invoiceList) === invoiceTotalRecords
  //   ) {
  //     layout.setInvoiceList((prev: any) => {
  //       return prev.map((current: any) => {
  //         return {
  //           ...current,
  //           selected: true,
  //         };
  //       });
  //     });
  //     setIsSelectAll(false);
  //   }
  // }, [
  //   layout?.invoiceOverViewDetails?.meta,
  //   invoiceTotalRecords,
  //   isSelectAll,
  //   layout.invoiceList,
  // ]);

  const isAllDataLoaded = layout.invoiceList?.length >= invoiceTotalRecords;

  const isAllSelected =
    layout.invoiceList?.filter(
      (item: any) => item.invoice_type !== "group_invoice"
    ).length > 0 &&
    layout.invoiceList
      ?.filter((item: any) => item.invoice_type !== "group_invoice")
      .every((item: any) => item.selected);

  const handleSelectAllListing = () => {
    if (!isAllDataLoaded) {
      // If all records are not loaded, show the confirmation box
      setIsSelectAll(true);
      return;
    }
    layout.setInvoiceList((prev: any) =>
      prev.map((item: any) =>
        item.invoice_type === "group_invoice"
          ? item
          : { ...item, selected: !isAllSelected }
      )
    );
  };

  return (
    <div className="flex  gap-2.5 my-2 w-full ">
      {isSelectAll ? (
        <div className="flex whitespace-nowrap items-center gap-2.5  text-sm13 anim-fade">
          <button
            className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
     bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
            type="button"
            onClick={() => setIsSelectAll(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
    bg-violet-500 hover:bg-indigo-500 text-white hover:text-white
    "
            onClick={(data: any) => handleSelectAll(data)}
          >
            Confirm
          </button>
          <span>Do you want to select all records?</span>
        </div>
      ) : (
        <>
          {tab === "Invoices" && (
            <div
              className={`
            ${
              tab === "Invoices" && size(layout.invoiceList) === 0
                ? "pointer-events-none"
                : ""
            }
            anim-fade inline-flex items-center text-violet-800 transition font-medium rounded px-2.5 py-0.5`}
            >
              <input
                id="default-checkbox"
                type="checkbox"
                checked={
                  isAllSelected
                  // tab === "Invoices"
                  //   ? size(layout.invoiceList) > 0 &&
                  //     layout.invoiceList?.every(
                  //       (item: any) =>
                  //         item?.selected &&
                  //         item?.invoice_type !== "group_invoice"
                  //     ) &&
                  //     size(layout.invoiceList) === invoiceTotalRecords
                  //   : size(layout.payoutList) > 0 &&
                  //     layout.payoutList?.every((item: any) => item?.selected)
                }
                readOnly
                className={`w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600`}
                onChange={(data) => {
                  handleSelectAllListing();
                  // if (
                  //   data?.target?.checked &&
                  //   invoiceTotalRecords !==
                  //     (size(layout.invoiceList) > 0 && size(layout.invoiceList))
                  // ) {
                  //   setIsSelectAll(true);
                  // } else if (
                  //   data?.target?.checked &&
                  //   invoiceTotalRecords ===
                  //     (size(layout.invoiceList) > 0 && size(layout.invoiceList))
                  // ) {
                  //   layout.setInvoiceList((prev: any) => {
                  //     return prev.map((current: any) => {
                  //       return {
                  //         ...current,
                  //         selected:
                  //           current?.invoice_type === "group_invoice"
                  //             ? false
                  //             : true,
                  //       };
                  //     });
                  //   });
                  // } else if (!data?.target?.checked) {
                  //   layout.setInvoiceList((prev: any) => {
                  //     return prev.map((current: any) => {
                  //       return {
                  //         ...current,
                  //         selected: false,
                  //       };
                  //     });
                  //   });
                  // }
                  // handleSelectAll(data);
                }}
              />
              <label
                htmlFor="default-checkbox"
                className={`ml-2 text-sm13 font-medium  dark:text-gray-300 text-gray-900 leading-[0.9]`}
              >
                Select all
              </label>
            </div>
          )}

          {/* DOWNLOAD PDF */}
          {tab === "Invoices" && (
            <button
              type="button"
              className={`group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                isDisabled
                  ? "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
                  : "pointer-events-none bg-gray-100 text-gray-400"
              } `}
              onClick={() => handleDownloadPDF()}
            >
              <IKTSVG
                className={`flex items-center justify-center transition ${
                  isDisabled
                    ? " group-hover:fill-white fill-white"
                    : "group-hover:fill-gray-400 fill-gray-400"
                }  mr-2`}
                path={downloadSVG}
                svgClassName="w-[.6875rem] h-3.5"
              />
              Download
            </button>
          )}
        </>
      )}
      {selectAllLoader && <ProgressBardLoader secondCounter={2} />}
    </div>
  );
};

export default CommanFooter;
