/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Collapse from "../../assets/media/icons/other_icons/Collapse.svg";
import { LayoutContext } from "../../components/core/LayoutProvider";
import NoDataComponent from "../../components/tableComponent/NoDataComponent";
import { IKTSVG } from "../../components/ui/IKTSVG";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import {
  getProperTicketTypeWithSubticketCombine,
  groupTeamMemberCommon,
  ticketTypeRename,
} from "../../helpers/Functions";
import { QUERIES } from "../../helpers/const";
import useDebounce from "../add_inventory/core/useDebounce";
import HeaderfilterContainer from "./component/HeaderfilterContainer";
import SalesFilter from "./component/SalesFilter";
import TabBody from "./component/TabBody";
import TabHeader from "./component/TabHeader";
import { SalesContext } from "./core/SalesProvider";
import { display } from "./core/_functions";
import {
  getSalesListing,
  getSalesOptions,
  getSalesOverviewListing,
} from "./core/_requests";
import DataTable from "./table/DataTable";

const Sale = () => {
  const layout = useContext(SalesContext);
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  const authUser = globalLayout.authUser;
  const access = globalLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  const isAddInventory = access?.some(
    (item: any) => item.id === "inventory" && item.status === true
  );

  const appliedFilters = useMemo(() => {
    return isGodAdmin ? layout.salesFilterCopy : layout.salesFilterHandle;
  }, [
    layout.salesFilterCopy,
    ...(!isGodAdmin ? [layout.salesFilterHandle] : []),
    isGodAdmin,
  ]);

  const handleSetCopyFilters = (filtersValue: any, replace = false) => {
    if (isGodAdmin) {
      layout.setSalesFilterCopy((curr: any) => {
        return replace === true
          ? filtersValue
          : {
              ...curr,
              ...filtersValue,
            };
      });
    }
  };

  let filters: any = { ...appliedFilters };
  let countFilter = {
    ...appliedFilters,
    page: undefined,
    per_page: undefined,
  };

  delete filters.algoliaPage;

  let orders = layout.orders;
  let sorting = { ...appliedFilters };
  let tabValue = layout.tabValue;
  let query = layout.query;
  // let copyQuery = layout.queryCopy;
  // const debouncedSearch = useDebounce(query, 1200);
  // const debouncedSearch = useMemo(() => {
  //   return isGodAdmin ? copyQuery : query;
  // }, [copyQuery, query, isGodAdmin]);
  const debouncedSearch = query;
  const [headerOptions, setHeaderOptions] = useState<any>();
  const location = useLocation();
  const [algoliaQuery, setAlgoliaQuery] = useState<any>("");
  const [startListing, setStartListing] = useState<boolean>(false);
  const [groupTeamMember, setGroupTeamMember] = useState<any>();
  let salesOptions = layout?.salesOptions;

  const sellerLevelOptionsData = globalLayout?.allowedAccess?.seller_levels;
  const teamOptionsData = globalLayout?.allowedAccess?.teams;

  // PAGINATION SET TO FIRST PAGE ON FILTER CHANGE
  useEffect(() => {
    layout.setSalesFilterHandle((prev: any) => {
      const final = {
        ...prev,
        page: 1,
        per_page: 20,
        query,
      };
      handleSetCopyFilters({ page: 1, per_page: 20, query });
      return final;
    });
    display(false);
    layout?.setOutToOfferViewData({
      status: false,
      data: "",
      index: "",
    });

    setReportTableLoading(true);
    setShimmerLoader(true);
    setShimmerOverviewLoader(true);
    if (document.body.classList.contains("overflow-hidden")) {
      document.body.classList.remove("overflow-hidden");
    }
  }, [
    // appliedFilters?.team_id && size(appliedFilters?.team_id) > 0,
    // appliedFilters?.buyer_team_id && size(appliedFilters?.buyer_team_id) > 0,
    // appliedFilters?.team_member && size(appliedFilters?.team_member) > 0,
    // appliedFilters?.event_date?.startDate,
    // appliedFilters?.time_to_event,
    // appliedFilters?.order_statuses && size(appliedFilters?.order_statuses) > 0,
    // appliedFilters?.order_exchanges &&
    //   size(appliedFilters?.order_exchanges) > 0,
    // appliedFilters?.orderDateRange?.startDate,
    // appliedFilters?.deliveryDateRange?.startDate,
    // appliedFilters?.ticket_type && size(appliedFilters?.ticket_type) > 0,
    // appliedFilters?.event_id,
    // appliedFilters?.internal_order_statuses &&
    //   size(appliedFilters?.internal_order_statuses) > 0,
    // appliedFilters?.store_id,
    // appliedFilters?.category_ids && size(appliedFilters?.category_ids) > 0,
    // appliedFilters?.performer_ids && size(appliedFilters?.performer_ids) > 0,
    // appliedFilters?.ticket_in_hand,
    // appliedFilters?.order_by,
    // appliedFilters?.sort_order,
    // layout.tabValue,
    // debouncedSearch,

    appliedFilters?.team_id,
    appliedFilters?.buyer_team_id,
    appliedFilters?.team_member,
    appliedFilters?.event_date?.startDate,
    appliedFilters?.time_to_event,
    appliedFilters?.order_statuses,
    appliedFilters?.order_exchanges,
    appliedFilters?.orderDateRange?.startDate,
    appliedFilters?.deliveryDateRange?.startDate,
    appliedFilters?.ticket_type,
    appliedFilters?.event_id,
    appliedFilters?.internal_order_statuses,
    appliedFilters?.store_id,
    appliedFilters?.category_ids,
    appliedFilters?.performer_ids,
    appliedFilters?.ticket_in_hand,
    appliedFilters?.order_by,
    appliedFilters?.sort_order,
    appliedFilters?.timeStamp,
    layout.tabValue,
    debouncedSearch,
  ]);

  useEffect(() => {
    document.body.classList.add("bg-gray-200");
    document.body.classList.add("!pr-0");
  }, []);

  // GET SALES OPTIONS
  const { data: optionsData, isLoading: optionsLoading } = useQuery(
    QUERIES.SALES_OPTIONS,
    () => getSalesOptions(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
      enabled: true,
    }
  );

  let salesDataMemo: any = useMemo(() => {
    return [tabValue, debouncedSearch, filters];
  }, [tabValue, debouncedSearch, filters]);

  let salesData: any = useDebounce(JSON.stringify(salesDataMemo), 400);
  // GET SALES LISTING
  const {
    isLoading: salesLoading,
    data: listingData,
    refetch,
    isFetching: isSalesFetching,
  } = useQuery(
    [`${QUERIES.SALES_LISTING}`, ...JSON.parse(salesData)],
    () =>
      getSalesListing({
        tabValue,
        debouncedSearch,
        filters,
        sorting,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      // enabled: startListing ? true : false,
      staleTime: Infinity,
      // staleTime: 0,
      cacheTime: 0,
      onError: (err) => {},
      onSettled: (err) => {
        // display(false);
        // layout?.setOutToOfferViewData({
        //   status: false,
        //   data: "",
        //   index: "",
        // });
        setTimeout(() => {
          setReportTableLoading(false);
          setShimmerLoader(false);
          layout.setIsSelectAll(false);
        }, 200);
      },
    }
  );

  // GET SALES OVERVIEW

  const {
    isLoading: salesOverviewLoading,
    data: overviewListingData,
    refetch: countRefetch,
  } = useQuery(
    [`sales-overview-data`, tabValue, debouncedSearch, countFilter, sorting],
    () =>
      getSalesOverviewListing({
        tabValue,
        debouncedSearch,
        filters: countFilter,
        sorting,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: startListing ? true : false,
      staleTime: Infinity,
      // staleTime: 0,
      cacheTime: 0,
      onError: (err) => {},
      onSettled: (err) => {
        setShimmerOverviewLoader(false);
      },
    }
  );

  // ON ENTER - SEARCH QUERY (ALGOLIA)
  const handleKeyDown = (event: any) => {
    if (event?.target?.value && event.key === "Enter") {
      event.preventDefault();
      layout?.setQuery(event?.target?.value);
    }
  };

  // LOAD TAB OPTIONS VIA DASHBOARD
  useEffect(() => {
    if (location && location?.state === "to_be_fulfilled") {
      layout.setTabValue(location?.state);
      setTimeout(() => {
        layout.setSalesFilterHandle((currentVal: any) => {
          const final = {
            ...currentVal,
            team_member: [],
          };
          handleSetCopyFilters({ team_member: [] });
          return final;
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // LOAD HEADER COUNTS
  useEffect(() => {
    if (overviewListingData?.data) {
      setHeaderOptions(overviewListingData?.data);
    }
    // }
  }, [overviewListingData, tabValue]);

  // GET ORDERS FOR LISTING
  useEffect(() => {
    // AFTER LAZY LOAD
    const updateList = async () => {
      if (!isSalesFetching) {
        if (
          listingData?.data &&
          listingData?.data?.orders &&
          listingData?.data?.orders.length > 0
        ) {
          const newData = await Promise.all(
            listingData?.data?.orders?.map((order: any) => {
              return {
                ...order,
                selected: false,
                isLock: true,
              };
            })
          );
          layout.setOrders((pre: any) => {
            if (appliedFilters?.page > 1) {
              return _.uniqBy([...pre, ...newData], "order_id");
            } else {
              return [...newData];
            }
          });
        } else {
          layout.setOrders([]);
        }
      }
    };
    updateList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingData?.data?.orders, layout.salesFilterHandle]);

  // grouping team member

  useEffect(() => {
    if (_.size(teamOptionsData) > 0 && isGodAdmin) {
      const options = groupTeamMemberCommon(teamOptionsData);
      setGroupTeamMember(options);
    }
  }, [teamOptionsData, isGodAdmin]);

  // LOAD SALES OPTIONS
  useEffect(() => {
    const ticketType = getProperTicketTypeWithSubticketCombine(
      optionsData?.data?.ticket_type
    );
    const newRenameTicketType = ticketType?.map((obj: any) => {
      return {
        ...obj,
        name: ticketTypeRename(obj?.id),
      };
    });
    let updatedTicketTypes = _.orderBy(newRenameTicketType, ["name"], ["asc"]);
    if (isGodAdmin) {
      layout?.setSalesOptions({
        ...optionsData?.data,
        team_members:
          _.size(layout.salesFilterHandle?.team_id) > 0
            ? groupTeamMemberCommon(layout.salesFilterHandle?.team_id)
            : groupTeamMember,
        ticket_type: updatedTicketTypes,
      });
    } else {
      layout?.setSalesOptions({
        ...optionsData?.data,
        ticket_type: updatedTicketTypes,
      });
    }
  }, [
    optionsData,
    isGodAdmin,
    layout.salesFilterHandle?.team_id,
    groupTeamMember,
  ]);

  useEffect(() => {
    if (salesOptions && !isGodAdmin) {
      layout.setSalesFilterHandle((currentValue: any) => {
        let userLogged = salesOptions?.team_members?.find(
          (loggedUser: any) => loggedUser?.is_logged_in
        );

        const final = {
          page: 1,
          per_page: 20,
          ticket_in_hand: false,
          query: currentValue?.query,
          ...(userSpecific && { specific: authUser?.id }),
          ...(!userSpecific && {
            team_member: !startListing
              ? userLogged
                ? [userLogged]
                : []
              : currentValue?.team_member,
          }),
        };
        handleSetCopyFilters(final, true);
        return final;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesOptions, isGodAdmin]);

  useEffect(() => {
    if (salesOptions?.team_members) {
      setTimeout(() => {
        setStartListing(true);
      }, 1000);
    }
  }, [salesOptions?.team_members]);

  // get team otpions
  useEffect(() => {
    if (teamOptionsData?.length > 0) {
      let updatedOptions = teamOptionsData;
      layout?.setTeamsOptions(updatedOptions);
      layout?.setSellerLevelOptions(
        sellerLevelOptionsData?.map((item: any) => ({
          ...item,
          name: item?.title,
        }))
      );
    }
  }, [teamOptionsData, sellerLevelOptionsData]);

  const [customReportLoading, setReportLoading] = useState(true);
  const [customReportTableLoading, setReportTableLoading] = useState(true);
  const [shimmerLoader, setShimmerLoader] = useState<any>(true);
  const [shimmerOverviewLoader, setShimmerOverviewLoader] = useState<any>(true);
  const [secondCounter, setSecondCounter] = useState(2.5);
  const [tabChange, setTabChange] = useState<any>();

  /*for sticky event bar*/
  const heightRef1: any = useRef();
  const heightRef2: any = useRef();
  const toggleRef: any = useRef();
  const [IsToggle, setIsToggle] = useState<any>(true);

  const toggle = document.querySelector(
    '[data-te-target="#inventoryaccordion"]'
  );

  const [overViewHeight, setOverViewHeight] = useState<any>();
  useEffect(() => {
    let height3: any;
    const element: any = document.querySelector("#inventoryaccordion");
    setTimeout(() => {
      height3 = IsToggle === true ? element?.offsetHeight : 0;
      setOverViewHeight(height3);
    }, 400);
  }, [IsToggle]);

  useEffect(() => {
    const stickyElement = document.getElementById("topFilters") as HTMLElement;
    const stickyElementStyle = window.getComputedStyle(stickyElement);
    const stickyElementheight: any =
      stickyElementStyle.getPropertyValue("height");

    const filter = document.getElementById("filters_block") as HTMLElement;
    const heightheightRef2 = filter.offsetHeight;

    if (stickyElement) {
      // const heightheightRef2 = heightRef2?.current?.offsetHeight;

      const initialPosition = stickyElement.getBoundingClientRect().top;
      const accordion = document.getElementById("inventoryaccordion");

      const accorAttr = accordion?.getAttribute("data-te-collapse-show");

      let MarginTopTotle = 0;
      let topPosition = 0;

      // const MarginTopTotle =
      //     IsToggle === true
      //       ? heightheightRef1 + overViewHeight - HeaderHeight
      //       : heightheightRef1 - HeaderHeight;

      const recalculateMarginTopTotle = () => {
        const accordion = document.getElementById(
          "inventoryaccordion"
        ) as HTMLElement;
        const accordionStyle = window.getComputedStyle(accordion);
        const accordionheight: any = parseFloat(
          accordionStyle.getPropertyValue("height")
        );
        const filter = document.getElementById("filters_block") as HTMLElement;
        const heightheightRef2 = filter.offsetHeight;
        // Recalculate MarginTopTotle based on updated heights or values
        const heightheightRef1 = heightRef1?.current?.offsetHeight;
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const salesFilter: any =
          document.getElementById("salesFilter")?.clientHeight;

        var computedStyle = window.getComputedStyle(stickyElement);
        var paddingBottom = computedStyle.paddingBottom;
        var paddingBottomValue = parseInt(paddingBottom, 10);

        const mediaQuery = window.matchMedia("(max-width: 767px)");

        // console.log(paddingBottomValue);
        if (window.matchMedia("(max-width: 767px)").matches) {
          topPosition = HeaderHeight;
        } else {
          topPosition =
            heightheightRef2 +
            salesFilter +
            HeaderHeight +
            paddingBottomValue -
            1;
        }

        MarginTopTotle =
          IsToggle === true
            ? heightheightRef1 + accordionheight - HeaderHeight
            : HeaderHeight - heightheightRef1;
      };

      const makeSticky = function () {
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const sticky_head: any = document.getElementById("sticky-head");
        const stickyShadow: any = document.getElementById("sticky-shadow");
        const sticky_headHeight: any = sticky_head?.clientHeight;

        recalculateMarginTopTotle();
        if (window.scrollY + HeaderHeight >= initialPosition) {
          if (stickyElement) stickyElement.classList.add("sticky");
          stickyElement.style.top =
            IsToggle === true ? `-${MarginTopTotle}px` : `${MarginTopTotle}px`;
          if (sticky_head) sticky_head.style.top = topPosition + "px";
          if (stickyShadow)
            stickyShadow.style.top = topPosition + sticky_headHeight + "px";
        } else {
          if (stickyElement) stickyElement.classList.remove("sticky");
          stickyElement.style.top = `0px`;
          if (sticky_head) sticky_head.style.top = "0px";
          if (stickyShadow) stickyShadow.style.top = "0px";
        }
      };
      const handleWindowResize = () => {
        // Handle logic when the window is resized
        makeSticky(); // Update sticky behavior based on new values
        recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
      };

      // if (window.scrollY > 200) {
      //   makeSticky();
      // }

      window.addEventListener("scroll", makeSticky);
      window.addEventListener("change", makeSticky);
      window.addEventListener("blur", makeSticky);

      setTimeout(() => {
        document.addEventListener("resize", handleWindowResize);
      }, 200);
      return () => {
        window.removeEventListener("scroll", makeSticky);
        window.removeEventListener("change", makeSticky);
        window.removeEventListener("blur", makeSticky);
        document.removeEventListener("resize", handleWindowResize);
      };
    }
    // });
  }, [toggleRef, IsToggle, overViewHeight]);

  function removeBodyScroll() {
    var body = document.querySelector("body");
    body?.classList?.remove("overflow-hidden");
  }
  useEffect(() => {
    // Set the query from localStorage if it exists
    if (localStorage.getItem("salesQuery")) {
      layout?.setQuery(localStorage.getItem("salesQuery"));
    }

    // Add an event listener to remove the item on page refresh
    const handleBeforeUnload = () => {
      localStorage.removeItem("salesQuery");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  /* end sticky event */
  return (
    <>
      <div className="main-content-wrap pb-[3.125rem]">
        <div
          className={`topFilters sticky-element top-[3.75rem] z-[11] pb-5 bg-gray-200 max-md:z-[21] max-md:!static`}
          id="topFilters"
        >
          <div className="top-accor shadow-op2">
            {/* HEADER */}
            <div className="accordion font-medium" ref={heightRef1}>
              <div className=" !rounded-none bg-white accordion-item">
                <div
                  className="flex  justify-between accordion-header mb-0"
                  id="headingOne"
                >
                  <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2">
                    {/* HEADER FILTER */}
                    <HeaderfilterContainer />
                  </div>
                  <div>
                    <button
                      className="group relative flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l  rounded-tr transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
                      type="button"
                      data-te-collapse-init
                      data-te-toggle="collapse"
                      data-te-target="#inventoryaccordion"
                      aria-expanded="true"
                      aria-controls="inventoryaccordion"
                      onClick={() =>
                        setIsToggle(IsToggle === false ? true : false)
                      }
                    >
                      <IKTSVG
                        className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                        path={Collapse}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* TAB */}
            <div
              className="bg-white border-t accordion-collapse font-medium collapse show"
              id="inventoryaccordion"
              data-te-collapse-item
              data-te-collapse-show
              aria-labelledby="headingOne"
            >
              {/* TAB HEADER */}
              <div
                className="tab-panel bg-no-repeat bg-setting-bg bg-cover p-5 pb-0 flex after:content after:absolute after:top-full after:left-0 after:w-full after:h-full after:shadow-3xl after:z-0 relative overflow-y-hidden overflow-x-auto flex-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
                role="tablist"
                data-te-nav-ref
              >
                <TabHeader
                  tabId="new"
                  // name="New sales"
                  name="Pending"
                  count={headerOptions?.new_tab}
                  countColor={
                    tabValue === "new" ? "bg-violet-500" : "bg-violet-500"
                  }
                  ticketPrice={headerOptions?.new_total_ticket}
                  postKey="new"
                  handleTabClick={(data: any) => {
                    layout.setTabValue(data);
                    removeBodyScroll();
                  }}
                  shimmerLoader={shimmerOverviewLoader}
                />
                <TabHeader
                  tabId="to_be_fulfilled"
                  // name="To be fulfilled"
                  name="Awaiting Delivery"
                  count={headerOptions?.to_be_fulfilled_tab}
                  countColor={
                    tabValue === "to_be_fulfilled"
                      ? "bg-violet-500"
                      : "bg-violet-500"
                  }
                  ticketPrice={headerOptions?.tobe_fulfilled_total_ticket}
                  postKey="to_be_fulfilled"
                  handleTabClick={(data: any) => {
                    setTabChange(2);
                    layout.setTabValue(data);
                    removeBodyScroll();
                  }}
                  shimmerLoader={shimmerOverviewLoader}
                />
                {isGodAdmin && (
                  <TabHeader
                    tabId="out_to_offer"
                    // name="To be fulfilled"
                    name="Open Orders"
                    count={headerOptions?.offer_order_tab}
                    countColor={
                      tabValue === "out_to_offer"
                        ? "bg-violet-500"
                        : "bg-violet-500"
                    }
                    ticketPrice={headerOptions?.offer_order_total_ticket}
                    postKey="out_to_offer"
                    handleTabClick={(data: any) => {
                      setTabChange(6);
                      layout.setTabValue(data);
                      removeBodyScroll();
                    }}
                    shimmerLoader={shimmerOverviewLoader}
                  />
                )}
                <TabHeader
                  tabId="fulfilled"
                  // name="Fulfilled"
                  name="Delivered"
                  count={headerOptions?.fulfilled_tab}
                  countColor={
                    tabValue === "fulfilled" ? "bg-violet-500" : "bg-violet-500"
                  }
                  ticketPrice={headerOptions?.fulfilled_total_ticket}
                  postKey="fulfilled"
                  handleTabClick={(data: any) => {
                    setTabChange(3);
                    layout.setTabValue(data);
                    removeBodyScroll();
                  }}
                  shimmerLoader={shimmerOverviewLoader}
                />
                <TabHeader
                  tabId="completed"
                  name="Completed"
                  count={headerOptions?.completed_tab}
                  countColor={
                    tabValue === "completed" ? "bg-violet-500" : "bg-violet-500"
                  }
                  ticketPrice={headerOptions?.completed_total_ticket}
                  postKey="completed"
                  handleTabClick={(data: any) => {
                    setTabChange(4);
                    layout.setTabValue(data);
                    removeBodyScroll();
                  }}
                  shimmerLoader={shimmerOverviewLoader}
                />
                <TabHeader
                  tabId="cancelled"
                  // name="Cancelled/issues"
                  name="Cancelled"
                  count={headerOptions?.cancelled_tab}
                  countColor={
                    tabValue === "cancelled" ? "bg-violet-500" : "bg-violet-500"
                  }
                  ticketPrice={headerOptions?.cancelled_total_ticket}
                  postKey="cancelled"
                  handleTabClick={(data: any) => {
                    setTabChange(5);
                    layout.setTabValue(data);
                    removeBodyScroll();
                  }}
                  shimmerLoader={shimmerOverviewLoader}
                />
              </div>

              {/* TAB BODY */}

              {tabValue === "new" && (
                <TabBody
                  bodyId="new"
                  isFirst={true}
                  data={headerOptions}
                  salesOptions={salesOptions}
                  countBoxTitle="Pending revenue"
                  shimmerLoader={shimmerOverviewLoader}
                ></TabBody>
              )}
              {tabValue === "to_be_fulfilled" && (
                <TabBody
                  bodyId="to_be_fulfilled"
                  isFirst={false}
                  data={headerOptions}
                  countBoxTitle="Awaiting delivery revenue"
                  shimmerLoader={shimmerOverviewLoader}
                  salesOptions={salesOptions}
                ></TabBody>
              )}

              {tabValue === "out_to_offer" && isGodAdmin && (
                <TabBody
                  bodyId="out_to_offer"
                  isFirst={false}
                  data={headerOptions}
                  countBoxTitle="Open Orders revenue"
                  shimmerLoader={shimmerOverviewLoader}
                  salesOptions={salesOptions}
                ></TabBody>
              )}

              {tabValue === "fulfilled" && (
                <TabBody
                  bodyId="fulfilled"
                  isFirst={false}
                  data={headerOptions}
                  countBoxTitle="Delivery revenue"
                  shimmerLoader={shimmerLoader}
                  salesOptions={salesOptions}
                ></TabBody>
              )}
              {tabValue === "completed" && (
                <TabBody
                  bodyId="completed"
                  isFirst={false}
                  data={headerOptions}
                  countBoxTitle="Completed revenue"
                  shimmerLoader={shimmerOverviewLoader}
                  salesOptions={salesOptions}
                >
                  {" "}
                </TabBody>
              )}
              {tabValue === "cancelled" && (
                <TabBody
                  bodyId="cancelled"
                  isFirst={false}
                  data={headerOptions}
                  countBoxTitle="Cancelled revenue"
                  shimmerLoader={shimmerOverviewLoader}
                  salesOptions={salesOptions}
                ></TabBody>
              )}
            </div>
            <SalesFilter
              // query={isGodAdmin ? layout.queryCopy : layout.query}
              query={query}
              options={salesOptions}
              debouncedSearch={debouncedSearch}
              paginateData={listingData?.meta}
              handleQuery={(data: any) => layout?.setQuery(data)}
              handleAlgoliaValue={(data: any) => setAlgoliaQuery(data)}
              handleKeyDown={(event: any) => handleKeyDown(event)}
              teamsOptions={layout?.teamsOptions}
              shimmerLoader={shimmerLoader}
              handleSetCopyFilters={handleSetCopyFilters}
            />
          </div>
        </div>

        {customReportTableLoading
          ? Array.from({ length: 10 }, (v, i) => (
              <div
                className={`accordion font-medium  mb-2.5 mx-5 ${
                  i === 0 && "mt-0"
                } `}
              >
                <div
                  key={i}
                  className={`accordion-item bg-white !rounded overflow-hidden ${
                    customReportTableLoading && "shimmer-effect"
                  } `}
                  style={{ height: "40px" }}
                ></div>
              </div>
            ))
          : orders?.length > 0 && (
              <DataTable
                data={orders}
                tabValue={tabValue}
                paginateData={listingData?.meta}
                refetch={() => {
                  refetch();
                  countRefetch();
                }}
                isGodAdmin={isGodAdmin}
                salesLoading={salesLoading}
                optionsData={optionsData}
                handleSetCopyFilters={handleSetCopyFilters}
              />
            )}
        {customReportTableLoading && (
          <ProgressBardLoader
            LoadingText=" Loading your sales data"
            secondCounter={secondCounter}
          />
        )}

        {orders?.length === 0 && !customReportTableLoading && (
          <>
            <NoDataComponent />
          </>
        )}
      </div>
      {/* {(salesLoading || !startListing) && <Loader />} */}
    </>
  );
};

export default Sale;
