/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import {
  getCountListing,
  getInventoryEventList,
  getInventoryLogListing,
  getInventoryRecordListing,
  handleBulk,
  UpdateInventory,
} from "./core/_request";

import _, { isArray } from "lodash";
import { LayoutContext } from "../../components/core/LayoutProvider";
import { IKTSVG } from "../../components/ui/IKTSVG";
import { KTSVG } from "../../components/ui/KTSVG";
import useDebounce from "../add_inventory/core/useDebounce";
import { InventoryContext } from "./core/InventoryProvider";
import PusherSetup from "./core/PusherSetup";
import ETicketUpload from "./widgets/ETicketUpload";
import EditTableFieldSideBar from "./widgets/EditTableFieldSideBar";

import { getInstructionLetterTemplateList } from "pages/Sales/core/_requests";
import PPE from "../../assets/media/icons/other_icons/PPE.svg";
import Upload from "../../assets/media/icons/other_icons/Upload.svg";
import Check from "../../assets/media/icons/other_icons/check.svg";
import HaxPublishGray from "../../assets/media/icons/other_icons/hax-Publish-Gray.svg";
import Refresh from "../../assets/media/icons/other_icons/refresh.svg";
import AngleLeft from "../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../assets/media/icons/standard_icons/angle_right.svg";
import Cross from "../../assets/media/icons/standard_icons/cross.svg";
import SelectAngle from "../../assets/media/icons/standard_icons/select-angle-indigo.svg";
import NoDataComponent from "../../components/tableComponent/NoDataComponent";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import { splitTypeOptions } from "../../helpers/AssetHelpers";
import {
  convertPPEPartnersToLocalFormat,
  decimalNumber,
  groupTeamMemberCommon,
  removeCommaFromNumber,
  ticketTypeRename,
} from "../../helpers/Functions";
import { showAlert } from "../../helpers/ShowAlert";
import { QUERIES } from "../../helpers/const";
import { algoliaSearch } from "../Reports/core/requests";
import { reArrangeDeliveryArray } from "../add_inventory/core/_functions";
import {
  calculateAvgPriceForProceedValue,
  changedSelectedCount,
  setEventRecordPayload,
  visibilityMeterCalculationForInventory,
} from "./core/_functions";
import Footer from "./layout/Footer";
import EventTable from "./table/EventTable";
import EditInventoryMobileView from "./table/component/EditInventoryMobileView";
import InventoryFilter from "./table/component/InventoryFilter";
import ListingQualityPopup from "./widgets/ListingQualityPopup";
import { LogDetailsPopUp } from "./widgets/LogDetails/LogDetailsPopUp";

const Inventory = memo(
  ({
    list,
    setList,
    publishStatus,
    setPublishStatus,
    currentRecordId,
    setCurrentRecordId,
    query,
    setQuery,
  }: any) => {
    const layout = useContext(InventoryContext);
    const coreLayout = useContext(LayoutContext);
    const target_currency = coreLayout.currencyLabel;
    let isMobile = coreLayout?.isMobile;
    const isTableEditable: [] = layout.isTableEditable;
    let isTableDisabled =
      (isTableEditable && _.size(isTableEditable) > 0) ||
      layout.tableAlert?.confirm;
    const conversation_rate = coreLayout?.conversationRate;

    //GOD ADMIN
    const [teamsOptions, setTeamsOptions] = useState<any>();
    const [groupTeamMember, setGroupTeamMember] = useState<any>();

    const teamOptionsData = coreLayout?.allowedAccess?.teams;
    const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;

    const setEventId = layout.setEventId;
    const authUser = coreLayout.authUser;
    // CHECK USER SPECIFIC
    // const userSpecific = true;
    const access = coreLayout.allowedAccess?.allowed_access;
    const userSpecific = access?.some(
      (item: any) => item.id === "user-specific-data" && item.status === true
    );
    const isAddInventory = access?.some(
      (item: any) => item.id === "inventory" && item.status === true
    );

    let users = layout.users;

    const [customLoading, setCustomLoading] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<any>();
    const [paginateData, setPaginateData] = useState<any>();
    const [pagination, setPagination] = useState<any>({
      page: 1,
      per_page: isGodAdmin ? 25 : 50,
    });
    const [SortingCurrentID, setSortingCurrentID] = useState<any>();
    const [editView, setEditView] = useState<any>();
    const debouncedSearch = useDebounce(query, 1200);
    const [listCountingInfo, setListCountingInfo] = useState<any>();
    const [startListing, setStartListing] = useState<boolean>(false);
    const [isEventListenerAdded, setIsEventListenerAdded] = useState(false);

    // EDIT POPUP STATES
    const [editData, setEditData] = useState<any>();
    const [isOpenTicketPopUp, setIsOpenTicketPopUp] = useState<any>(false);
    const [isOpenListingQualityPopup, setIsOpenListingQualityPopup] =
      useState<any>(false);
    const [matchIndexView, setMatchIndexView] = useState<any>();

    const tableRefSide: any = useRef();

    // ALGOLIA STATES
    const [algoliaOptions, setAlgoliaOptions] = useState<any>();
    const [inputChange, setInputChange] = useState<any>();
    const listingIds = coreLayout.editListings;
    const [inventoryFilterHandle, setInventoryFilterHandle] = useState<any>({
      listingIds: listingIds,
    });
    const algoliaDebounce = useDebounce(inputChange, 1200);
    const [teamsSelected, setTeamsSelected] = useState<any>();

    let inventoryFilterCopy = { ...inventoryFilterHandle };
    delete inventoryFilterCopy.algoliaPage;
    const newInventoryFilter: any = layout.inventoryNewFilterValue;
    const [instructionLetterTemplates, setInstructionLetterTemplates] =
      useState<any>({});
    const commonFilter = layout?.commonFilter?.newFilter;
    // RESET TO PAGE ONE WHEN ANY FILTER APPLY
    useEffect(() => {
      inventoryFilterHandle?.event_id?.length === 0 && setAlgoliaOptions([]);
      setPagination((prev: any) => {
        return {
          ...prev,
          page: 1,
          per_page: isGodAdmin ? 25 : 50,
        };
      });

      layout.setCommonFilter((pre: any) => {
        return {
          ...pre,
          copyFilter: {
            debouncedSearch,
            pagination,
            publishStatus,
            users,
            dateRange,
            inventoryFilterCopy,
            teamsSelected,
            newInventoryFilter,
          },
          newFilter: {
            ...pre.newFilter,
            ...(inventoryFilterHandle?.listingIds?.length > 0 && {
              inventoryFilterCopy,
            }),
          },
        };
      });
    }, [
      publishStatus,
      layout.users,
      dateRange,
      teamsSelected,
      inventoryFilterHandle,
      isGodAdmin,
      layout.inventoryNewFilterValue?.ticket_type,
      layout.inventoryNewFilterValue?.in_hand,
      layout.inventoryNewFilterValue?.is_ticket_uploaded,
      layout.inventoryNewFilterValue?.listing_quality,
      layout.inventoryNewFilterValue?.hospitality,
      layout.inventoryNewFilterValue?.ticketstatus,
      layout.inventoryNewFilterValue?.category_ids,
      layout.inventoryNewFilterValue?.recreated_listings,
    ]);

    const handleInputChange = (data: any, event: any) => {
      // FIXED LAG ISSUE DURING TYPING
      if (
        event.action === "menu-close" ||
        event.action === "set-value" ||
        event.action === "input-blur"
      ) {
        if (!data) {
          setInputChange((current: any) => {
            return current;
          });
        }
      } else {
        setInputChange((current: any) => {
          if (algoliaDebounce !== data) {
            setAlgoliaOptions([]);
          }
          return data;
        });
      }
    };

    // ON CLEAR BUTTON - ALGOLIA
    const onSearch = (data: any) => {
      if (data?.target?.id !== "search") {
        setInputChange("");
        setAlgoliaOptions([]);
      }
    };

    const salesFilter = inventoryFilterHandle;
    // MULTI SELECT ALGOLIA
    const { isLoading: algoliaLoading, data: optionsData } = useQuery(
      [`${QUERIES.ALGOLIA_INVENTORY}`, algoliaDebounce, salesFilter],
      () => {
        if (inputChange && inputChange.trim() !== "") {
          return algoliaSearch({ inputChange, salesFilter, coreLayout });
        }
        return Promise.resolve(); // Return an empty resolved promise when algoliaDebounce is falsy or an empty string
      },
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: true,
        enabled: algoliaDebounce ? true : false,
        onError: (err) => {},
      }
    );

    const algoliaData = optionsData;

    // LOAD ALGOLIA OPTIONS IN STATE
    useEffect(() => {
      if (optionsData?.hits) {
        setAlgoliaOptions((current: any) => {
          return current?.length > 0
            ? _.uniqBy([...current, ...optionsData?.hits], "id")
            : optionsData?.hits;
        });
      }
    }, [optionsData]);

    // ON ENTER - SEARCH QUERY (ALGOLIA)
    const handleKeyDown = (event: any) => {
      if (event?.target?.value && event.key === "Enter") {
        event.preventDefault();
        // after  10-6 code
        setInventoryFilterHandle((pre: any) => {
          return {
            ...pre,
            page: 1,
            per_page: isGodAdmin ? 25 : 50,
            debouncedSearch: event?.target?.value,
          };
        });
        handleOnApplyFilter("apply", {
          ...commonFilter,
          inventoryFilterCopy: {
            ...commonFilter.inventoryFilterCopy,
            debouncedSearch: event?.target?.value,
          },
        });
      }
    };

    // RESET ALGOLIA OPTIONS
    useEffect(() => {
      if (_.size(inventoryFilterHandle?.event_id) === 0) {
        // setAlgoliaOptions([]);
      }
    }, [inventoryFilterHandle?.event_id]);

    // FASTER VERSION FOR USE QUERY
    // let eventListMemo: any = useMemo(() => {
    //   return [
    //     // debouncedSearch,
    //     pagination,
    //     publishStatus,
    //     users,
    //     dateRange,
    //     teamsSelected,
    //     inventoryFilterCopy,
    //     newInventoryFilter,
    //   ];
    // }, [
    //   // debouncedSearch,
    //   pagination,
    //   publishStatus,
    //   users,
    //   dateRange,
    //   teamsSelected,
    //   inventoryFilterCopy,
    //   newInventoryFilter,
    // ]);

    const getFilteredValue = (
      isGodAdmin: any,
      godAdminValue: any,
      normalValue: any
    ) => {
      return isGodAdmin ? godAdminValue : normalValue;
    };

    const getEventListMemo = (
      isGodAdmin: boolean,
      commonFilter: any,
      pagination: any,
      publishStatus: any,
      users: any,
      dateRange: any,
      teamsSelected: any,
      inventoryFilterCopy: any,
      newInventoryFilter: any,
      timeStamp: any
    ) => {
      return [
        getFilteredValue(
          isGodAdmin,
          commonFilter?.newFilter?.pagination,
          pagination
        ),
        getFilteredValue(
          isGodAdmin,
          commonFilter?.newFilter?.publishStatus,
          publishStatus
        ),
        getFilteredValue(isGodAdmin, commonFilter?.newFilter?.users, users),
        getFilteredValue(
          isGodAdmin,
          commonFilter?.newFilter?.dateRange,
          dateRange
        ),
        getFilteredValue(
          isGodAdmin,
          commonFilter?.newFilter?.teamsSelected,
          teamsSelected
        ),
        getFilteredValue(
          isGodAdmin,
          commonFilter?.newFilter?.inventoryFilterCopy,
          inventoryFilterCopy
        ),
        getFilteredValue(
          isGodAdmin,
          commonFilter?.newFilter?.newInventoryFilter,
          newInventoryFilter
        ),
        layout.commonFilter?.newFilter?.timeStamp,
      ];
    };

    const eventListMemo = useMemo(() => {
      return getEventListMemo(
        isGodAdmin,
        layout.commonFilter,
        pagination,
        publishStatus,
        users,
        dateRange,
        teamsSelected,
        inventoryFilterCopy,
        newInventoryFilter,
        layout.commonFilter?.newFilter?.timeStamp
      );
    }, [
      isGodAdmin,
      getFilteredValue(
        isGodAdmin,
        layout.commonFilter?.newFilter?.pagination,
        pagination
      ),
      getFilteredValue(
        isGodAdmin,
        layout.commonFilter?.newFilter?.publishStatus,
        publishStatus
      ),
      getFilteredValue(
        isGodAdmin,
        layout.commonFilter?.newFilter?.users,
        users
      ),
      getFilteredValue(
        isGodAdmin,
        layout.commonFilter?.newFilter?.dateRange,
        dateRange
      ),
      getFilteredValue(
        isGodAdmin,
        layout.commonFilter?.newFilter?.teamsSelected,
        teamsSelected
      ),
      getFilteredValue(
        isGodAdmin,
        layout.commonFilter?.newFilter?.inventoryFilterCopy,
        inventoryFilterCopy
      ),
      getFilteredValue(
        isGodAdmin,
        layout.commonFilter?.newFilter?.newInventoryFilter,
        newInventoryFilter
      ),
      layout.commonFilter?.newFilter?.timeStamp,
    ]);

    let eventListData: any = useDebounce(JSON.stringify(eventListMemo), 200);
    // EVENT LISTING API
    const { isLoading, data, refetch, isFetching } = useQuery(
      [`${QUERIES.INVENTORY_EVENT_LIST}`, ...JSON.parse(eventListData)],
      () =>
        getInventoryEventList({
          debouncedSearch,
          pagination: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.pagination,
            pagination
          ),
          publishStatus: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.publishStatus,
            publishStatus
          ),
          users: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.users,
            users
          ),
          dateRange: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.dateRange,
            dateRange
          ),
          inventoryFilterCopy: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.inventoryFilterCopy,
            inventoryFilterCopy
          ),
          teamsSelected: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.teamsSelected,
            teamsSelected
          ),
          newInventoryFilter: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.newInventoryFilter,
            newInventoryFilter
          ),
          // ...(userSpecific && authUser?.id && { team_member: [authUser?.id] }),
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        enabled: true,
        onError: (err) => {},
      }
    );

    // GET COUNT LISTING
    const {
      isLoading: countLoading,
      data: countList,
      refetch: countRefetch,
    } = useQuery(
      [
        `${QUERIES.INVENTORY_EVENT_COUNT}`,
        debouncedSearch,
        getFilteredValue(
          isGodAdmin,
          layout.commonFilter?.newFilter?.users,
          users
        ),
        getFilteredValue(
          isGodAdmin,
          layout.commonFilter?.newFilter?.dateRange,
          dateRange
        ),
        getFilteredValue(
          isGodAdmin,
          layout.commonFilter?.newFilter?.publishStatus,
          publishStatus
        ),
        getFilteredValue(
          isGodAdmin,
          layout.commonFilter?.newFilter?.inventoryFilterCopy,
          inventoryFilterCopy
        ),
        getFilteredValue(
          isGodAdmin,
          layout.commonFilter?.newFilter?.teamsSelected,
          teamsSelected
        ),
        getFilteredValue(
          isGodAdmin,
          layout.commonFilter?.newFilter?.pagination,
          pagination
        ),
        getFilteredValue(
          isGodAdmin,
          layout.commonFilter?.newFilter?.newInventoryFilter,
          newInventoryFilter
        ),
      ],
      () =>
        getCountListing({
          debouncedSearch,
          users: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.users,
            users
          ),
          dateRange: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.dateRange,
            dateRange
          ),
          publishStatus: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.publishStatus,
            publishStatus
          ),
          inventoryFilterCopy: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.inventoryFilterCopy,
            inventoryFilterCopy
          ),
          teamsSelected: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.teamsSelected,
            teamsSelected
          ),
          newInventoryFilter: getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.newInventoryFilter,
            newInventoryFilter
          ),
          // ...(userSpecific && authUser?.id && { team_member: [authUser?.id] }),
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        enabled:
          getFilteredValue(
            isGodAdmin,
            layout.commonFilter?.newFilter?.pagination,
            pagination
          )?.page === 1
            ? true
            : false,
        onError: (err) => {},
      }
    );

    //REMOVE BODY HIDDEN CLASS
    let body: any = document.querySelector("body");
    let eventAccordion: any = document.getElementById("EventAccordion");

    const removeOverflowHiddenBody = (event: any) => {
      if (!eventAccordion.contains(event.target)) {
        body?.classList.remove("overflow-hidden");
        body?.classList.remove("body-padding");
        body?.classList.remove("body-padding-17");
        document.body.style.paddingRight = 0 + "px";
      }
    };

    useEffect(() => {
      if (!isEventListenerAdded && body && eventAccordion) {
        body.addEventListener("mouseover", removeOverflowHiddenBody);
        setIsEventListenerAdded(true);
      }
      setCustomLoading(false);
    }, [list]);

    // CALL COUNT API AFTER GET EVENT
    useEffect(() => {
      if (countList?.data) {
        setListCountingInfo(countList?.data);
      }
    }, [countList]);

    // SET EVENTS & OPTIONS IN STATE
    useEffect(() => {
      setCustomLoading(true);
      if (!isLoading && data) {
        // BEFORE LAZY LOAD

        const tempData = data?.data?.events?.map((event: any) => {
          return {
            ...event,
            requireSelectAll: {},
            isOpen: false,
            categories: event?.categories?.map((category: any) => {
              return {
                ...category,
                sections: category?.sections?.map((section: any) => {
                  return {
                    id: section?.id,
                    name:
                      category?.name !== section?.name
                        ? section?.name.replace(category?.name, "")
                        : section?.name,
                    originalName: section?.name,
                  };
                }),
              };
            }),
          };
        });
        setList(tempData);

        // AFTER LAZY LOAD

        // setList((pre: any) => {
        //   const newData = (data?.data?.events || [])?.map((event: any) => ({
        //     ...event,
        //     categories: (event?.categories || [])?.map((category: any) => ({
        //       ...category,
        //       sections: (category?.sections || [])?.map((section: any) => ({
        //         id: section?.id,
        //         name:
        //           category?.name !== section?.name
        //             ? section?.name.replace(category?.name, "")
        //             : section?.name,
        //         originalName: section?.name,
        //       })),
        //     })),
        //   }));
        //   if (data?.meta?.current_page > 1) {
        //     return _.uniqBy([...pre, ...newData], "id");
        //   } else {
        //     return [...newData];
        //   }
        // });

        setPaginateData(data?.meta);

        const tempEticket = data?.data?.ticket_types?.reduce(
          (acc: any, current: any) => {
            if (current?.id === "eticket") {
              // If the current element is "eticket", extract subtypes
              const updatedSubtypes = current?.subtypes?.map((subtype: any) => {
                // Check if the subtype's name is "PDF E-Ticket" and rename it to "E-Ticket"
                if (subtype?.name === "PDF E-Ticket") {
                  return { ...subtype, name: "E-Ticket" };
                }
                return subtype;
              });

              acc.push(...updatedSubtypes);
            } else {
              acc.push({
                ...current,
                name:
                  current?.name.charAt(0).toUpperCase() +
                  current?.name.slice(1),
              });
            }
            return acc;
          },
          []
        );

        const newRenameTicketType = tempEticket?.map((obj: any) => {
          return {
            ...obj,
            name: ticketTypeRename(obj?.id),
          };
        });

        if (!isGodAdmin) {
          if (data?.data?.users?.length > 0) {
            layout.setTeamMembers(data?.data?.users);
            layout.setTransferOwnershipTeamMembers(data?.data?.transfer_users);
          } else {
            layout.setUsers([]);
          }
        }

        layout.setTicketType(newRenameTicketType ? newRenameTicketType : []);
        layout.setDeliveryType(
          reArrangeDeliveryArray(data?.data?.delivery_option, "type")
        );
        layout.setSplitType(splitTypeOptions(data?.data?.split_types));
        layout.setBenefits(data?.data?.benefits);
        layout.setRestrictions(data?.data?.restrictions);
        layout.setCurrencyRates(data?.data?.currency_rates);
        setStartListing(true);

        if (data?.meta?.current_page === 1) {
          const mainCollapseHeader: any = document.querySelectorAll(
            "#table-shrink .title-accordion"
          );
          const mainCollapseHeaderAccordion: any =
            document.getElementById("table-shrink");

          const mainCollapseBtn: any = document.querySelectorAll(
            "#table-shrink .collapse-icon"
          );

          const mainCollapseBodies: any = document.querySelectorAll(
            "#table-shrink .bottom_acc"
          );

          // new Added
          const marketPlace: any = document.querySelectorAll(
            "#table-shrink .inv_acccc"
          );
          const marketPlaceBody: any = document.querySelectorAll(
            "#table-shrink .mrk-btn"
          );

          marketPlaceBody.forEach((header: any) => {
            header.setAttribute("aria-expanded", "false");
            header.setAttribute("data-te-collapse-collapsed", "true");
          });

          marketPlace.forEach((body: any) => {
            body.classList.remove("visible");
            body.removeAttribute("data-te-collapse-show");
            body.classList.add("hidden");
          });

          let firstDiv: any = mainCollapseHeaderAccordion?.childNodes?.[0];
          firstDiv?.childNodes?.[0]?.classList.remove("mb-4");
          firstDiv?.childNodes?.[0]?.classList.add("mb-2.5");

          mainCollapseBodies.forEach((body: any) => {
            body.classList.remove("visible");
            body.removeAttribute("data-te-collapse-show");
            body.classList.add("hidden");
          });

          mainCollapseHeader.forEach((header: any) => {
            header.setAttribute("aria-expanded", "false");
            // header.setAttribute("data-te-collapse-collapsed", "");
          });

          mainCollapseBtn.forEach((header: any) => {
            header.setAttribute("aria-expanded", "false");
            header.setAttribute("data-te-collapse-collapsed", "true");
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isGodAdmin]);

    const loadAllEventListing = async (event: any, count: number) => {
      let i = 0;
      // Previous code for page -
      // let page = event?.meta?.current_page;
      let page = 0;
      let id = event?.id;
      layout.setSelectAllLoader(true);
      layout.setSelectAllLoaderCurrentEvent(id);

      setList((events: any) => {
        return events?.map((event: any, eInnerIndex: any) => {
          if (event?.id === id) {
            return {
              ...event,
              eventRecords: event?.eventRecords?.map((record: any) => {
                return {
                  ...record,
                  bulkLoader: true,
                };
              }),
            };
          } else {
            return event;
          }
        });
      });

      // let userId =
      //   users && Array.isArray(users) && users.length > 0 && _.map(users, "id");
      let orderState = layout.orderState;
      // let publishStatusId =
      //   publishStatus &&
      //   Array.isArray(publishStatus) &&
      //   publishStatus.length > 0 &&
      //   _.map(publishStatus, "id");
      let urlResponses: any = [];

      const getIds = (data: any) =>
        Array.isArray(data) && data.length > 0 ? _.map(data, "id") : null;

      const userFilter = isGodAdmin ? commonFilter?.users : users;

      const publishStatusFilter = isGodAdmin
        ? commonFilter?.publishStatus
        : publishStatus;

      const userId = getIds(userFilter);
      const publishStatusId = getIds(publishStatusFilter);

      for (i; i < count; i++) {
        page = page + 1;
        await getInventoryRecordListing({
          id,
          userId,
          publishStatusId,
          orderState,
          page,
          teamsSelected: isGodAdmin
            ? commonFilter?.teamsSelected
            : teamsSelected,
          per_page: 50,
          newInventoryFilter: isGodAdmin
            ? commonFilter?.newInventoryFilter
            : newInventoryFilter,
          listingIds,
        }).then(async (response: any) => {
          urlResponses.push(response);
        });
      }

      //COMBINE ALL RESPONSE DATA
      let allListings: any = [];
      urlResponses.map((urlResponse: any) => {
        allListings = [...allListings, ...urlResponse.data];
      });
      let mainResponse = {
        ...urlResponses.pop(),
        data: allListings,
      };

      // SET TO LIST
      await setResponsestoList(mainResponse, id, count);

      // UNSET LOADER
      layout.setSelectAllLoader(false);
      layout.setSelectAllLoaderCurrentEvent({});
    };

    const setResponsestoList = async (response: any, id: any, count: any) => {
      let eventIndex = 0;
      setList((events: any) => {
        let finalResult = events?.map((event: any, eIndexIn: number) => {
          if (event.id === id) {
            eventIndex = eIndexIn;

            let tempRecords =
              response?.data?.length > 0
                ? _.uniqBy(
                    [
                      ...(event?.eventRecords?.map((item: any) => {
                        return { ...item, bulkLoader: false };
                      }) || []),
                      ...(response.data?.map((item: any) => {
                        let visibility = visibilityMeterCalculationForInventory(
                          event,
                          item,
                          layout.ticketType
                        );
                        const markinsight =
                          event?.markeInsights?.data?.results?.sections;
                        const avg_price = calculateAvgPriceForProceedValue(
                          item?.section,
                          markinsight,
                          item,
                          event,
                          conversation_rate,
                          isGodAdmin
                        );

                        let ppePartners = [];
                        if (
                          item?.ticket_exchange_prices &&
                          _.size(item?.ticket_exchange_prices) > 0 &&
                          Array.isArray(item?.ticket_exchange_prices)
                        ) {
                          ppePartners = convertPPEPartnersToLocalFormat(
                            item?.ticket_exchange_prices,
                            item.sell_price,
                            target_currency
                          );
                        }
                        return {
                          ...item,
                          benefits: item?.restrictions,
                          requireSelectAll: {},
                          ticketsInHands: item?.in_hand === "no" ? false : true,
                          visibility: visibility,
                          oldVisibility: 0,
                          newRow: true,
                          exchanges: isArray(event?.selected_event_exchanges)
                            ? event?.selected_event_exchanges
                            : [],
                          isLock: true,
                          avg_price: avg_price,
                          markinsight: markinsight,
                          ppePartners: ppePartners,
                        };
                      }) || []),
                    ],
                    "id"
                  )
                : [];

            let final = {
              ...event,
              eventRecords: tempRecords?.map((obj: any) => {
                const avg_price = calculateAvgPriceForProceedValue(
                  obj?.section,
                  obj?.markinsight,
                  obj,
                  event,
                  conversation_rate,
                  isGodAdmin
                );
                return {
                  ...obj,
                  avg_price: avg_price,
                };
              }),
              meta: response?.meta,
              loader: false,
            };
            // delete final?.meta
            if (isGodAdmin) {
              return {
                ...final,
                eventRecords: final?.eventRecords?.map((record: any) => {
                  return {
                    ...record,
                    isLock: record?.selected ? false : true, //lock true for god admin and need to ask dev for it
                  };
                }),
              };
            } else {
              return final;
            }
          } else {
            return event;
          }
        });
        return finalResult;
      });
      await setTimeout(() => {
        setList((events: any) => {
          return events.map((event: any) => {
            if (event.id === id) {
              return {
                ...event,
                eventRecords: event?.eventRecords?.map((item: any) => {
                  return {
                    ...item,
                    newRow: false,
                  };
                }),
              };
            } else {
              return event;
            }
          });
        });
      }, 3000);
    };

    const loadMoreListing = async (event: any, pusher?: any) => {
      let page = pusher ? 1 : event?.meta?.current_page + 1;
      // let userId =
      //   users && Array.isArray(users) && users.length > 0 && _.map(users, "id");
      // if (userSpecific) {
      //   userId = [authUser?.id];
      // }

      let id = event?.id;
      let orderState = layout.orderState;
      // let publishStatusId =
      //   publishStatus &&
      //   Array.isArray(publishStatus) &&
      //   publishStatus.length > 0 &&
      //   _.map(publishStatus, "id");

      if (id) {
        setList((events: any) => {
          let finalResult = events.map((event: any) => {
            if (event.id === id) {
              return {
                ...event,
                loader: true,
                requireSelectAll: {},
              };
            } else {
              return event;
            }
          });
          return finalResult;
        });

        const getIds = (data: any) =>
          Array.isArray(data) && data.length > 0 ? _.map(data, "id") : null;

        const userFilter = isGodAdmin ? commonFilter?.users : users;

        const publishStatusFilter = isGodAdmin
          ? commonFilter?.publishStatus
          : publishStatus;

        const userId = getIds(userFilter);
        const publishStatusId = getIds(publishStatusFilter);
        const response = await getInventoryRecordListing({
          id,
          userId,
          publishStatusId,
          orderState,
          page,
          teamsSelected: isGodAdmin
            ? commonFilter?.teamsSelected
            : teamsSelected,
          per_page: 20,
          newInventoryFilter: isGodAdmin
            ? commonFilter?.newInventoryFilter
            : newInventoryFilter,
          listingIds,
        }).then(async (response: any) => {
          await setResponsestoList(response, id, 0);
          // setTimeout(() => {
          // }, 500);
          return response;
        });

        layout.setPusherDeleteLoader(false);
        return response;
      }
    };

    // Function to get index of dragged Filter item
    const getFilterIndex = (id: any) => {
      let index = layout.draggedItems.findIndex((item: any) => item.id === id);
      return index + 1;
    };

    const getFilterIndexForCheckbox = (id: any) => {
      let index = layout.filterItems.findIndex((item: any) => item.id === id);
      return index;
    };

    // ---------------- UPDATE PRICING API & CHECKING --------------------

    const instantLocalValidation = useCallback(
      (event: any, eventRecord: any) => {
        let fieldErrors: any = [];

        const faceValueCheck =
          Number(eventRecord?.face_value) <= 0 ||
          (typeof eventRecord?.face_value === "object" &&
            Number(eventRecord?.face_value?.value) <= 0);

        const processValueCheck =
          Number(eventRecord?.sell_price) <= 0 ||
          (typeof eventRecord?.sell_price === "object" &&
            Number(eventRecord?.sell_price?.value) <= 0 &&
            !eventRecord?.ppe);

        !eventRecord?.category &&
          event?.categories &&
          Object.keys(event?.categories).length > 0 &&
          (fieldErrors = {
            ...fieldErrors,
            category: "Category value field is required",
          });
        // !eventRecord?.section &&
        //   eventRecord?.category?.sections &&
        //   Object.keys(eventRecord?.category?.sections).length > 0 &&
        //   (fieldErrors = {
        //     ...fieldErrors,
        //     section: "Section value field is required",
        //   });
        !eventRecord?.split_type &&
          (fieldErrors = {
            ...fieldErrors,
            splitType: "Split Type value field is required",
          });
        !eventRecord?.MULTIPLES &&
          eventRecord?.splitType?.id === "MULTIPLES" &&
          (fieldErrors = {
            ...fieldErrors,
            MULTIPLES: "Sell in Multiples Field is required",
          });
        (!eventRecord?.face_value ||
          (typeof eventRecord?.face_value === "object" &&
            eventRecord?.face_value?.value === "")) &&
          (fieldErrors = {
            ...fieldErrors,
            face_value: "Face value field is required",
          });

        //  new
        faceValueCheck &&
          (fieldErrors = {
            ...fieldErrors,
            face_value: "Face value field is required more than zero",
          });

        !eventRecord?.quantity_available &&
          (fieldErrors = {
            ...fieldErrors,
            quantity_available: "Quantity field is required",
          });

        (!eventRecord?.sell_price ||
          (typeof eventRecord?.sell_price === "object" &&
            eventRecord?.sell_price?.value === "")) &&
          !eventRecord?.ppe &&
          (fieldErrors = {
            ...fieldErrors,
            sell_price: "Proceed value field is required",
          });

        // new

        processValueCheck &&
          (fieldErrors = {
            ...fieldErrors,
            sell_price: "Proceed value field is required more than zero --1",
          });

        !eventRecord?.ticket_type &&
          (fieldErrors = {
            ...fieldErrors,
            ticket_type: "Ticket type field is required",
          });
        !eventRecord?.sub_ticket_type?.id &&
          eventRecord?.ticketType?.id === "eticket" &&
          (fieldErrors = {
            ...fieldErrors,
            sub_ticket_type: "Sub Ticket type Field is required",
          });

        (eventRecord?.ticket_type === "paper" ||
          eventRecord?.ticket_type?.id === "paper") &&
          eventRecord?.delivery_options?.length > 0 &&
          eventRecord?.delivery_options?.map((singleDelivery: any) => {
            let price =
              eventRecord?.[singleDelivery?.id] != undefined
                ? Number(eventRecord?.[singleDelivery?.id])
                : Number(singleDelivery?.price);
            (!price || price <= 0) &&
              (fieldErrors = {
                ...fieldErrors,
                [singleDelivery?.id]:
                  layout.deliveryType?.find(
                    (item: any) => singleDelivery?.id === item?.id
                  )?.type + " field is required more than zero",
              });
          });

        // eventRecord?.date_to_ship &&
        //   moment(event?.date).valueOf() <
        //   moment(eventRecord?.date_to_ship).valueOf() &&
        //   (fieldErrors = {
        //     ...fieldErrors,
        //     date_to_ship:
        //       "Date of ship should not be greater than the event date",
        //   });
        return fieldErrors;
      },
      []
    );

    const setLocalValidationToEventRecords = useCallback(async (id: any) => {
      let success = true;
      setList((events: any) => {
        return events.map((event: any) => {
          return {
            ...event,
            eventRecords: Array.isArray(event["eventRecords"])
              ? event["eventRecords"].map((eventRecord: any) => {
                  if (eventRecord?.id === id) {
                    let fieldErrors: any = instantLocalValidation(
                      event,
                      eventRecord
                    );
                    success =
                      Object.keys(fieldErrors).length > 0 ? false : true;
                    return {
                      ...eventRecord,
                      fieldErrors: fieldErrors,
                    };
                  } else {
                    return eventRecord;
                  }
                })
              : event["eventRecords"],
          };
        });
      });
      return success;
    }, []);

    const updateRecordForPrice = async (
      event: any,
      eventRecord: any,
      onlyUpdate: Boolean = true,
      checkedIds: any
    ) => {
      //CHECK LOCAL VALIDATION
      if (!(await setLocalValidationToEventRecords(eventRecord?.id))) {
        return true;
      } else {
        // setUpdateAllFlag(true);
        setList((prev: any) => {
          return prev.map((event2: any) => {
            if (event2?.id === event?.id) {
              return {
                ...event2,
                eventRecords: event2?.eventRecords?.map((item: any) => {
                  if (item?.id === eventRecord?.id) {
                    return {
                      ...item,
                      loader: true,
                    };
                  } else {
                    return item;
                  }
                }),
              };
            }

            return event2;
          });
        });

        setTimeout(() => {
          // setIsLoading(true);
          setLocalValidationToEventRecords(eventRecord?.id);
          eventRecord.status = onlyUpdate
            ? eventRecord.status
            : eventRecord.status === "no"
            ? "yes"
            : "no";
          UpdateInventory(
            setEventRecordPayload(
              event,
              { ...eventRecord, price_per_exchange: 0 } //REMOVE PPE CHANGE
            )
          ).then((response: any) => {
            let responseData = response?.data?.payload?.post_data;
            // setUpdateAllFlag(false);
            responseData
              ? showAlert(
                  response.message,
                  response?.data?.status ? false : true
                )
              : showAlert(Object.values(response?.errors)?.toString(), true);

            updateVisibility(eventRecord);
          });

          // UPDATE VISBILITY STATE
        }, 600);
      }
    };

    /**
     * CONFIRMED BULK ACTION
     */
    const confirmedBulkAction = async (
      { action, checkedIds }: any,
      updatePriceDate: any
    ) => {
      layout.setTableAlert({});
      let checkedIdsArr: any = checkedIds.split(",");
      let isError: any;
      if (action) {
        if (action === "delete") {
          list?.forEach((event: any) => {
            event?.eventRecords?.map((record: any) => {
              if (checkedIdsArr?.includes(record?.id)) {
                record.deleteLoader = true;
              }
            });
          });

          const alertMessage = `${changedSelectedCount(
            list
          )} listing(s) have been scheduled for deletion`;
          showAlert(alertMessage, false);
        }
        setList((events: any) => {
          return events?.map((event: any) => {
            let eventRecords: any = event?.eventRecords;
            if (action === "delete") {
              // sessionStorage.setItem(
              //   "checkedIds",
              //   JSON.stringify(checkedIdsArr)
              // );

              let allSelectedlisting = event?.eventRecords?.filter(
                (record: any) => record.selected
              );
              const countOfQuantityAvailable = allSelectedlisting?.reduce(
                (count: number, record: any) =>
                  count + (record?.quantity_available || 0),
                0
              );
              const countOfStatusYes =
                allSelectedlisting?.filter(
                  (record: any) => record.status === "yes"
                )?.length || 0;

              const countOfStatusNo =
                allSelectedlisting?.filter(
                  (record: any) => record.status === "no"
                )?.length || 0;

              let total_listings = allSelectedlisting?.length
                ? event?.total_listings - allSelectedlisting?.length
                : event?.total_listings;

              let total_qty_available = countOfQuantityAvailable
                ? event?.total_qty_available - countOfQuantityAvailable
                : event?.total_qty_available;

              let total_unpublished_listings = countOfStatusNo
                ? event?.total_unpublished_listings - countOfStatusNo
                : event?.total_unpublished_listings;

              let total_published_listings = countOfStatusYes
                ? event?.total_published_listings - countOfStatusYes
                : event?.total_published_listings;
              return {
                ...event,
                requireSelectAll: {},
                eventRecords: event?.eventRecords?.map((record: any) => {
                  if (record?.selected) {
                    return {
                      ...record,
                      deleteLoader: true,
                      // fadeOut: true,
                    };
                  } else {
                    return record;
                  }
                }),
                total_listings: total_listings,
                total_qty_available: total_qty_available,
                total_unpublished_listings: total_unpublished_listings,
                total_published_listings: total_published_listings,
              };
              // eventRecords = event?.eventRecords?.map((record: any) => {
              //   if (checkedIdsArr?.includes(record?.id)) {
              //     return {
              //       ...record,
              //       deleteLoader: true,
              //     };
              //   } else {
              //     return record;
              //   }
              // });
            } else if (action !== "delete") {
              sessionStorage.removeItem("checkedIds");

              // DISPLAY SCHEDULED MESSAGE - BEFORE PUSHER
              if (action === "publish-all") {
                // isError = checkIsFullfillUploadExist(list);
                // if (isError?.length > 0) {
                //   showAlert(TicketError, true);
                // } else {
                const alertMessage = `${changedSelectedCount(
                  list
                )} listing(s) have been scheduled for publication`;
                showAlert(alertMessage, false);
                // }
              } else if (action === "unpublish-all") {
                const alertMessage = `${changedSelectedCount(
                  list
                )} listing(s) have been scheduled for unpublished`;
                showAlert(alertMessage, false);
              }

              eventRecords = event?.eventRecords?.map((record: any) => {
                // PUBLISH ALL - BULK ACTION
                if (
                  action === "publish-all" &&
                  checkedIds?.includes(record?.id) &&
                  record?.status !== "yes"
                ) {
                  return {
                    ...record,
                    bulkLoader: true,
                    // deleteLoader: true,
                  };
                }
                // UNPUBLISH ALL - BULK ACTION
                else if (
                  action === "unpublish-all" &&
                  checkedIds?.includes(record?.id) &&
                  record?.status === "yes"
                ) {
                  sessionStorage.removeItem("checkedIds");
                  return {
                    ...record,
                    bulkLoader: true,
                    // deleteLoader: true,
                  };
                }
                // UDPATE PRICING - BULK ACTION
                else if (
                  action === "update-pricing" &&
                  checkedIds?.includes(record?.id)
                ) {
                  const increaseOrDecrease =
                    updatePriceDate?.manuplatePrice?.name;
                  const percentageOrAbsolute =
                    updatePriceDate?.percentageOrAbsolute?.name;
                  const updateAmount = Number(
                    updatePriceDate?.updatePricingValue
                  );
                  let proceedPrice: any;

                  if (record?.isInputChange) {
                    if (typeof record?.replacible?.sell_price === "object") {
                      proceedPrice = Number(
                        removeCommaFromNumber(
                          record?.replacible?.sell_price?.value
                        )
                      );
                    } else {
                      proceedPrice = Number(
                        removeCommaFromNumber(record?.replacible?.sell_price)
                      );
                    }
                  } else {
                    if (typeof record?.sell_price === "object") {
                      proceedPrice = Number(
                        removeCommaFromNumber(record?.sell_price?.value)
                      );
                    } else {
                      proceedPrice = Number(
                        removeCommaFromNumber(record?.sell_price)
                      );
                    }
                  }

                  // UPDATING PRICE LOGIC
                  if (increaseOrDecrease === "Increase price") {
                    if (percentageOrAbsolute === "Percentage") {
                      // INCREASE BY PERCENTAGE
                      proceedPrice = proceedPrice * (1 + updateAmount / 100);
                    } else if (percentageOrAbsolute === "Absolute") {
                      // INCREASE BY ABSOLUTE VALUE
                      proceedPrice = proceedPrice + updateAmount;
                    }
                  } else if (increaseOrDecrease === "Decrease price") {
                    if (percentageOrAbsolute === "Percentage") {
                      // DECREASE BY PERCENTAGE
                      proceedPrice = proceedPrice * (1 - updateAmount / 100);
                    } else if (percentageOrAbsolute === "Absolute") {
                      // DECREASE BY ABSOLUTE VALUE
                      proceedPrice = proceedPrice - updateAmount;
                    }
                  }

                  // ENSURE NEGATIVE VALUE DOES NOT APPLY IN PROCEED PRICE
                  proceedPrice = decimalNumber(Math.max(proceedPrice, 0));
                  let final = {
                    ...record,
                    ...(record?.isInputChange && {
                      ...record?.replacible,
                      isInputChange: false,
                    }),
                    // selected: false,
                    sell_price:
                      Number(proceedPrice) > 0
                        ? proceedPrice
                        : record?.sell_price,
                  };

                  if (proceedPrice > 0) {
                    updateRecordForPrice(event, final, true, checkedIdsArr);
                  }
                  sessionStorage.removeItem("checkedIds");
                  return final;
                } else {
                  return {
                    ...record,
                    selected: false,
                  };
                }
              });
            }
            return {
              ...event,
              eventRecords: eventRecords,
            };
          });
        });

        let timeoutId;

        if (action === "delete") {
          // Set the timeout and save the ID
          timeoutId = setTimeout(() => {
            setList((events: any) => {
              return events?.map((event: any) => {
                return {
                  ...event,
                  eventRecords: event?.eventRecords?.filter(
                    (record: any) => !record.deleteLoader
                  ),
                };
              });
            });
          }, 700);
        }
      }

      layout.setTableAlert({});
      action !== "update-pricing" &&
        handleBulk({ checked_ids: checkedIdsArr, action }).then(
          (response: any) => {
            if (Object.keys(response?.data?.errors).length === 0) {
              // showAlert(response?.message, false);
            } else {
              Object.values(response?.data?.errors).forEach((error: any) => {
                showAlert(error, true);
              });
            }
          }
        );
    };

    // UPDATE VISIBILITY CHECK
    const updateVisibility = (data: any) => {
      let vis: any;
      let oldvis: any;
      setList((prev: any) => {
        return prev.map((event: any) => {
          return {
            ...event,
            eventRecords: event.eventRecords?.map((eventRecord: any) => {
              if (eventRecord?.id === data?.id) {
                vis = eventRecord.visibility;
                oldvis = eventRecord.oldVisibility;
                return {
                  ...eventRecord,
                  visibility: 0,
                  selected: false,
                  oldVisibility: 0,
                  loader: false,
                  isInputChange: false,
                  replacible: {},
                  deleteLoader: false,
                };
              } else {
                return eventRecord;
              }
            }),
          };
        });
      });

      setTimeout(() => {
        setList((prev: any) => {
          const updatedEvents = prev.map((event: any) => {
            const updatedEventRecords = event.eventRecords?.map(
              (eventRecord: any) => {
                if (eventRecord?.id === data?.id) {
                  return {
                    ...eventRecord,
                    visibility: vis,
                    oldVisibility: 0,
                    listing_ticket_files: data?.listing_ticket_files,
                    ticketFiles: data?.ticketFiles,
                    mobile_links: data?.mobile_links,
                  };
                } else {
                  return eventRecord;
                }
              }
            );

            return {
              ...event,
              eventRecords: updatedEventRecords,
            };
          });
          return updatedEvents;
        });
      }, 200);
    };

    const handleEticket = (event: any, eventRecord: any) => {
      eventRecord.selected = true;
      layout.setTempEvent(list);
      layout.setETicketDataField((currentData: any) => {
        let eventSelected = list
          .map((event: any) => {
            return {
              ...event,
              eventRecords: Array.isArray(event["eventRecords"])
                ? event["eventRecords"].filter((inEventRecord: any) => {
                    return (
                      (inEventRecord?.selected === true ||
                        eventRecord?.id === inEventRecord?.id) &&
                      (inEventRecord?.ticket_type === "eticket" ||
                        inEventRecord?.ticket_type?.id === "eticket")
                    );
                  })
                : [],
            };
          })
          .filter((event: any) => {
            return (
              Array.isArray(event["eventRecords"]) &&
              event["eventRecords"].length > 0
            );
          });

        return {
          btnEvent:
            eventSelected && eventSelected.length > 0
              ? _.map(eventSelected, "id")
              : _.map(event, "id"),
          isOpen: true,
        };
      });
    };

    // get team options
    useEffect(() => {
      if (teamOptionsData?.length > 0) {
        setTeamsOptions(teamOptionsData);
      }
    }, [teamOptionsData?.length > 0]);

    // grouping team member
    useEffect(() => {
      if (_.size(teamOptionsData) > 0 && isGodAdmin) {
        const options = groupTeamMemberCommon(teamOptionsData);
        setGroupTeamMember(options);
      }
    }, [teamOptionsData, isGodAdmin]);

    // LOAD SALES OPTIONS
    useEffect(() => {
      if (isGodAdmin) {
        if (_.size(teamsSelected) > 0) {
          layout.setTeamMembers(groupTeamMemberCommon(teamsSelected));
          layout.setTransferOwnershipTeamMembers(
            groupTeamMemberCommon(teamsSelected)
          );
        } else {
          layout.setTeamMembers(groupTeamMember);
          layout.setTransferOwnershipTeamMembers(groupTeamMember);
        }
      }
    }, [isGodAdmin, groupTeamMember, teamsSelected]);

    // OPEN EVENT AS IT IS WHILE WE SCROLLING IN VIRTOUS
    useEffect(() => {
      const handleScroll = () => {
        const ids = list?.map((obj: any) => obj?.id);
        ids?.forEach((id: any) => {
          const divElement: any = document.getElementById(`${id}`);
          if (divElement) {
            const eventBar: any =
              divElement.parentElement.parentElement.parentElement
                .parentElement;
            eventBar.classList.remove("stickyEventBar");
          }
        });
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [list]);

    const [secondCounter, setSecondCounter] = useState(1.3);

    useEffect(() => {
      if (isLoading === false) {
        // setTimeout(() => {
        setSecondCounter(1);
        // }, 1050);
      }
    }, [isLoading]);

    // GET TEMPLATES
    useEffect(() => {
      getInstructionLetterTemplateList({
        order_by: "title",
        get_all: true,
      }).then((res: any) => {
        setInstructionLetterTemplates(
          res.data?.instruction_letter_templetes?.filter(
            (activeTemplate: any) => activeTemplate.status === 1
          )
        );
      });
    }, []);

    const handleOnApplyFilter = (action: any, filterData: any) => {
      if (isGodAdmin) {
        layout.setCommonFilter((pre: any) => {
          return {
            ...pre,
            newFilter: filterData,
          };
        });
      }
    };

    useEffect(() => {
      if (coreLayout?.globalInventory?.team_id) {
        setTeamsSelected([
          teamsOptions?.find(
            (item: any) => item?.id === coreLayout?.globalInventory?.team_id
          ),
        ]);
        handleOnApplyFilter("apply", {
          ...commonFilter,
          teamsSelected: [
            teamsOptions?.find(
              (item: any) => item?.id === coreLayout?.globalInventory?.team_id
            ),
          ],
        });
      }
    }, [teamsOptions]);

    return (
      <>
        <div className="visually-hidden">
          <IKTSVG svgClassName="fill-indigo-500 h-4 w-4 center" path={PPE} />
          <IKTSVG svgClassName="fill-indigo-500 h-4 w-4 center" path={Upload} />
          <IKTSVG svgClassName="fill-indigo-500 h-4 w-4 center" path={Check} />
          <IKTSVG
            svgClassName="fill-indigo-500 h-4 w-4 center"
            path={HaxPublishGray}
          />
          <IKTSVG
            svgClassName="fill-indigo-500 h-4 w-4 center"
            path={Refresh}
          />
          <KTSVG
            className="flex items-center justify-center fill-violet-500 transition animate-spin"
            path="other_icons/process.svg"
          />
          <IKTSVG
            svgClassName="fill-indigo-500 h-4 w-4 center"
            path={AngleLeft}
          />
          <IKTSVG
            svgClassName="fill-indigo-500 h-4 w-4 center"
            path={AngleRight}
          />
          <IKTSVG svgClassName="fill-indigo-500 h-4 w-4 center" path={Cross} />
          <IKTSVG
            svgClassName="fill-indigo-500 h-4 w-4 center"
            path={SelectAngle}
          />
        </div>
        <PusherSetup
          eventListData={{
            debouncedSearch,
            pagination,
            publishStatus,
            users,
            dateRange,
            inventoryFilterCopy,
          }}
          setListCountingInfo={setListCountingInfo}
          setList={setList}
          loadMoreListing={loadMoreListing}
          countRefetch={countRefetch}
        />

        <InventoryFilter
          list={list}
          isTableDisabled={
            isTableDisabled ||
            layout.isDeleteActive ||
            layout.isBulkActionLoader ||
            layout.selectAllConfirmation ||
            layout.customLoader
          }
          isAddInventory={isAddInventory}
          listCountingInfo={listCountingInfo}
          publishStatus={publishStatus}
          setPublishStatus={setPublishStatus}
          algoliaData={algoliaData}
          algoliaOptions={algoliaOptions}
          algoliaLoading={algoliaLoading}
          setPagination={setPagination}
          confirmedBulkAction={confirmedBulkAction}
          setTeamsSelected={setTeamsSelected}
          setInventoryFilterHandle={setInventoryFilterHandle}
          setCustomLoading={setCustomLoading}
          dateRange={dateRange}
          inventoryFilterHandle={inventoryFilterHandle}
          users={users}
          startListing={startListing}
          paginateData={paginateData}
          setQuery={setQuery}
          setEventId={setEventId}
          setInputChange={setInputChange}
          setDateRange={setDateRange}
          userSpecific={userSpecific}
          teamsSelected={teamsSelected}
          query={query}
          debouncedSearch={debouncedSearch}
          getFilterIndex={getFilterIndex}
          getFilterIndexForCheckbox={getFilterIndexForCheckbox}
          onSearch={onSearch}
          handleInputChange={handleInputChange}
          inputChange={inputChange}
          handleKeyDown={handleKeyDown}
          isGodAdmin={isGodAdmin}
          teamsOptions={teamsOptions}
          isLoading={countLoading}
          setList={setList}
          loadMoreListing={loadMoreListing}
          handleOnApplyFilter={handleOnApplyFilter}
        />
        {/* Event Loop */}
        <div
          className="accordion mb-4 font-medium transition-all md:px-5 max-lg:pb-20"
          id={`EventAccordion`}
        >
          {/* before lazy load */}
          <div id="table-shrink">
            {isLoading || isFetching || customLoading
              ? Array.from({ length: 10 }, (v, i) => (
                  <div
                    key={i}
                    className={`accordion font-medium !mb-[0.625rem] `}
                  >
                    <div
                      key={i}
                      className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                      style={{ height: "40px" }}
                    ></div>
                  </div>
                ))
              : list &&
                list?.length > 0 &&
                list?.map((inlist: any, eIndex: number) => {
                  return (
                    <React.Fragment key={eIndex}>
                      <EventTable
                        isTableDisabled={isTableDisabled}
                        layout={layout}
                        list={list}
                        event={inlist}
                        eIndex={eIndex}
                        setList={setList}
                        users={users}
                        publishStatus={publishStatus}
                        loadMoreListing={loadMoreListing}
                        loadAllEventListing={loadAllEventListing}
                        updateVisibility={updateVisibility}
                        tableRefSide={tableRefSide}
                        editData={editData}
                        setEditData={setEditData}
                        setIsOpenTicketPopUp={setIsOpenTicketPopUp}
                        matchIndexView={matchIndexView}
                        teamsSelected={teamsSelected}
                        query={inventoryFilterCopy?.debouncedSearch}
                        currentRecordId={currentRecordId}
                        setCurrentRecordId={setCurrentRecordId}
                        SortingCurrentID={SortingCurrentID}
                        setSortingCurrentID={setSortingCurrentID}
                        setIsOpenListingQualityPopup={
                          setIsOpenListingQualityPopup
                        }
                        setEditView={setEditView}
                      />
                    </React.Fragment>
                  );
                })}
          </div>
          {list?.length === 0 &&
            !isLoading &&
            !isFetching &&
            !customLoading && <NoDataComponent isPadding={true} />}

          {/* pagination?.page === 1 && shimmerLoader */}
          {(isLoading || isFetching) && (
            <ProgressBardLoader
              // LoadingText={`Loading your ${LISTINGS_UTILS?.myListings.name} data`}
              LoadingText={`Loading your listings data`}
              secondCounter={secondCounter}
            />
          )}

          {/* Edit PopUp */}
          {editData?.status && (
            <EditTableFieldSideBar
              data={editData}
              tableRefSide={tableRefSide}
              closeHandle={() => {
                setEditData({ status: false });
                setMatchIndexView({});
              }}
              handleRecord={(mData: any, IData: any) => {
                handleEticket(mData, IData);
              }}
              updateVisibility={updateVisibility}
              setIsOpenTicketPopUp={setIsOpenTicketPopUp}
              list={list}
              setList={setList}
            />
          )}

          {/* <ETicketUpload /> */}
          {isOpenTicketPopUp && (
            <ETicketUpload
              popUpOpen={isOpenTicketPopUp}
              closeHandle={() => {
                setIsOpenTicketPopUp(false);
                layout.setIsEditPopUpActive(false);
              }}
              list={list}
              setList={setList}
              instructionLetterTemplates={instructionLetterTemplates}
            />
          )}
        </div>

        {isOpenListingQualityPopup && (
          <ListingQualityPopup
            isOpen={isOpenListingQualityPopup}
            onClose={setIsOpenListingQualityPopup}
          />
        )}

        <Footer
          list={list}
          setList={setList}
          publishStatus={publishStatus}
          setPublishStatus={setPublishStatus}
          currentRecordId={currentRecordId}
          setCurrentRecordId={setCurrentRecordId}
          query={inventoryFilterCopy?.debouncedSearch}
          loadMoreListing={loadMoreListing}
          isOpenTicketPopUp={isOpenTicketPopUp}
          debouncedSearch={debouncedSearch}
          pagination={pagination}
          dateRange={dateRange}
          inventoryFilterCopy={inventoryFilterCopy}
          teamsSelected={teamsSelected}
          newInventoryFilter={newInventoryFilter}
          getFilteredValue={getFilteredValue}
        />

        {isMobile && (
          <EditInventoryMobileView
            setEditView={setEditView}
            editView={editView}
            setList={setList}
          />
        )}

        {layout.logsData && (
          <LogDetailsPopUp
            isDisplay={false}
            logsData={layout.logsData}
            setLogsData={layout.setLogsData}
            request={getInventoryLogListing(layout.logsData)}
          />
        )}

        {layout?.isLoader && <ProgressBardLoader secondCounter={0.1} />}
      </>
    );
  }
);

export default Inventory;
