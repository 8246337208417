import { useContext, useEffect, useState } from "react";
import { TxAccountContext } from "../core/TxAccountProvider";

const BulkExcludeOrdersFilter = ({ loading, handleOrderId }: any) => {

  const layout = useContext(TxAccountContext);
  const orderFilters = layout.orderFilters;

  useEffect(() => {
    setExcludeBulkOrders((data: any) => {
      return {
        ...data,
        removeOrder: orderFilters?.order_ids ? orderFilters?.order_ids?.join(", ") : "",
      };
    })
  }, [orderFilters?.order_ids])

  let excludeBulkOrdersInitial = {
    removeOrder: "",
    order_ids: [],
  };
  const [excludeBulkOrders, setExcludeBulkOrders] = useState(
    excludeBulkOrdersInitial
  );

  // ON ENTER -  EXCLUDE ORDER
  const handleExcludeOrder = () => {
    let enteredIds: any = excludeBulkOrders?.removeOrder ? excludeBulkOrders?.removeOrder
      .split(/\s*,\s*|\s+/)
      .map((id: any) => id.trim()) : [];

    let event = {
      target: {
        name: "order_ids",
        value: enteredIds,
      },
    };
    handleOrderId(event);
  };

  return (
    <div className="exclude_input relative " id={`filters_block1`}>
      <form className="flex flex-wrap flex-col  border-b gap-y-2.5 py-4 px-[.9375rem] bg-white">
        <div className={loading && "shimmer-effect"}>
          <input
            name="removeOrder"
            id="removeOrder"
            value={excludeBulkOrders?.removeOrder}
            placeholder="Enter order Ids"
            className="w-full border rounded text-sm14 leading-5 px-2.5 py-1 placeholder:text-gray-400 outline-none"
            onChange={(data: any) =>
              setExcludeBulkOrders((current: any) => {
                return {
                  ...current,
                  removeOrder: data.target.value,
                };
              })
            }
            onKeyDown={(data: any) => {
              if (data?.key === "Enter") {
                data?.preventDefault();
                handleExcludeOrder();
              }
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default BulkExcludeOrdersFilter;
