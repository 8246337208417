/* eslint-disable jsx-a11y/role-supports-aria-props */

import clsx from "clsx";
import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import { SYMBOLS } from "helpers/const";
import { decimalNumber } from "helpers/Functions";
import moment from "moment";
import { createInvoiceNumber } from "pages/subscribe/personal_details/core/functions";
import { TxAccountContext } from "pages/tx_account/core/TxAccountProvider";
import HeaderCell from "pages/tx_account/Table/component/HeaderCell";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useContext, useEffect } from "react";
import eyeSVG from "../../../../../assets/media/icons/other_icons/Eye.svg";
import angleLeftSVG from "../../../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../../../assets/media/icons/standard_icons/angle_right.svg";
import DraftInvoiceItemContent from "../DraftInvoiceItemContent";

const columnHeaders = [
  { title: "Team", postKey: "name", width: "min-w-[7.5rem]", padding: "pl-4" },
  { title: "Team member", postKey: "username", width: "min-w-[7.5rem]" },
  { title: "Marketplace", postKey: "exchange_title", width: "min-w-[7.5rem]" },
  { title: "Order ID", postKey: "order_id", width: "min-w-[7.5rem]" },
  { title: "Marketplace ID", postKey: "order_id", width: "min-w-[7.5rem]" },
  { title: "Order date", postKey: "order_date_time", width: "min-w-[7.5rem]" },
  {
    title: "Proceed price",
    postKey: "transaction_value",
    width: "min-w-[7.5rem]",
  },
  {
    title: "Marketplace value",
    postKey: "transaction_value",
    width: "min-w-[7.5rem]",
  },
  {
    title: "Seller payout",
    postKey: "tixstock_revenue",
    width: "min-w-[7.5rem]",
  },
  {
    title: "Tixstock revenue",
    postKey: "event_description",
    width: "min-w-[7.5rem]",
  },
  { title: "Event", postKey: "event_description", width: "min-w-[14.375rem]" },
  { title: "Venue", postKey: "event_venue", width: "min-w-[10.625rem]" },
  { title: "Event date", postKey: "event_date", width: "min-w-[7.5rem]" },
  {
    title: "Ticket details",
    postKey: "ticket_section",
    width: "min-w-[10.625rem]",
  },
  { title: "Qty", postKey: "ticket_quantity", width: "min-w-[3.4375rem]" },
  { title: "Ticket type", postKey: "ticket_type", width: "min-w-[7.5rem]" },
  { title: "Order status", postKey: "order_status", width: "min-w-[7.5rem]" },
  { title: "Internal order status", postKey: "paid", width: "min-w-[7.5rem]" },
  { title: "Payment status", postKey: "paid", width: "min-w-[7.5rem]" },
];

const ScrollButton = ({
  id,
  direction,
  onClick,
}: {
  id: string;
  direction: "left" | "right";
  onClick: () => void;
}) => (
  <button
    className={clsx("group px-[.5625rem] py-2 transition", {
      "rounded-l": direction === "left",
      "rounded-r": direction === "right",
    })}
    type="button"
    id={id}
    onClick={onClick}
  >
    <IKTSVG
      className="flex justify-center fill-violet-500 group-hover:fill-indigo-500 items-center transition"
      path={direction === "left" ? angleLeftSVG : angleRightSVG}
      svgClassName="w-1.5 h-[.5625rem]"
    />
  </button>
);

const GroupInvoiceItemContent = ({
  item,
  index,
  viewGroup,
  setViewGroup,
}: any) => {
  const { setInvoicesViewData } = useContext(TxAccountContext);
  const { invoice_details } = item;

  const scroll = (scrollOffset: any, id: any) => {
    let replaceTable: any = document.getElementById(id)?.parentElement;
    replaceTable && (replaceTable.scrollLeft += scrollOffset);
  };

  // HANDLE VIEW CLICK
  const handleView = (e: any, item: any) => {
    if (e?.target?.closest(`#eye-group-by-${index}`)) {
      e?.preventDefault();
      return;
    }

    setInvoicesViewData((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        orders: prevState.data?.orders?.map((order: any) => ({
          ...order,
          isOpen:
            order.order_id === item.order_id ? !order.isOpen : order.isOpen,
        })),
      },
    }));
  };

  // SCROLL BTN DISABLED
  useEffect(() => {
    const scrollContainer = document.querySelector(
      `#table-of-group-invoice-${index}`
    );
    if (!scrollContainer) return;

    // Select buttons for this specific index
    const leftArrow = document.querySelector(`#leftArrow-${index}`);
    const rightArrow = document.querySelector(`#rightArrow-${index}`);

    if (!leftArrow || !rightArrow) return;

    const handleScroll = () => {
      const isAtStart = scrollContainer.scrollLeft <= 0;
      const isAtEnd =
        Math.abs(
          scrollContainer.scrollLeft +
            scrollContainer.clientWidth -
            scrollContainer.scrollWidth
        ) <= 2;

      // Update left arrow state
      leftArrow.classList.toggle("opacity-50", isAtStart);
      leftArrow.classList.toggle("pointer-events-none", isAtStart);

      // Update right arrow state
      rightArrow.classList.toggle("opacity-50", isAtEnd);
      rightArrow.classList.toggle("pointer-events-none", isAtEnd);
    };

    handleScroll();

    // Attach event listener
    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [index, item?.isOpen]);

  return (
    <>
      <div className="border-b text-left text-xs w-full" key={index}>
        <div className="[&:not([data-te-collapse-collapsed])]:bg-indigo-500/5 accordion-cont group">
          <table className="w-full">
            <tbody>
              <tr
                className={clsx({
                  "bg-indigo-500/5":
                    viewGroup?.data?.order_id === item?.order_id,
                })}
                onClick={(e: any) => {
                  handleView(e, item);
                }}
              >
                <td
                  style={{ width: "6.875rem" }}
                  className="font-medium w-[6.875rem] max-w-[6.875rem] min-w-[6.875rem] md:w-[8.125rem] md:max-w-[8.125rem] md:min-w-[8.125rem]  pl-4 pr-1.5 py-3 whitespace-nowrap group-hover:bg-indigo-500 group-hover:bg-opacity-[0.07] truncate"
                  title={createInvoiceNumber(invoice_details)}
                >
                  {createInvoiceNumber(invoice_details)}
                </td>
                <td
                  style={{ width: "6.25rem" }}
                  className="font-medium w-[6.25rem] max-w-[6.25rem] min-w-[6.25rem] px-1.5 py-3 group-hover:bg-indigo-500 group-hover:bg-opacity-[0.07]"
                >
                  <span className="text-ellipsis w-[6.625rem] block overflow-hidden whitespace-nowrap">
                    {invoice_details?.payment_date
                      ? moment(invoice_details?.payment_date).format(
                          "DD MMM YY"
                        )
                      : "-"}
                  </span>
                </td>
                <td
                  style={{ width: "3.75rem" }}
                  className="font-medium w-[7.75rem] max-w-[7.75rem] min-w-[7.75rem] px-1.5 py-3 group-hover:bg-indigo-500 group-hover:bg-opacity-[0.07]"
                  title={invoice_details?.status}
                >
                  <span className="text-ellipsis w-[6.625rem] block overflow-hidden whitespace-nowrap truncate">
                    {invoice_details?.status ?? "-"}
                  </span>
                </td>
                <td className="font-medium w-auto px-1.5 py-3 whitespace-nowrap group-hover:bg-indigo-500 group-hover:bg-opacity-[0.07]">
                  {SYMBOLS[invoice_details?.currency]}
                  {decimalNumber(invoice_details?.total_amount ?? "0")}
                </td>

                <td
                  style={{ width: "5rem" }}
                  className="sticky right-0 left-shad before:border-r cursor-pointer font-medium group max-w-20 min-w-20 ml-auto group-hover:bg-indigo-500 group-hover:bg-opacity-[0.07]"
                >
                  <div className="flex justify-between">
                    <span
                      id={`eye-group-by-${index}`}
                      onClick={(e: any) => {
                        setViewGroup((current: any) => {
                          return {
                            ...current,
                            data: {
                              ...current?.data,
                              order_id: item?.order_id,
                              invoice_details: invoice_details,
                            },
                            show: false,
                          };
                        });
                      }}
                    >
                      <IKTSVG
                        className={clsx(
                          "flex items-center justify-center w-10 h-10 p-2 fill-violet-500 hover:fill-indigo-500 transition shrink-0 duration-200 ease-in-out motion-reduce:transition-none",
                          viewGroup?.data?.order_id === item?.order_id &&
                            "!fill-indigo-500"
                        )}
                        path={eyeSVG}
                        svgClassName={`w-4 h-4 `}
                        dataTooltipID={`eye-group-by-${index}-tooltip`}
                      />
                      <TooltipPortal
                        id={`eye-group-by-${index}-tooltip`}
                        content={
                          viewGroup?.data?.order_id === item?.order_id
                            ? ""
                            : "View"
                        }
                        className="max-md:hidden shadow text-center text-xs !bg-white !opacity-100 !px-1.5 !py-0.5 cursor-default font-medium max-w-[9rem] z-[45]"
                      />
                    </span>

                    <span data-tooltip-id={`arrow-group-by-${index}-tooltip`}>
                      <KTSVG
                        className={`flex items-center justify-center w-10 h-10 p-2 border-l fill-violet-500 hover:fill-indigo-500 transition shrink-0    duration-200 ease-in-out motion-reduce:transition-none `}
                        path="standard_icons/arrowDown.svg"
                        svgClassName={`w-2 ${
                          item?.isOpen ? "rotate-[-180deg]" : "rotate-0"
                        }`}
                      />
                    </span>
                    <TooltipPortal
                      id={`arrow-group-by-${index}-tooltip`}
                      content={item?.isOpen ? "Collapse" : "Expand"}
                      className="max-md:hidden shadow text-center text-xs !bg-white !opacity-100 !px-1.5 !py-0.5 cursor-default font-medium max-w-[9rem] z-[45]"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* ACCORDION BODY */}
        <div
          className="overflow-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-violet-800"
          id={`table-of-group-invoice-${index}`}
        >
          <table
            id={`collapseTwo5-${index}`}
            className={` ${
              item?.isOpen ? "!visible" : "hidden"
            } accordion-collapse bg-gray-100/50 text-gray-500 border-t`}
            style={{
              maxHeight: item?.isOpen ? "500px" : "0px",
              overflow: "scroll",
            }}
          >
            <tbody>
              <tr>
                <td colSpan={100}>
                  <table>
                    <thead>
                      <tr className="bg-white">
                        {columnHeaders?.map(
                          ({ title, postKey, width, padding }) => (
                            <HeaderCell
                              key={title}
                              title={title}
                              postKey={postKey}
                              width={width}
                              padding={padding || ""}
                              arrow={false}
                            />
                          )
                        )}

                        {/* SCROLL BUTTON */}
                        <th className="p-0 w-[5.0625rem] max-md:hidden md:sticky ml-auto relative right-0">
                          <div
                            className="flex bg-white border-l h-[2.5rem] justify-end left-shad px-4 py-[.4375rem]"
                            id="left-shad-list"
                          >
                            <div className="flex rounded arrow_buttons">
                              <ScrollButton
                                id={`leftArrow-${index}`}
                                direction="left"
                                onClick={() =>
                                  scroll(-120, `collapseTwo5-${index}`)
                                }
                              />
                              <ScrollButton
                                id={`rightArrow-${index}`}
                                direction="right"
                                onClick={() =>
                                  scroll(120, `collapseTwo5-${index}`)
                                }
                              />
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <DraftInvoiceItemContent sIndex={index} list={item} />
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GroupInvoiceItemContent;
