import DropDownField from "components/formComponent/DropDownField";
import { XERO_CONTACT_OPTIONS } from "helpers/const";
import { useState } from "react";
import downloadIcon from "../../../assets/media/icons/other_icons/download-icon.svg";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import PlusSVG from "../../../assets/media/icons/other_icons/plus.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";

interface CommissionPDFViewInterface {
  isLoading?: boolean;
  isDownloadBtn?: boolean;
  fileName: string;
  draftKey: any;
  downloadPDF: any;
  isGenerate: boolean;
  saveCommissionInvoice: any;
  isCustomLoading?: boolean;
  loaderMessage?: string;
  title?: string;
  children?: React.ReactNode;
  xeroNeeded?: boolean;
}

const CommissionPDFView: React.FC<CommissionPDFViewInterface> = ({
  isLoading = false,
  fileName = "tixstock-invoice-000000000-draft.pdf",
  draftKey,
  isDownloadBtn = false,
  isGenerate = false,
  downloadPDF,
  title = "View invoice",
  loaderMessage = "Saving commission invoice",
  isCustomLoading = false,
  saveCommissionInvoice = () => {},
  children = <></>,
  xeroNeeded = false,
}: any) => {
  const [xeroContact, setXeroContact] = useState(XERO_CONTACT_OPTIONS[0]);
  const [commissionCreate, setCommissionCreate] = useState(false);
  return (
    <div
      className={`invoice_list p-5 pt-3.5 bg-gray-100/50 mt-[1.875rem] rounded ${
        isCustomLoading && "shimmer-effect"
      }`}
    >
      <h5 className="text-sm14 font-semibold leading-4 mb-[1.125rem]">
        {title}
      </h5>

      {/* LOAD  */}
      {isLoading ? (
        <div className="flex bg-white justify-center anim-fade invoice-loader items-center loader pb-4 pt-6">
          <div className="text-center w-full inner_loader max-w-[14rem]">
            <div className="h-2 rounded loader-stripe mb-3.5"></div>
            <span className="text-sm14 block leading-4">{loaderMessage}</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap list_wrap anim-fade gap-2.5">
          <div className="flex flex-wrap bg-white border justify-between rounded text-sm12 w-full px-2.5 py-1.5 single-invoice">
            {/* FILE NAME */}
            <span className="flex flex-1 leading-4">{fileName}</span>

            {isDownloadBtn && (
              // DOWNLOAD BTN
              <button
                type="button"
                data-tooltip-id={`pdf-download-account`}
                onClick={() => downloadPDF(draftKey, "download", fileName)}
              >
                <TooltipPortal
                  id={`pdf-download-account`}
                  content={"Download PDF"}
                  className="shadow text-center text-xs !bg-white !opacity-100 !px-1.5 !py-0.5 cursor-default font-medium max-w-[9rem] z-[45]"
                />
                <IKTSVG
                  className="fill-violet-500 hover:fill-indigo-500 transition"
                  path={downloadIcon}
                  svgClassName="w-[.9375rem]"
                />
              </button>
            )}

            {/* VIEW BUTTON OR SAVE OPTION*/}
            {isGenerate ? (
              <button
                className="ml-2"
                type="button"
                data-tooltip-id={`pdf-view-account-save`}
                onClick={(e) =>
                  !xeroNeeded
                    ? saveCommissionInvoice(e)
                    : setCommissionCreate(true)
                }
              >
                <TooltipPortal
                  id={`pdf-view-account-save`}
                  content={"Save"}
                  className="shadow text-center text-xs !bg-white !opacity-100 !px-1.5 !py-0.5 cursor-default font-medium max-w-[9rem] z-[45]"
                />
                <IKTSVG
                  className="fill-violet-500 hover:fill-indigo-500 transition"
                  path={PlusSVG}
                  svgClassName="w-[.9375rem] h-2.5"
                />
              </button>
            ) : (
              <button
                className="ml-2"
                type="button"
                data-tooltip-id={`pdf-view-account-commission`}
                onClick={() => downloadPDF(draftKey, "view")}
              >
                <TooltipPortal
                  id={`pdf-view-account-commission`}
                  content={"View"}
                  className="shadow text-center text-xs !bg-white !opacity-100 !px-1.5 !py-0.5 cursor-default font-medium max-w-[9rem] z-[45]"
                />
                <IKTSVG
                  className="fill-violet-500 hover:fill-indigo-500 transition"
                  path={eyeSVG}
                  svgClassName="w-[.9375rem] h-2.5"
                />
              </button>
            )}
          </div>
          {commissionCreate && (
            <div className="flex gap-5 pt-4">
              <DropDownField
                options={XERO_CONTACT_OPTIONS}
                name="xerocontact"
                placeholder="Xero contact"
                value={xeroContact}
                handleOnChange={(data: any) =>
                  setXeroContact(data.target.value)
                }
                isClearable={false}
                menuPlacement="top"
              />

              <div className="flex">
                <button
                  onClick={(e) => setCommissionCreate(false)}
                  type="button"
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 mr-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    // confirm();
                    setCommissionCreate(false);
                    saveCommissionInvoice(e, xeroContact);
                  }}
                  className={` text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5`}
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CommissionPDFView;
