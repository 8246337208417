import { PAYOUT_TAB } from "helpers/const";

export const tabConditions = (
  isTabActive: string,
  filterType: string = "filter"
) => {
  const tabMapping: any = {
    Orders: filterType === "filter" ? "Orders" : "OrdersColumn",
    Invoices: filterType === "filter" ? "Invoices" : "InvoicesColumn",
    [PAYOUT_TAB]: filterType === "filter" ? PAYOUT_TAB : "payoutColumn",
    "Seller wallets":
      filterType === "filter" ? "Seller wallets" : "sellerwalletColumn",
    "Bank accounts":
      filterType === "filter" ? "Bank accounts" : "bankAccountsColumn",
    "Withdraw requests":
      filterType === "filter" ? "Withdraw requests" : "withdrawRequestColumn",
    notification:
      filterType === "filter" ? "Notification" : "notificationColumn",
    activity: filterType === "filter" ? "Activities" : "activitiesColumn",
  };

  return tabMapping[isTabActive] || "";
};

export const downloadFile = ({ data, fileName, fileType }: any) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
  return false;
};

export const createInvoiceNumber = (data: any): string => {
  return `${data?.prefix ?? ""}${data?.invoice_number ?? ""}${data?.invoice_number && data?.suffix ? "_" : ""}${
    data?.suffix ?? ""
  }`;
};
