import moment from "moment";
import React from "react";
import {
  decimalNumber,
  ticketTypeRenameForSalesReport,
} from "../../../../helpers/Functions";
import { SYMBOLS } from "../../../../helpers/const";
import DataCell from "../../Table/component/DataCell";

const DraftInvoiceItemContent = ({ list, sIndex }: any) => {
  return (
    <React.Fragment key={sIndex}>
      {/* TEAM */}
      <DataCell
        value={list?.team_name}
        classname="block"
        padding="pl-4"
        width="w-[5.875rem]"
      />
      {/* TEAM MEMBER */}
      {/* <DataCell value={list?.user_name} classname="block" width="w-[6.75rem]" /> */}
      <DataCell
        value={list?.user_name}
        classname="block"
        width="w-[5.875rem]"
      />
      {/* MARKETPLACE */}
      <DataCell
        value={list?.marketplace}
        classname="block"
        width="w-[6.75rem]"
      />
      {/* ORDER ID */}
      <DataCell value={list?.order_id} classname="block" width="w-[6.75rem]" />
      {/* MARKETPLACE ID*/}
      <DataCell
        value={list?.external_order_id}
        classname="block"
        width="w-[6.75rem]"
        noCapitalize={"yes"}
      />
      {/* ORDER DATE */}
      <DataCell
        value={
          list?.order_date_time
            ? moment(list?.order_date_time).format("DD/MM/YYYY")
            : "-"
        }
        classname="block"
        width="w-[6.75rem]"
      />
      <DataCell
        value={`${SYMBOLS?.[list?.ticket_currency]}${decimalNumber(
          list?.transaction_value
        )}`}
        classname="block"
        width="w-[6.75rem]"
      />
      <DataCell
        value={`${SYMBOLS?.[list?.marketplace_ticket_currency]}${decimalNumber(
          list?.marketplace_selling_price
        )}`}
        classname="block"
        width="w-[6.75rem]"
      />
      <DataCell
        value={`${SYMBOLS?.[list?.settlement_currency]}${decimalNumber(
          list?.settlement_value
        )}`}
        classname="block"
        width="w-[6.75rem]"
      />
      <DataCell
        value={`${SYMBOLS?.[list?.settlement_currency]}${decimalNumber(
          list?.tixstock_revenue
        )}`}
        classname="block"
        width="w-[6.75rem]"
      />
      <DataCell
        value={list?.event_description}
        width="w-[6.75rem]"
        noCapitalize={"yes"}
      />
      <DataCell value={list?.venue} width="w-[13.625rem]" />
      <DataCell
        value={moment(list?.event_date).format("DD/MM/YYYY")}
        width="w-[9.875rem]"
      />
      <DataCell value={list?.seating_location} width="w-[6.75rem]" />{" "}
      <DataCell
        value={
          list?.ticket_quantity < 10
            ? list?.ticket_quantity.toString().padStart(2, 0)
            : list?.ticket_quantity
        }
        width="w-[9.875rem]"
      />
      <DataCell
        value={ticketTypeRenameForSalesReport(
          list?.ticket_type,
          list?.sub_ticket_type
        )}
        width="w-[7.5rem]"
        noCapitalize={"yes"}
      />
      <DataCell value={list?.order_status} width="w-[6.75rem]" />
      <DataCell
        value={list?.internal_order_status ? list?.internal_order_status : "-"}
        width="w-[6.75rem]"
      />
      <DataCell
        value={list?.paid === "false" ? "Unpaid" : "Paid"}
        width="w-[6.75rem]"
      />
      {/* <DataCell value={list?.marketplace} width="w-[6.75rem]" /> */}
      <td className="group sticky max-md:hidden right-0 ml-auto p-0 no_white_bg !bg-transparent ">
        <div className="!justify-end">
          <div className="left-shad  h-full" id="left-shaf"></div>
        </div>
      </td>
    </React.Fragment>
  );
};

export default DraftInvoiceItemContent;
