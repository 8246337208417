import { SYMBOLS } from "helpers/const";
import { addCommaInNumber } from "helpers/Functions";
import { size } from "lodash";
import moment from "moment";
import { useCallback } from "react";

export const RefetchPaymentsTable = ({
  data = [],
  currency = "",
  loader = false,
}: any) => {
  const getPaidAmount = useCallback(
    (data: any) => {
      if (data.paid_amount)
        return `${SYMBOLS[currency]}${addCommaInNumber(data.paid_amount)}`;

      return "";
    },
    [currency]
  );

  const getPaymentDate = useCallback((data: any) => {
    if (data.payment_date)
      return moment(data?.payment_date).format("ddd, DD MMM YYYY");

    return "-";
  }, []);

  if (loader)
    return (
      <div className="block_wrap w-full md:w-1/2 ">
        {Array.from({ length: 2 }, (v, i) => (
          <div
            className="accordion font-medium  px-2.5 mb-2.5 last:mb-0"
            key={i}
          >
            <div
              className={`  ${loader && "shimmer-effect"} `}
              style={{ height: "1.25rem" }}
            ></div>
          </div>
        ))}
      </div>
    );

  if (size(data) === 0) return <div></div>;

  return (
    <div className="block_wrap w-full md:w-1/2 px-2.5">
      <div
        className="w-full max-h-[7.5rem] border rounded outline-black  overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full 
        border-r"
      >
        <table className={`w-full`} id="txtradetracked">
          <thead className="sticky top-0 bg-white text-gray-400 border-b border-gray-100 z-10">
            <tr>
              <th className="p-1.5 pl-5 text-left text-xs font-medium w-[6.15rem] min-w-[6.15rem] max-w-[6.15rem] truncate">
                Paid amount
              </th>
              <th className="p-1.5 text-left text-xs font-medium w-auto">
                Payment date
              </th>
            </tr>
          </thead>

          <tbody>
            {data?.map((obj: any, index: any) => (
              <tr
                className="border-b last:border-none border-gray-100 hover:bg-indigo-500/5 transition cursor-pointer"
                key={index}
              >
                <td
                  className="w-[6.15rem] min-w-[6.15rem] max-w-[6.15rem] truncate pl-5 p-1.5 text-xs text-gray-900 font-medium"
                  title={getPaidAmount(obj)}
                >
                  {getPaidAmount(obj)}
                </td>
                <td className="w-auto ellips-text p-1.5 text-xs text-gray-900 font-medium">
                  <p
                    className="text-xs text-gray-900 w-full truncate font-medium line-clamp-1"
                    title={getPaymentDate(obj)}
                  >
                    {getPaymentDate(obj)}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
