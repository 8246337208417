import moment from "moment";
import React from "react";
import { addCommaInNumber } from "../../../helpers/Functions";

interface PopupDetailBoxInterface {
  label?: string;
  headerValue?: string;
  countValue?: string | number;
  labelValue?: string | number;
  isDisabled?: boolean;
  disabledColor?: string;
  successValues?: any;
  loading?: any;
  widthClass?: string;
  isHeightAuto?: boolean;
}

const PopupDetailBox: React.FC<PopupDetailBoxInterface> = ({
  label,
  headerValue,
  countValue,
  labelValue,
  isDisabled,
  disabledColor,
  loading,
  successValues,
  widthClass = "",
  isHeightAuto = false,
}: any) => {
  return (
    <div className={`block_wrap w-1/2 px-2.5 ${widthClass}`} key={label}>
      <div
        className={`inner_block_wrap border rounded px-5 py-3 ${
          isHeightAuto ? "h-auto" : "h-full"
        } relative  ${isDisabled ? "bg-gray-100/50 border-transparent" : ""}
        ${loading && "shimmer-effect"}
        ${
          disabledColor === "danger"
            ? "bg-rose-500 bg-opacity-[7%] border-rose-500/20"
            : disabledColor === "success" &&
              "bg-green-600 bg-opacity-[7%] border-green-600/20"
        }
        `}
      >
        {Array.isArray(headerValue) ? (
          headerValue?.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <h3 className="inline-flex text-sm18 leading-5 mb-2">
                  {item?.description ? item?.description : item?.name}
                  {index !== headerValue.length - 1 ? `, ` : ""}
                </h3>{" "}
              </React.Fragment>
            );
          })
        ) : (
          <h3 className="text-sm18 leading-5 mb-2">
            {label === "Orders" ? addCommaInNumber(headerValue) : headerValue}
          </h3>
        )}
        <span className="text-sm12 text-gray-500 block leading-4">{label}</span>
        {labelValue && (
          <div
            title="Orders"
            className="except_count px-2 py-1 bg-green-800 absolute top-[.8125rem] right-[.9375rem] text-xxs font-semibold rounded text-white leading-3"
          >
            {labelValue}
          </div>
        )}
        {!isDisabled && countValue && (
          <div className="except_count px-2 py-1 bg-rose-500 absolute top-[.8125rem] right-[.9375rem] text-xxs font-semibold rounded text-white leading-3">
            {countValue}
          </div>
        )}
        {disabledColor === "success" && successValues && (
          <div className="except_count px-2 py-1 bg-green-600 absolute top-[.8125rem] right-[.9375rem] text-xxs font-semibold rounded text-white leading-3">
            {moment(successValues).format("DD/MM/YYYY")}
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupDetailBox;
