import { size } from "lodash";
import { useContext } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { showAlert } from "../../../helpers/ShowAlert";
import {
  PAYOUT_TAB,
  TX_ACCOUNT_ORDER_INITIAL_FILTER,
  TX_ACCOUNT_ORDER_PAYOUT_SELLER,
} from "../../../helpers/const";
import { TxAccountContext } from "../core/TxAccountProvider";
import { makePayout } from "../core/_request";

const OrderFooterPayoutSeller = ({
  excludeOrderValue,
  ordersCount,
  isDisabled,
}: any) => {
  const layout = useContext(TxAccountContext);
  const coreLayout = useContext(LayoutContext);
  let steps = layout?.orderPayoutSeller;
  let isDisablePayout =
    size(excludeOrderValue?.excludeOrderFilterChip) > 0 ||
    !excludeOrderValue?.payoutReference.trim() ||
    ordersCount === 0 ||
    isDisabled;

  // HANDLE CONFIRMED
  const handleConfirmed = () => {
    layout.setCustomLoader(true);
    makePayout({
      ...layout.orderPayoutSeller,
      payout_reference: excludeOrderValue?.payoutReference,
    }).then((response: any) => {
      layout.setCustomLoader(false);

      if (response?.data?.status) {
        layout.setOrderPayoutSeller((current: any) => {
          let teamsOptions = coreLayout?.allowedAccess?.teams;

          return {
            ...current,
            finish: true,
            team_payout_ids: response?.data?.team_payout_ids?.map(
              (item: any) => {
                return {
                  ...item,
                  teamName: teamsOptions.find(
                    (option: any) => option.id === item.team_id
                  ),
                };
              }
            ),
          };
        });
      } else if (size(response?.errors) > 0) {
        showAlert(response?.errors?.[0], true);
        layout.setOrderPayoutSeller((current: any) => {
          return {
            ...current,
            authorize_pay_next_step: false,
          };
        });
      } else {
        showAlert("Something went wrong", true);
        layout.setOrderPayoutSeller((current: any) => {
          return {
            ...current,
            authorize_pay_next_step: false,
          };
        });
      }
    });
  };

  return (
    <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white border border-t z-20 min-h-[3.125rem]">
      <div className={`flex flex-wrap w-full justify-end `}>
        {/* FINISH */}
        {steps?.authorize_pay_step &&
        steps?.authorize_pay_next_step &&
        steps?.finish ? (
          <div className="right-btns flex items-center gap-2.5 anim-fade">
            <button
              type="button"
              className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
              onClick={() => {
                layout.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER);
                layout?.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
                layout.setTabActive(PAYOUT_TAB);
              }}
            >
              Finish
            </button>
          </div>
        ) : steps?.authorize_pay_step &&
          steps?.authorize_pay_next_step &&
          !steps?.finish ? (
          <>
            <div className="flex flex-wrap items-center gap-2.5  text-sm13 anim-fade">
              <span>
                Do you confirm the <span className="font-semibold">PAYOUT</span>{" "}
                details are correct?
              </span>
              <button
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
                        bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625reNleading-5"
                type="button"
                onClick={() =>
                  layout.setOrderPayoutSeller((current: any) => {
                    return {
                      ...current,
                      authorize_pay_next_step: false,
                    };
                  })
                }
              >
                Cancel
              </button>
              <button
                type="button"
                className="group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5  bg-violet-500 hover:bg-indigo-500 text-white hover:text-white"
                onClick={handleConfirmed}
              >
                Confirm
              </button>
            </div>
          </>
        ) : (
          <div className={`right-btns flex items-center gap-2.5 anim-fade`}>
            <button
              type="button"
              className={`group inline-flex group items-center hover:bg-indigo-500 hover:text-white transition font-medium rounded text-sm13 px-2.5 py-0.5 border ${
                size(excludeOrderValue?.excludeOrderFilterChip) > 0
                  ? "pointer-events-none bg-gray-100 text-gray-400"
                  : "excludeOrderValue?.excludeOrderFilterChip"
              }`}
              onClick={() =>
                layout.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER)
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className={`group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                isDisablePayout
                  ? "pointer-events-none bg-gray-100 text-gray-400"
                  : "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
              }`}
              onClick={() =>
                !isDisablePayout &&
                layout.setOrderPayoutSeller((current: any) => {
                  return {
                    ...current,
                    authorize_pay_next_step: true,
                  };
                })
              }
            >
              Authorise payout
            </button>
          </div>
        )}
      </div>

      {/* LOADER */}
      {layout.customLoader && <ProgressBardLoader secondCounter={1} />}
    </footer>
  );
};

export default OrderFooterPayoutSeller;
