import Cookies from "js-cookie";
import _, { size } from "lodash";
import moment from "moment";
import { logoutUser } from "pages/settings/core/_request";
import { useEffect, useRef } from "react";
import { formattedKeyValues } from "../components/ExpandableList/constants/constants";
import { extractOptions } from "./AssetHelpers";
import { SYMBOLS } from "./const";
import { showAlert } from "./ShowAlert";

// const decimalNumber = (number: any) => {
//   // return Number(number).toFixed(2);
//   const numberString = String(number);
//   const hasCommas = /[,]/.test(numberString);

//   if (hasCommas) {
//     // Number is already formatted with commas, return as is
//     return numberString;
//   }

//   const formattedNumber = Number(number).toFixed(2);

//   if (formattedNumber.length > 6) {
//     // Add commas for thousands separator
//     const parts = formattedNumber.split(".");
//     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     return parts.join(".");
//   }

//   return formattedNumber;
// };

const decimalNumber = (number: any) => {
  let numberString = String(number);

  numberString = numberString.replace(/,/g, "");

  const formattedNumber = Number(numberString).toFixed(2);

  const parts = formattedNumber.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};

// const handleKeyDown = (e: any) => {
//   if (
//     !(
//       (e.key >= "0" && e.key <= "9") ||
//       e.key === "Backspace" ||
//       e.key === "Delete" ||
//       e.key === "ArrowLeft" ||
//       e.key === "ArrowRight" ||
//       e.key === "Tab" ||
//       (e.ctrlKey && e.key === "c") ||
//       (e.ctrlKey && e.key === "a") ||
//       (e.ctrlKey && e.key === "v") ||
//       e.key === "." ||
//       // ((e.ctrlKey || e.metaKey) && e.key === "a")
//       ((e.ctrlKey || e.metaKey) &&
//         (e.key === "c" || e.key === "a" || e.key === "v"))
//     )
//   ) {
//     e.preventDefault();
//   }
// };

/** Optimized function to handle limited character fields to price field or customer phone number field */
const handleKeyDown = (e: any, allowedChars: string[] = []) => {
  // Define the default allowed keys
  const defaultAllowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9", // Numeric keys
    "Backspace",
    "Delete", // Deletion keys
    "ArrowLeft",
    "ArrowRight", // Arrow keys
    "Tab", // Tab key
    ".", // Decimal point
  ];

  const allowedKeys = [...defaultAllowedKeys, ...allowedChars];

  // Check if the pressed key is allowed
  if (
    !(
      (
        allowedKeys.includes(e.key) ||
        (e.ctrlKey && (e.key === "c" || e.key === "a" || e.key === "v")) || // Ctrl+C, Ctrl+A, Ctrl+V
        (e.metaKey && (e.key === "c" || e.key === "a" || e.key === "v"))
      ) // Command+C, Command+A, Command+V (for Mac)
    )
  ) {
    e.preventDefault();
  }
};

export const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
  const pastedData = e.clipboardData.getData("Text").trim();

  const isValidNumber = /^\d*\.?\d*$/.test(pastedData.replace(/,/g, ""));

  if (!isValidNumber) {
    e.preventDefault();
  }
};

const grettingMessage = () => {
  let currentDate = new Date();
  let hrs = currentDate.getHours();
  let greet: any = "";

  if (hrs < 12) greet = "Good morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good evening";

  return greet;
};

// IS CHILD IN VIEW - FUNCTION
function isChildInViewport(childId: string): boolean {
  const childElement = document.getElementById(childId);
  if (!childElement) {
    return false;
  }
  const childRect = childElement.getBoundingClientRect();
  return (
    childRect.top >= 0 &&
    childRect.left >= 0 &&
    childRect.bottom <= window.innerHeight &&
    childRect.right <= window.innerWidth
  );
}

// SCROLL VIA OFFCANVAS
const scrollToVisible = (childId: string) => {
  const child = document.getElementById(childId);
  const childReact = child && child.getBoundingClientRect();
  if (childReact)
    if (childReact.top < 0 || childReact.bottom > window.innerHeight) {
      window.scrollTo({
        top: child.offsetTop,
        behavior: "smooth",
      });
    }
};

const priceFormat = (price: any) => {
  return price
    ? price.toLocaleString("en-US", { minimumFractionDigits: 2 })
    : "";
};

function hasExtension(fileName: any) {
  const regex = /\.[^.]+$/; // Matches any characters after the last dot
  return regex.test(fileName);
}
const existInLocalstorage = (name: string) => {
  return localStorage.getItem(name)
    ? JSON.parse(localStorage.getItem(name) as string)
    : null;
};
const existInSessionstorage = (name: string) => {
  return sessionStorage.getItem(name)
    ? JSON.parse(sessionStorage.getItem(name) as string)
    : null;
};

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

var tempIndex: any;
const handleShift = (e: any, rIndex: any, data: any) => {
  if (e.shiftKey && e.target.checked) {
    data?.forEach((record: any, index: any) => {
      if (
        (tempIndex <= index && index <= rIndex) ||
        (tempIndex >= index && rIndex <= index)
      ) {
        record.selected = true;
      }
    });
  } else {
    tempIndex = rIndex;
  }
};

const daysDifference = (date1: any, date2: any) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  const diffTime = date1 - date2;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const parseMMDDYYYY = (date: any) => {
  if (date) {
    var dateParts = date?.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject =
      dateParts && new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject.toString();
  }
};
/**
 * GET COLOR FOR ORDER STATUS IN SALES AND REPORT
 * @param data
 * @returns
 */
const getOrderStatusColor = (data: any) => {
  let statusColor: any =
    data?.is_legacy_order == "true"
      ? `bg-rose-500`
      : data?.order_status === "Commissionable" ||
        data?.status === "Commissionable"
      ? `bg-green-600`
      : data?.deliveryByDays <= 14 &&
        !(data?.order_status === "Cancelled" || data?.status === "Cancelled")
      ? "bg-rose-500"
      : `bg-violet-800`;
  let dateColor: any =
    data?.is_legacy_order == "true"
      ? `bg-rose-500 bg-opacity-[7%]`
      : data?.order_status === "Commissionable" ||
        data?.status === "Commissionable"
      ? `bg-green-600 bg-opacity-[7%]`
      : data?.deliveryByDays <= 14 &&
        !(data?.order_status === "Cancelled" || data?.status === "Cancelled")
      ? "bg-rose-500 bg-opacity-[7%]"
      : `bg-gray-100`;
  return { statusColor, dateColor };
};

function hasEmptyOrNullValues(arr: any) {
  return !arr.every((element: any) => element !== null && element !== "");
}

function groupTeamMemberCommon(arr: any) {
  return arr?.map((team: any) => ({
    label: team?.name,
    options: team?.users?.map((user: any) => ({
      id: user?.id,
      name: user?.name,
    })),
  }));
}

function getCurrencySymbol(currencyCode: any) {
  return SYMBOLS[currencyCode];
}

// MAKE LARGE STRING TO SMALL
function makeCRCTable() {
  var c;
  var crcTable = [];
  for (var n = 0; n < 256; n++) {
    c = n;
    for (var k = 0; k < 8; k++) {
      c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1;
    }
    crcTable[n] = c;
  }
  return crcTable;
}

function crc32(str: any) {
  var crcTable =
    (window as any).crcTable || ((window as any).crcTable = makeCRCTable());
  var crc = 0 ^ -1;

  if (str) {
    for (var i = 0; i < str?.length; i++) {
      crc = (crc >>> 8) ^ crcTable[(crc ^ str?.charCodeAt(i)) & 0xff];
    }
  }

  return (crc ^ -1) >>> 0;
}

function removeCommaFromNumber(number: any) {
  let commaRegex = /,/;
  if (commaRegex.test(number)) {
    return number?.replace(/,/g, "");
  } else {
    return number;
  }
}

const addCommaInNumber = (number: any) => {
  // return Number(number).toFixed(2);
  const numberString = String(number);
  const hasCommas = /[,]/.test(numberString);

  if (hasCommas) {
    // Number is already formatted with commas, return as is
    return numberString;
  }

  if (numberString.length > 3) {
    // Add commas for thousands separator
    const parts = numberString.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  return numberString;
};

// px to rem convert
const remCalc = (px: any, base = 16) => {
  let tempPx = px;
  if (typeof px === "string" || px instanceof String)
    tempPx = tempPx.replace("px", "");

  tempPx = parseInt(tempPx);
  return (1 / base) * tempPx + "rem";
};

// PRICE CONVERSATION BASE ON BASE CURRENCY
function convertPriceWithBaseCurrency(currency: any, price: any, rate: any) {
  let convRate = size(rate) > 0 ? rate[currency] : rate;

  if (price && convRate) {
    const symbol = currency ? SYMBOLS[currency] : "£";
    const convertedPrice = price?.replace(/,/g, "") * convRate;
    return `${symbol}${decimalNumber(convertedPrice)}`;
  } else {
    return `£${price}`;
  }
}

function checkIsFullfillUploadExist(list: any) {
  const selectedEventRecords = list
    ?.flatMap((item: any) =>
      item?.eventRecords?.filter((record: any) => record?.selected)
    )
    .filter((obj: any) => obj !== undefined);

  const flag =
    selectedEventRecords?.length > 0
      ? selectedEventRecords?.filter(
          (obj: any) =>
            obj?.listing_ticket_files?.length > 0 &&
            obj?.quantity_available !== obj?.listing_ticket_files?.length
        )
      : [];
  return flag || "";
}

function firstLetterCapital(str: any) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

function priceCalculation(
  inputPrice: any,
  averagePrice: any,
  priceFactor: any
) {
  if (inputPrice && averagePrice !== null) {
    const diff =
      (removeCommaFromNumber(inputPrice) * 100) /
      removeCommaFromNumber(averagePrice);

    let percentDiff = Math.round(diff - 100);
    let colorCategory;
    if (percentDiff >= -60 && percentDiff <= 10) {
      colorCategory = "Good";
    } else if (percentDiff > 10 && percentDiff < 30) {
      colorCategory = "Average";
    } else if (percentDiff >= 30) {
      colorCategory = "poor";
    } else if (percentDiff < -60) {
      colorCategory = "Low";
    }
    return colorCategory;
  } else {
    return "nomsg";
  }
}

function priceCalculationWithNumber(
  inputPrice: any,
  averagePrice: any,
  priceFactor: any
) {
  if (inputPrice && averagePrice !== null && averagePrice) {
    let percentDiff = Math.round(
      (removeCommaFromNumber(inputPrice) * 100) /
        removeCommaFromNumber(averagePrice) -
        100
    );
    let colorCategory;
    if (percentDiff >= -60 && percentDiff <= 10) {
      colorCategory = "2";
    } else if (percentDiff > 10 && percentDiff < 30) {
      colorCategory = "1";
    } else if (percentDiff >= 30) {
      colorCategory = "3";
    } else if (percentDiff < -60) {
      colorCategory = "0";
    }
    return colorCategory;
  }
}

function colorForMeter(value: any, record?: any) {
  const priceValue =
    _.size(record?.faceValue) === 0 ||
    _.size(record?.proceedValue) === 0 ||
    record?.faceValue?.value === "" ||
    record?.faceValue?.value === "0.00" ||
    record?.proceedValue?.value === "" ||
    record?.proceedValue?.value === "0.00";
  // if (isPriceField?.withCategory) {

  if (value && value !== "nomsg") {
    if (value === "poor") {
      return "price_high";
    }
    if (value === "Average") {
      return "price_medium";
    }
    if (value === "Good") {
      return "price_regular";
    }
    return "price_low";
  } else if (record && priceValue) {
    return `price_with_empty`;
  }

  // }
}

interface TooltipMessage {
  [key: string]: string;
}

const tooltipMessages: TooltipMessage = {
  poor: "Not likely to sell at this price",
  Average: "More likely to sell at a lower price",
  Good: "Competitively priced and likely to sell",
  Low: "This price looks too low",
};

function getMessageForAvgPrice(avg_price: string): string {
  return tooltipMessages[avg_price] || "";
}

function htmlForTooltip(msg: string, level: string): string {
  return `<div>
    <p class='text-center '>${level}</p>
    <p class='py-0.5'>${msg}</p>
  </div>`;
}

function tooltipForListingMeter(
  visiblity: any,
  avg_price: any,
  event: any
): string {
  if (avg_price && event?.id && visiblity > 2) {
    const msg = getMessageForAvgPrice(avg_price);
    const level = `Level ${visiblity - 2}`;
    return htmlForTooltip(msg, level);
  } else {
    if (visiblity <= 2) {
      return `Listing Quality Low`;
    } else {
      return `Listing Quality Level ${visiblity - 2}`;
    }
  }
}

const ticketTypeMappings: { [key: string]: string } = {
  pdf: "E-ticket",
  "mobile-qr": "Mobile Screenshot",
  mobile: "External Transfer",
  "mobile-link": "Mobile Link/PKPASS",
  "season-cards": "Member/Season Card",
  paper: "Paper",
  eticket: "E-ticket",
  PDF: "E-ticket",
  Mobile: "External Transfer",
  "mobile-transfer": "External Transfer",
  eTicket: "E-ticket",
  "local-delivery": "Local Delivery",
};
function ticketTypeRename(value: any) {
  return ticketTypeMappings[value] || value;
}

function ticketTypeRenameForSalesReport(value: any, subticket: any) {
  return (
    ticketTypeMappings[
      ["eticket", "eTicket", "pdf", "PDF"].includes(value)
        ? subticket
          ? subticket
          : value
        : value
    ] ||
    ticketTypeMappings[value] ||
    value
  );
}

function getProperTicketTypeWithSubticketCombine(ticketArray: any) {
  return ticketArray?.reduce((acc: any, current: any) => {
    if (current?.id === "eticket") {
      // If the current element is "eticket", extract subtypes
      const updatedSubtypes =
        current?.subtypes?.map((subtype: any) => {
          // Check if the subtype's name is "PDF E-Ticket" and rename it to "E-Ticket"
          if (subtype?.name === "PDF E-Ticket") {
            return { ...subtype, name: "E-Ticket" };
          }
          return subtype;
        }) || [];

      acc.push(...updatedSubtypes);
    } else {
      acc.push({
        ...current,
        name: current?.name.charAt(0).toUpperCase() + current?.name.slice(1),
      });
    }
    return acc;
  }, []);
}
const capitalizedWords = (children: any, noCapitalize: any) => {
  if (!noCapitalize) {
    const words = children?.split(" ");
    let wordsArray = words?.map((word: any, index: number) => {
      if (word.toUpperCase() === word) {
        return word;
      } else if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word.toLowerCase();
      }
    });
    return wordsArray?.join(" ");
  }
  return children;
};

function uniqueByNameWithPriority(arr: any, id: any) {
  const uniqueMap = new Map();
  arr &&
    arr.forEach((item: any) => {
      if (!uniqueMap.has(item.name) || item.id == id) {
        uniqueMap.set(item.name, item);
      }
    });
  return Array.from(uniqueMap.values());
}

// function convertDateStringToUnixTimestamp(dateString: any) {
//   const date = new Date(dateString + " GMT");
//   const epochTimestamp = date.getTime() / 1000;
//   return epochTimestamp;
// }
function convertDateStringToUnixTimestamp(dateString: any) {
  // Parsing date using moment for consistent timezone handling
  const date = moment.utc(dateString);
  const epochTimestamp = date.unix();
  return epochTimestamp;
}

// const handleScroll = (element: any, stickyHead: any = "") => {
//   const scrollLeft = element.scrollLeft;
//   const scrollRight = Math.max(
//     0,
//     element.scrollWidth - element.clientWidth - scrollLeft
//   );

//   if (scrollRight < 1) {
//     element && element.classList.add("rightDisable");
//     stickyHead && stickyHead.classList.add("rightDisable");
//   }
//   if (scrollRight > 1) {
//     element && element.classList.remove("rightDisable");
//     stickyHead && stickyHead.classList.remove("rightDisable");
//   }

//   if (scrollLeft === 0) {
//     element && element.classList.add("leftDisable");
//     stickyHead && stickyHead.classList.add("leftDisable");
//   }
//   if (scrollLeft > 0) {
//     element && element.classList.remove("leftDisable");
//     stickyHead && stickyHead.classList.remove("leftDisable");
//   }
//   if (scrollLeft === 0 && scrollRight === 0) {
//     element && element.classList.add("leftDisable");
//     stickyHead && stickyHead.classList.add("leftDisable");
//     element && element.classList.add("rightDisable");
//     stickyHead && stickyHead.classList.add("rightDisable");
//   }
// };

const handleScroll = (element: any, stickyHead: any = "") => {
  const scrollLeft = element.scrollLeft;
  const scrollRight = Math.max(
    0,
    element.scrollWidth - element.clientWidth - scrollLeft
  );

  // Handle right disable
  if (scrollRight <= 1) {
    element?.classList.add("rightDisable");
    stickyHead?.classList.add("rightDisable");
  } else {
    element?.classList.remove("rightDisable");
    stickyHead?.classList.remove("rightDisable");
  }

  // Handle left disable
  if (scrollLeft <= 0) {
    element?.classList.add("leftDisable");
    stickyHead?.classList.add("leftDisable");
  } else {
    element?.classList.remove("leftDisable");
    stickyHead?.classList.remove("leftDisable");
  }
};

const showSectionOnly = (categoryName: any, sectionName: any) => {
  if (categoryName == sectionName) {
    return sectionName;
  } else {
    return sectionName.replace(categoryName, "");
  }
};

const sentenceCase = (data: string) => {
  return _.capitalize(data?.toLowerCase());
};

const maxFileUploadCount = (sellerType: string, formikFilesArray: any) => {
  const maxProofs = sellerType === "Business" ? 3 : 2;
  const proofCount = size(formikFilesArray);

  return proofCount <= maxProofs ? proofCount : maxProofs;
};

const getCountryCodeOptions = (data: any) => {
  return data?.map((item: any, index: number) => {
    return {
      id: `${item?.name} ${item?.dial_code}`,
      name: `${item?.iso_code} (${item?.name})`,
      originalId: item?.iso_code,
    };
  });
};

const checkCompleteKYC = (data: any) => {
  if (!data?.txPayStatus && data?.txPayData?.seller_type === "Individual") {
    return {
      status: true,
      identity_proof: data?.txPayData?.identity_proof,
      proofSize: data?.txPayData?.identity_proof?.length,
    };
  } else if (
    !data?.txPayStatus &&
    data?.txPayData?.seller_type === "Business"
  ) {
    return {
      status: true,
      business_proof: data?.txPayData?.business_proof,
      proofSize: data?.txPayData?.business_proof?.length,
    };
  } else {
    return {};
  }
};

// ERROR REFLECT
const errorReflect = (data: any) => {
  return data?.errors?.title
    ? showAlert(data?.errors?.title, true)
    : Array.isArray(data?.errors) && size(data?.errors) > 0
    ? showAlert(data?.errors?.join("<br/>"), true)
    : data?.message
    ? showAlert(data?.message, true)
    : typeof data?.error === "string"
    ? showAlert(data?.error, true)
    : "";
};

//useClickOutside hook
const useClickOutside = (handler: any) => {
  const domNode: any = useRef();

  useEffect(() => {
    let eventHandler = (event: any) => {
      if (!domNode?.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", eventHandler);

    return () => {
      document.removeEventListener("mousedown", eventHandler);
    };
  });

  return domNode;
};
const handleLogout = async () => {
  await logoutUser();
  window.location.href = "/login";
  Cookies.remove("authTokens");
  Cookies.remove("authData");
  const recentSearch = localStorage.getItem("recentSearch");
  localStorage.clear();
  recentSearch && localStorage.setItem("recentSearch", recentSearch);
};

const getThreeMonthsLater = (startDate: any, endDate: any) => {
  let start = moment(startDate);
  let end = moment(endDate);

  let threeMonthsLater = start.add(3, "months");
  return end.isAfter(threeMonthsLater);
};

const isMenuAllowed = (arr: any, name: string) => {
  return arr?.some((item: any) => item.id === name && item.status);
};

const isMobileView = window.matchMedia("(max-width: 768px)").matches;

const getIDFromArray = (arr: any, keyToFind: string) => {
  return arr && arr?.find((obj: any) => obj?.name === keyToFind)?.id;
};

export const isDateBeforeValidLogDate = (date: any) => {
  let logsValidDate = process.env.REACT_APP_VALID_LOGS_DATE;
  if (!date || !logsValidDate) return false;

  return moment(date).isBefore(moment(logsValidDate));
};

export const convertIntoFormatValue = (key: any, data: any) => {
  if (!data[key]) return null;
  let value;
  Object.keys(formattedKeyValues).forEach(() => {
    value = `${SYMBOLS[data[formattedKeyValues[key]]] ?? ""}${decimalNumber(
      data[key]
    )}`;
  });
  return value;
};
export const convertIntoErrorMessage = (data: any) => {
  if (!data?.errors && !data.message) return "Something went wrong!";

  return data?.errors?.title
    ? data?.errors?.title
    : Array.isArray(data?.errors)
    ? data?.errors?.join("<br/>")
    : data.message;
};

export const isValidNumber = (num: any) => {
  if (!num) return true;

  const regex = /^-?(?:0|[0-9]\d{0,2}(?:,?\d{3})*)(?:\.\d{0,2})?$/;
  return regex.test(num);
};
const removeSelectAll = (mainArray: any, visibleArray: any) => {
  return (
    (mainArray &&
      mainArray?.filter(
        (item: any) =>
          visibleArray &&
          !visibleArray.some((visibleItem: any) => visibleItem.id === item.id)
      )) ||
    []
  );
};

const addSelectAll = (
  currentArray: any,
  newArray: any,
  searchBy: string = ""
) => {
  let newArrayForTeamsFilter: string[] = [
    "Teams",
    "teams",
    "team_id",
    "teamMembers",
    "teammembers",
    "team_member",
    "order_status",
    "order_statuses",
    "restrictions",
  ];
  if (currentArray && Array.isArray(currentArray)) {
    // return _.unionWith(currentArray, newArray, _.isEqual);
    return _.unionBy(
      currentArray,
      newArray,
      newArrayForTeamsFilter.includes(searchBy) ? "id" : "name"
    );
  } else {
    return newArray;
  }
};

const handleSelectDeselectAll = (
  data: any,
  fieldName: string,
  options: any,
  visibleOptions: any = null,
  setState: any,
  isArrayTypeState: boolean = false,
  nameAvailable: string = "",
  otherValuesInStateUpdate: any = [],
  otherValuesInStateDelete: any = []
) => {
  let updatedOptions =
    visibleOptions && size(visibleOptions) > 0
      ? visibleOptions
      : data?.target?.id === "select-all"
      ? options
      : null;

  let isNameAvailable = updatedOptions?.some((item: any) =>
    nameAvailable ? item?.[nameAvailable] : item?.name || item?.type
  );

  if (data?.target?.id === "select-all") {
    if (isArrayTypeState) {
      setState((currentValue: any) => {
        return addSelectAll(
          currentValue,
          updatedOptions?.filter((option: any) => option.name),
          fieldName
        );
      });
    } else {
      setState((currentValue: any) => {
        let conditionalUpdatedOptions = isNameAvailable
          ? updatedOptions?.filter((option: any) => option.name || option.type)
          : extractOptions(updatedOptions);

        const updatedState = {
          ...currentValue,
          [fieldName]: addSelectAll(
            currentValue[fieldName],
            conditionalUpdatedOptions,
            fieldName
          ),
          page: 1,
        };

        // CHECK IF ANY OTHER VALUES NEED TO UPDATE IN THE STATE
        size(otherValuesInStateUpdate) > 0 &&
          otherValuesInStateUpdate.forEach((item: any) => {
            updatedState[item.name] = item.value;
          });

        // CHECK IF ANY OTHER VALUES NEED TO GET DELETED IN THE STATE
        size(otherValuesInStateDelete) > 0 &&
          otherValuesInStateDelete.forEach((item: any) => {
            delete updatedState[item.name];
          });

        return updatedState;
      });
    }
  } else if (data?.target?.id === "deselect-all") {
    if (isArrayTypeState) {
      setState((currentValue: any) => {
        return removeSelectAll(
          currentValue,
          isNameAvailable ? updatedOptions : extractOptions(updatedOptions)
        );
      });
    } else {
      setState((currentValue: any) => {
        const updatedState = {
          ...currentValue,
          [fieldName]: removeSelectAll(
            currentValue?.[fieldName],
            isNameAvailable ? updatedOptions : extractOptions(updatedOptions)
          ),
          page: 1,
        };

        // CHECK IF ANY OTHER VALUES NEED TO GET DELETED IN THE STATE
        size(otherValuesInStateDelete) > 0 &&
          otherValuesInStateDelete.forEach((item: any) => {
            delete updatedState[item.name];
          });

        return updatedState;
      });
    }
  }
};

const pdfViewer = (setState: any, file: any) => {
  setState((current: any) => {
    return {
      ...current,
      page: 1,
      file: file,
      status: true,
    };
  });
};

const pdfViewerMain = (setState: any, file: any) => {
  setState((current: any) => {
    return {
      ...current,
      page: 1,
      file: file,
      status: true,
    };
  });
};

export const getDefaultPaginationFilters = (perPage: number = 30) => ({
  page: 1,
  per_page: perPage,
});

const checkTicketFilesEmptyOrNot = (array: any) => {
  return array && size(array) > 0
    ? array?.filter((obj: any) => JSON.stringify(obj) !== "{}")?.length
    : 0;
};

export const isSameOrderId = (data: any, list: any) => {
  if (!data || !list) return false;
  return data?.order_id === list?.order_id;
};

const updatePPEPartners = (
  ppePartners: any[],
  id: any,
  value: any,
  type: any,
  txNetworkValue: any
) => {
  let partnerExists = false;

  const filteredPPEPartners =
    ppePartners &&
    ppePartners?.length > 0 &&
    ppePartners?.filter((partner: any) => partner?.id);

  const updatedPartners: any =
    filteredPPEPartners && filteredPPEPartners?.length > 0
      ? filteredPPEPartners?.map((partner) => {
          if (partner?.id === id) {
            partnerExists = true;
            return { ...partner, value: value };
          }
          return partner;
        })
      : [];

  if (!partnerExists) {
    // updatedPartners.push({ id: id, value: value });
  }

  let minValue = Number.MAX_VALUE;
  let minValueSymbol = "";

  updatedPartners.forEach((partner: any) => {
    const partnerValue = Number(removeCommaFromNumber(partner?.value?.value));

    if (!partner.is_hidden && !isNaN(partnerValue)) {
      if (partnerValue < minValue) {
        minValue = partnerValue;
        minValueSymbol = partner?.value?.symbol;
      }
    }
  });

  if (
    !isNaN(Number(removeCommaFromNumber(txNetworkValue?.value))) &&
    Number(removeCommaFromNumber(txNetworkValue?.value)) < minValue
  ) {
    minValue = Number(removeCommaFromNumber(txNetworkValue?.value));
    minValueSymbol = txNetworkValue?.symbol;
  }

  const arrayWithTxtradePrice = updatedPartners.map((partner: any) => {
    if (partner.is_hidden === true) {
      return {
        ...partner,
        value: {
          ...partner.value,
          value: minValue,
          symbol: minValueSymbol,
        },
      };
    }
    return partner;
  });

  if (type === "ppePartners") {
    return arrayWithTxtradePrice;
  } else {
    return arrayWithTxtradePrice?.map((partner: any) => {
      return {
        exchange_id: partner.exchange_id,
        sell_price_currency: partner?.value?.symbol,
        sell_price: partner?.value?.value,
        reseller_id: partner?.id,
        reseller_name: partner?.name,
      };
    });
  }
};

const resetPPEFields = (ppePartners: any, sell_price: any, type: any) => {
  const filteredPPEPartners =
    ppePartners &&
    ppePartners?.length > 0 &&
    ppePartners?.filter((partner: any) => partner?.id);

  sell_price =
    typeof sell_price === "object"
      ? sell_price
      : {
          value: sell_price,
        };

  const updatedPartners: any =
    filteredPPEPartners && filteredPPEPartners?.length > 0
      ? filteredPPEPartners?.map((partner: any) => {
          return {
            ...partner,
            value: {
              ...partner.value,
              value: sell_price.value,
            },
          };
        })
      : [];

  if (type === "ppePartners") {
    return updatedPartners;
  } else {
    return updatedPartners?.map((partner: any) => {
      return {
        exchange_id: partner.exchange_id,
        sell_price_currency: partner?.value?.symbol,
        sell_price: partner?.value?.value,
        reseller_id: partner?.id,
        reseller_name: partner?.name,
      };
    });
  }
};

const convertPPEPartnersToLocalFormat = (
  data: any,
  sell_price: any,
  currency: any
) => {
  return data?.map((exchange: any) => {
    return {
      id: exchange?.reseller_id,
      name: exchange?.reseller_name,
      value: {
        symbol: exchange?.sell_price_currency
          ? exchange?.sell_price_currency
          : currency,
        value:
          exchange?.sell_price === 0
            ? sell_price
            : decimalNumber(exchange?.sell_price),
        name: exchange?.reseller_id,
      },
      exchange_id: exchange?.exchange_id,
      is_hidden: exchange?.is_hidden,
    };
  });
};

const isValidPriceFieldNumber = (number: any) => {
  return (
    !isNaN(removeCommaFromNumber(decimalNumber(number))) &&
    Number(removeCommaFromNumber(decimalNumber(number))) > 0
  );
};

export const removeHtmlTags = (inputString: string) => {
  return inputString.replace(/<[^>]*>/g, "");
};

export function getExtension(url: any) {
  const parts = url?.split("?")?.[0]?.split("#")?.[0]?.split("."); // Remove query and hash, then split by '.'
  return parts?.length > 1 ? parts?.pop() : ""; // Return the last part or empty string if no extension
}

export const toNumber = (value: any) => {
  const number = parseFloat(value);
  return isNaN(number) ? 0 : number;
};

export const convertToFormattedData = (data: any) => {
  return (
    data &&
    Object.entries(data).map(([key, options]) => ({
      label: key,
      options,
    }))
  );
};

const convertToFormattedOptions = (data: any) => {
  return Object.entries(data).map(([key, options]) => ({
    label: key,
    options,
  }));
};
export const getUserConfig = (globalLayout: any, configKey: any) => {
  return globalLayout?.allowedAccess?.team_data?.seller_level?.config?.find(
    (item: any) => item?.key === configKey
  );
};

function ToggleElemOpen() {
  const submenuWrapper: any = document.querySelector("#filtersMob");
  submenuWrapper.classList.add("activeFIlter");
  document.body.classList.add("overflow-hidden");
}

const countHandleChange = (
  event: any,
  options: any,
  key: string,
  setState: any
) => {
  setState((prevFilterData: any) => {
    const selectedStatus = options?.find((item: any) => {
      return item.name === event.target.name;
    });

    const currentKeyData = prevFilterData?.[key] || [];
    const isChecked = event.target.checked;

    const dynamicOptions = isChecked
      ? [...currentKeyData, selectedStatus]
      : currentKeyData.filter(
          (status: any) => status?.name !== event.target.name
        );

    const newFilterData = {
      ...prevFilterData,
      [key]: dynamicOptions,
      page: 1,
      per_page: 20,
    };
    return newFilterData;
  });
};

function getValueByKey(array: any[], key: string): any {
  const foundItem = array?.find((item) => item?.key === key);
  return foundItem ? foundItem?.value : null; // Return value if found, otherwise null
}

const separateBenefitsAndRestrictions = (
  restrictionsArray: any,
  allbenefits: any,
  allrestrictions: any
) => {
  const benefits: any = [];
  const restrictions: any = [];

  restrictionsArray?.forEach((item: any) => {
    if (allbenefits.some((benefit: any) => benefit.id === item.id)) {
      benefits.push(item);
    } else if (
      allrestrictions.some((restriction: any) => restriction.id === item.id)
    ) {
      restrictions.push(item);
    }
  });

  return { benefits, restrictions };
};

const getTicketTypeValue = (
  ticket_type: any,
  sub_ticket_type: any,
  ticketTypes: any
) => {
  let ticketTypeValue = {
    id: "",
    name: "",
  };
  if (typeof ticket_type === "object") {
    if (ticket_type === "eticket" && sub_ticket_type === "pdf") {
      ticketTypeValue = {
        id: "pdf",
        name: "PDF E-Ticket",
      };
    } else if (ticket_type === "eticket" && sub_ticket_type === "mobile-qr") {
      ticketTypeValue = {
        id: "mobile-qr",
        name: "Mobile QR",
      };
    } else {
      ticketTypeValue = ticket_type;
    }
  } else if (Array.isArray(ticketTypes)) {
    let subTicket = !sub_ticket_type?.trim() ? "pdf" : sub_ticket_type;
    const foundTicket = ticketTypes.find((ticket: any) => {
      if (ticket_type === "eticket") {
        return ticket?.id === subTicket;
      } else {
        return ticket?.id === ticket_type;
      }
    });

    ticketTypeValue = foundTicket;
  }

  return ticketTypeValue;
};

export const getVenuePerformerEventConfig = (coreLayout: any) => {
  return _.size(coreLayout?.allowedAccess?.venue_performer_config) > 0
    ? coreLayout?.allowedAccess?.venue_performer_config
    : false;
};

export const penaltyBreakdownInOrder = (
  fixedOrderOptions: any,
  ActualArray: any
) => {
  return fixedOrderOptions &&
    ActualArray &&
    fixedOrderOptions?.length > 0 &&
    ActualArray?.length > 0
    ? fixedOrderOptions?.filter((option: any) =>
        ActualArray?.some((item: any) => item.id === option)
      )
    : [];
};

export const endReachedVirtuoso = (paginateData: any, setState: any) => {
  if (paginateData?.current_page < paginateData?.last_page) {
    setState((current: any) => {
      let nextPage: number = paginateData?.current_page + 1;
      return {
        ...current,
        page: nextPage,
        per_page: 20,
      };
    });
  }
};

export const handleSetCopyFilters = (
  setCopyFilters: any,
  filtersValue: any,
  isGodAdmin: any,
  replace = false
) => {
  // if (isGodAdmin) {
  //   setCopyFilters(filtersValue);
  // }

  if (isGodAdmin) {
    setCopyFilters((curr: any) => {
      return replace === true
        ? filtersValue
        : {
            ...curr,
            ...filtersValue,
          };
    });
  }
};

export const removeEmptyValues: any = (obj: any) => {
  return _.pickBy(obj, (value: any) => {
    if (Array.isArray(value)) return value.length > 0;
    if (value instanceof Date) return true; // Keep Date objects
    if (value && typeof value === "object") {
      return !_.isEmpty(removeEmptyValues(value));
    }
    return !!value; // This will remove false, null, '', undefined, 0, NaN
  });
};

const pluralize = (count: number, singular: string) => {
  const esEndings = ["s", "sh", "ch", "x", "z"];
  const lower = singular.toLowerCase();

  let plural;
  if (esEndings.some((ending) => lower.endsWith(ending))) {
    plural = singular + "es";
  } else if (lower.endsWith("y") && !/[aeiou]y$/.test(lower)) {
    // e.g., "category" → "categories"
    plural = singular.slice(0, -1) + "ies";
  } else {
    plural = singular + "s";
  }

  return `${count === 1 ? singular : plural}`;
};

export {
  addCommaInNumber,
  addSelectAll,
  capitalizedWords,
  checkCompleteKYC,
  checkTicketFilesEmptyOrNot,
  colorForMeter,
  convertDateStringToUnixTimestamp,
  convertPPEPartnersToLocalFormat,
  convertPriceWithBaseCurrency,
  convertToFormattedOptions,
  countHandleChange,
  crc32,
  daysDifference,
  decimalNumber,
  errorReflect,
  existInLocalstorage,
  existInSessionstorage,
  firstLetterCapital,
  getCountryCodeOptions,
  getCurrencySymbol,
  getIDFromArray,
  getOrderStatusColor,
  getProperTicketTypeWithSubticketCombine,
  getThreeMonthsLater,
  getTicketTypeValue,
  getValueByKey,
  grettingMessage,
  groupTeamMemberCommon,
  handleKeyDown,
  handleLogout,
  handleScroll,
  handleSelectDeselectAll,
  handleShift,
  hasEmptyOrNullValues,
  hasExtension,
  isChildInViewport,
  isMenuAllowed,
  isMobileView,
  isValidPriceFieldNumber,
  maxFileUploadCount,
  parseMMDDYYYY,
  pdfViewer,
  pdfViewerMain,
  pluralize,
  priceCalculation,
  priceCalculationWithNumber,
  priceFormat,
  remCalc,
  removeCommaFromNumber,
  removeSelectAll,
  resetPPEFields,
  scrollToTop,
  scrollToVisible,
  sentenceCase,
  separateBenefitsAndRestrictions,
  showSectionOnly,
  ticketTypeRename,
  ticketTypeRenameForSalesReport,
  ToggleElemOpen,
  tooltipForListingMeter,
  uniqueByNameWithPriority,
  updatePPEPartners,
  useClickOutside
};

