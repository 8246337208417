import _ from "lodash";
import { convertIntoDateFormat } from "pages/inventory/core/_functions";
import axios from "../../../axois/SetupAxios";

const makePayloadForReferral = (data: any) => {
  let page = data?.page;
  let per_page = data?.per_page;
  let order_by = data?.order_by;
  let sort_order = data?.sort_order;
  let query = data?.query;
  let startDate = data?.startDate;
  let endDate = data?.endDate;
  let referral_status =
    data?.referralStatus && _.map(data?.referralStatus, "id");
  let team_id = data?.referredTeamName && _.map(data?.referredTeamName, "id");

  return {
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && order_by && { sort_order }),
    ...(query && { query }),
    ...(startDate && {
      referral_start_date: convertIntoDateFormat(startDate, "YYYY-MM-DD"),
    }),
    ...(endDate && {
      referral_expiry_date: convertIntoDateFormat(endDate, "YYYY-MM-DD"),
    }),
    ...(referral_status && referral_status?.length > 0 && { referral_status }),
    ...(team_id && team_id?.length > 0 && { referred_id: team_id }),
  };
};

//GET DATA
const getOverviewData = (): Promise<any> => {
  return axios.get(`settings/overview`).then((response) => response.data);
};

const getAccountInfo = async (data = false): Promise<any> => {
  return await axios
    .get(`settings/account/info${data ? "?with_balance=true" : ""}`)
    .then((response) => response.data);
};

const getAddress = async (data = ""): Promise<any> => {
  const response = await axios.get(
    `settings/account/address-book${data ? `?team_id=${data}` : ""}`
  );
  return response.data;
};

const getPackagesData = (): Promise<any> => {
  return axios
    .get(`settings/account/packages`)
    .then((response) => response.data);
};

const getChannelsData = (): Promise<any> => {
  return axios
    .get(`settings/account/team-exchange`)
    .then((response) => response.data);
};

// UPDATE ACCOUNTINFO
const updateAccountDetail = (data: any) => {
  return axios
    .post(`settings/account/info`, data)
    .then((response) => response.data);
};

// UPDATE ADDRESS
const updateAddress = (data: any) => {
  return axios
    .post(`settings/account/address-book`, data)
    .then((response) => response.data);
};

// TEAM LISTING
const getTeamData = (data: any): Promise<any> => {
  let team_member = _.map(data?.filterData?.team_member, "id");
  let user_types = data?.filterData?.usertype?.id;
  let page = data?.filterData?.page;
  let per_page = data?.overview ? data?.overview : data?.filterData?.per_page;
  let enterPriceUser = data?.filterData?.enterprise_paid_users;

  let payload = {
    ...data.pagination,
    ...(data?.debouncedSearch && { keywords: data?.debouncedSearch }),
    ...(team_member && team_member.length > 0 && { team_member }),
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(user_types && { type: user_types }),
    ...(enterPriceUser && { tab1: "enterprise_paid_users" }),
  };

  return axios
    .post(`settings/account/team-members`, payload)
    .then((response) => response.data);
};

// ADD TEAM MEMBER
const addTeamMembers = (data: any) => {
  return axios
    .post(`settings/account/add-team-members`, data)
    .then((response) => response.data);
};

// DELETE TEAM MEMBER
const deleteTeamUser = (data: any) => {
  return axios
    .post(`settings/account/team-members`, data)
    .then((response) => response.data);
};

// UPDATE CHANNELSDATA
const updateChannelData = (data: any) => {
  return axios
    .post(`settings/account/team-exchange`, data)
    .then((response) => response.data);
};

// UPDATE PACKAGE
const updatePackage = (data: any) => {
  return axios
    .post("settings/account/change-team-package", data)
    .then((response) => response.data);
};

// save-preferred-partner-exchange
const savePreferredPartner = (data: any) => {
  let payload = {
    preferred_partner: data ? 1 : 0,
  };
  return axios
    .post("settings/save-preferred-partner-exchange", payload)
    .then((response) => response.data);
};

const getUserOptions = (): Promise<any> => {
  return axios
    .get(`settings/account/packages`)
    .then((response) => response.data);
};

// ADD USER
const addNewUser = (data: any) => {
  return axios
    .post(`settings/add-additional-user-to-team`, data)
    .then((response) => response.data);
};

// DELETE USER
const removeUser = (data: any) => {
  return axios
    .post(`settings/account/packages`, data)
    .then((response) => response.data);
};

// CANCEL SUBSCRIPTION
const removeSubscription = () => {
  return axios
    .post(`settings/cancel-subscription-close-account`)
    .then((response) => response.data);
};
// Add ON PACKAGE
const packageAddOn = (data: any) => {
  let payload = {
    addons: data ? [990] : [],
  };
  return axios
    .post(`settings/update-team-package-addons`, payload)
    .then((response) => response.data);
};

// Account Cardsw
const getAccountCards = () => {
  return axios.get(`settings/account/cards`).then((response) => response.data);
};

const accountCards = (data: any) => {
  return axios
    .post(`settings/account/cards`, data)
    .then((response) => response.data);
};
const stripeTokenPost = (data: any) => {
  return axios
    .post(`settings/account/cards/add`, data)
    .then((response) => response.data);
};

// REQUEST FEATURE
const getRequestFeature = (data: any) => {
  return axios
    .post(`settings/request-feature`, data)
    .then((response) => response.data);
};

// Add ON PACKAGE
const v2EngineFeatureAPI = async (data: any) => {
  let payload = { ...data };
  const response = await axios.post(`settings/request-feature`, payload);
  return response.data;
};

const getGlobalCount = async () => {
  const response = await axios.post(`settings/navbar-counts`);
  return response.data;
};

// REFERRAL API'S
const getMyReferralsCount = async (data: any): Promise<any> => {
  const payload = makePayloadForReferral(data);

  const response = await axios.post(`settings/referral-counts`, payload);
  return response.data;
};

const getMyReferralsOptions = async (): Promise<any> => {
  const response = await axios.post(`settings/referral-options`);
  return response.data;
};

const getMyReferralsListings = async (data: any): Promise<any> => {
  const payload = makePayloadForReferral(data);
  const response = await axios.post(`settings/referrals`, payload);
  return response.data;
};

//logout the user
const logoutUser = async (): Promise<any> => {
  const response = await axios.post(`auth/logout`);
  return response.data
}

export {
  accountCards,
  addNewUser,
  addTeamMembers,
  deleteTeamUser,
  getAccountCards,
  getAccountInfo,
  getAddress,
  getChannelsData,
  getGlobalCount,
  getMyReferralsCount,
  getMyReferralsListings,
  getMyReferralsOptions,
  getOverviewData,
  getPackagesData,
  getRequestFeature,
  getTeamData,
  getUserOptions, logoutUser, packageAddOn,
  removeSubscription,
  removeUser,
  savePreferredPartner,
  stripeTokenPost,
  updateAccountDetail,
  updateAddress,
  updateChannelData,
  updatePackage,
  v2EngineFeatureAPI
};

